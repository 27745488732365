<form [formGroup]="subcategoryForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Sub Category' :SelectedName) | translate}}  </h4>
    <button [disabled]="loading" class="btn-primary cutom-button" *hasPermission="['Products','Categorisation','Sub Category', (SelectedID > 0) ? 'Edit' : 'New']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6 mt-5">
          <div class="row">
            <div class="form-group col-lg-8 col-sm-8">
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="name">{{'Name in English' | translate }}</label>
                  <input type="text" class="form-control" id=name placeholder="" formControlName="name">
                  <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                    <div *ngIf="f.name.errors.required">
                      {{'Name is required' | translate }}.
                    </div>
                    {{'Invalid input. Length must be 3 or more' | translate }}.
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="alternateName">{{'Name in Arabic' | translate }}</label>
                  <input type="text" class="form-control" id=alternateName placeholder="" formControlName="alternateName">
                  <div *ngIf="f.alternateName.invalid && (f.alternateName.dirty || f.alternateName.touched)" class="alert alert-danger">
                    <div *ngIf="f.alternateName.errors.required">
                      {{'Arabic Name is required' | translate }}.
                    </div>
                    {{'Invalid input. Length must be 3 or more' | translate }}.
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="Country">{{'Parent Category' | translate }}</label>
                  <ng-select [items]="Categories" [bindLabel]="(selctedLang == 'en' ? 'name' : 'alternateName')" bindValue="categoryID" (change)="loadDisplayOrder(f.categoryID)" formControlName="categoryID">
                  </ng-select>

                  <div *ngIf="f.categoryID.invalid && (f.categoryID.dirty || f.categoryID.touched)" class="alert alert-danger">
                    <div *ngIf="f.categoryID.errors.required">
                      {{'Parent category is required' | translate }}.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="Description">{{'Description' | translate }}</label>
                  <textarea class="form-control" style="height:70px" formControlName="description"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="terminalName">{{'Display Order' | translate }}</label>
                  <input type="number" class="form-control" id=displayOrder placeholder="" formControlName="displayOrder">
                  <div *ngIf="f.displayOrder.invalid && (f.displayOrder.dirty || f.displayOrder.touched)" class="alert alert-danger">
                    <div *ngIf="f.displayOrder.errors.required">
                      {{'Display order is Required' | translate }}
                    </div>
                    <div *ngIf="f.displayOrder.invalid">{{'Display order must be greater than 0 and less than 32000' | translate }} . </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-lg-4 col-sm-4">
              <div class="row ">
                <div class="form-group col-lg-12 col-sm-12 ">
                  <app-imageupload></app-imageupload>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="Item Status">{{'Item Status' | translate }}</label>
                  <div class="form-check form-switch float-clear">
                    <input type="checkbox" class="form-check-input" checked id="statusID" formControlName="statusID">
                    <label class="form-check-label" for="statusID"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>
    </div>
  </div>
</form>
