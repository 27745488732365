

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { Ingredient } from 'src/app/_models/ingredient';
import { AddingredientComponent } from './addingredient/addingredient.component';
import { IngredientService } from 'src/app/_services/ingredient.service';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { IngredientsExportDTO } from '../../_models/ingredient';
import { ImportIngredientsComponent } from './import-ingredients/import-ingredients.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ingredient',
  templateUrl: './ingredient.component.html',
  styleUrls: ['./ingredient.component.css'],
  providers: [ExcelService]
})
export class IngredientComponent implements OnInit {
  data$: Observable<Ingredient[]>;
  oldData: Ingredient[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "ingredient";
  excellData: any = [];
  submit: boolean;
  StatusList = [];
  UnitList = [];
  activeCount = 0;
  inActiveCount = 0;
  checkAllListSubscription: Subscription;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: IngredientService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private translate: TranslateService,
    private confirmationDialogService: ActionConfirmationService   ) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  getBrandData() {
    this.service.headerFilter = { status: [''],units:[''] };
    this.service.getIngredients(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    
    //Header Dropdown
    this.service.allData$.subscribe(res => {
      this.StatusList = [];
      this.UnitList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

        new Set(res.map(val => val.unit)).forEach(e => {
          this.UnitList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddingredientComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Ingredient");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });

  }

  Edit(ingredient) {
    const modalRef = this.modalService.open(AddingredientComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Ingredient");
    modalRef.componentInstance.SelectedID = ingredient.ingredientID;
    modalRef.componentInstance.SelectedName = ingredient.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Ingredient has been Deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Deactive(id, rowVersion) {

    this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Ingredient has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  SetStatus(ing) {
    this.service.getById(ing.ingredientID,ing.brandID).subscribe((res: any) => {
      res.statusID = ing.statusID === 1 ? 2 : 1;
      this.service.update(res).subscribe(data => {
        this.alertService.success("Ingredient has been " + (ing.statusID === 1 ? "Deactived" : "Active"));
        this.getBrandData();
      }, error => {
        this.alertService.error(error);
      });


    });
  }
  public bulkSelection($event) {
    if (this.service.allData$) {
      this.checkAllListSubscription = this.service.allData$.subscribe(i => i.forEach(e => {
        if (this.service.searchTerm) {
          if (this.service.matches(e, this.service.searchTerm))
            e.selected = $event.target.checked;
        }
        else {
          e.selected = $event.target.checked;
        }
      }));
      this.checkAllListSubscription.unsubscribe();
    }
  }


  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Ingredient', type);
  }
  private getExportData() {

    const selectedIngredients = this.service.ingredient.filter(d => d.selected);

    const ingredientsToExport = selectedIngredients.length > 0 ? selectedIngredients : this.service.ingredient;

    const exportData= ingredientsToExport.map(d => ({
      ingredientID: d.ingredientID,
      name: d.name,
      group: d.group,
      unit: d.unit,
      cost: d.cost,
      isExpired: (d.isExpired) ? 'Yes' :'No',
      lifePeriodInDays: d.lifePeriodInDays,
      suppliers: d.suppliersNames, // Mapping suppliersNames to suppliers
      sku: d.sku
    }));

    return exportData;
  }

  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Ingredient has been Deleted");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
  SelectedStatus = '';
  SelectedUnits = 'All Units';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
      units: (this.SelectedUnits == 'All Units') ? this.UnitList.map(function (k) { return k.name }) : [this.SelectedUnits]
    }

    this.service.headerFilter = headerFilter;


  }
  import() {
    const modalRef = this.modalService.open(ImportIngredientsComponent, { size: 'xl', windowClass: 'small-modal' });
    modalRef.result.then((result) => {
    }, (r) => {
      this.getBrandData();
    });
  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
  checkSelection() {
    this.activeCount = this.service.ingredient.filter(e => e.selected === true && e.statusID === 1).length;
    this.inActiveCount = this.service.ingredient.filter(e => e.selected === true && e.statusID === 2).length;
    return this.activeCount + this.inActiveCount; 
  }
  updateStatus(statusId) {
    let ids = this.service.ingredient.filter(e => e.selected === true).map(a => a.ingredientID).join(',');
    this.service.updateBulkStatus(ids, statusId).subscribe((res: any) => {
      this.alertService.success("Status updated");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  askForDelete(statusId) {
    let count = (this.activeCount + this.inActiveCount).toString() + ' ' + this.translate.instant('rows');
    this.confirmationDialogService.confirm(this.translate.instant('Delete') + ' ' + count, count.toString())
      .then((confirmed) => {
        if (confirmed) {
          this.updateStatus(statusId);
        }

      })
      .catch(() => { });
  }
}

