<form [formGroup]="addUserForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{ ((SelectedName === '') ? 'New User' :SelectedName) | translate}}
    </h4>
    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" *hasPermission="['Setup','Users','Users', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ UserButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="roleName">
                {{'Username' | translate }}
              </label>
              <input type="text" class="form-control" id="firstName" placeholder="" formControlName="firstName" pattern="^(?:[a-zA-Z0-9\s@,=%$#&_\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,30}$" />
              <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="alert alert-danger">
                <div *ngIf="f.firstName.errors.required">
                  {{'Name is Required' | translate }}
                </div>
                <div *ngIf="f.firstName.invalid">{{'Name is invalid' | translate }} . </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'User Role' | translate }}</label>
              <ng-select [items]="selectedRoles" bindLabel="roleName" bindValue="roleID" groupBy="type" [multiple]="true" (change)="validateUser($event)" [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="selectedGroupIds">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll()">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">
                      {{item.roleName}}
                    </span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>

                  </div>
                </ng-template>
              </ng-select>
              <div *ngIf="f.groupIDs.invalid && (f.groupIDs.dirty || f.groupIDs.touched)" class="alert alert-danger">
                <div *ngIf="f.groupIDs.errors.required">
                  {{'Role is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Availability in Locations' | translate }}</label>
              <ng-select #select [items]="LocationsList" bindLabel="name" [closeOnSelect]="true" bindValue="locationID" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedLocationIds">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll()">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">
                      {{item.name}}
                    </span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                    
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>


          <div class="row mt-10">
            <div class="form-group">
              <div class="tiltle-bar form-group" style="margin-bottom: 0;">
                <div class="row">
                  <div class="col-5">
                    <div class="title">
                      <label>{{'Advance Info' | translate }}</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="roleName">{{'Phone Number' | translate }}</label>
              <input type="text" class="form-control" pattern="^\(?([\+]|00)(\d{2,3})\)?[ .-]?(\d{2,3})[ .-]?(\d{4})[ .-]?(\d{3})$" id="contactNo" placeholder="" formControlName="contactNo" />
              <div *ngIf="f.contactNo.invalid && (f.contactNo.dirty || f.contactNo.touched)" class="alert-danger">
                <!--<div *ngIf="f.contactNo.errors.required"> Mobile Number is required. </div>-->
                <div *ngIf="f.contactNo.invalid">{{'Mobile Number is invalid' | translate }}. {{'Format should like' | translate }} +966 XXX XXXX XXX</div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="f.userType.value.split(',').includes('Dashboard') || f.userType.value.split(',').includes('Admin')? true : false">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="roleName">{{'Email' | translate }}</label>
              <input type="text" class="form-control" id="email" [class.disabled]="f.email.disabled" placeholder="" formControlName="email" autocomplete="off" />
              <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger">
                <div *ngIf="f.email.errors.required">
                  {{'Email is required' | translate }}.
                </div>
                <div *ngIf="f.email.invalid">{{'Email format is invalid' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="f.userType.value.split(',').includes('Dashboard')|| f.userType.value.split(',').includes('Admin') ? true : false">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="password">{{'Password' | translate }}</label>
              <input type="text" class="form-control" id="password" placeholder="" formControlName="password" autocomplete="off" dir="ltr" />
              <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger">
                <div *ngIf="f.password.errors.required">
                  {{'Password is required' | translate }}.
                </div>
                <div *ngIf="f.password.invalid">{{'Minimum eight characters, at least one capital letter, one number and one special character' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="f.userType.value.split(',').includes('App') && SelectedID !== 0 ? true : false">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="roleName">{{'Passcode' | translate }}</label>
              <input type="text" class="form-control disabled" id="passcode" placeholder="" formControlName="passcode" />
            </div>
          </div>
        

          <div class="row">
            <div class="form-group  col-lg-12">
              <div class="status-activebar">
                <label for="Status">

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{'User Status.' | translate }}
                </label>

                <div class="form-check form-switch">
                  <span class="inactive" (click)="f.statusID.setValue(false)" [className]="f.statusID.value==false ? 'active' : 'inactive'" role="button"> {{'Inactive' | translate}}</span>
                  <span class="inactive active" (click)="f.statusID.setValue(true)" [className]="f.statusID.value==true ? 'active' : 'inactive'" role="button">{{'Active' | translate}}</span>
                </div>

              </div>
            </div>
          </div>



        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>
