/// <reference path="../_helpers/globals.ts" />
/// <reference path="../_helpers/globals.ts" />
import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { timer, Subscription, BehaviorSubject } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { DashboardService } from '../_services/dashboard.service';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexMarkers,
  AnnotationStyle,
  ApexDataLabels,
  ApexStroke,
  ApexGrid,
  ApexYAxis,
  ApexAnnotations,
  ApexNonAxisChartSeries,
  ApexFill,
  ApexLegend,
  ApexTooltip,
  ApexPlotOptions,
  ApexResponsive,
  ApexStates,
  ApexTheme,
  ChartType,
  ApexNoData
} from "ng-apexcharts";
import { SalesSummary, LiveTransaction, OrderTodaySummary, CustomerTodaySummary } from '../_models/Dashboard';
import { LocalStorageService } from '../_services/local-storage.service';
import { DashboardReport } from '../_models/dashboard-report.model';
import { StatsordertypeComponent } from './statsordertype/statsordertype.component';
import { StatspaymenttypeComponent } from './statspaymenttype/statspaymenttype.component';
import { LocationService } from '../_services/location.service';
import { SalesTodaySummary } from '../_models/Dashboard';
import { WebSocketConnectionService } from '../_services/web-socket-connection.service';
import * as myGlobals from '../_helpers/globals';
import { NgbdSortableHeader, SortEvent } from '../_directives/sortable.directive';
import { DateRangePickerV2Component } from '../date-range-picker-v2/date-range-picker-v2.component';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  markers: ApexMarkers;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  grid: ApexGrid;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  colors: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  legend: ApexLegend;
  toolTip: ApexTooltip;
  apexNoData: ApexNoData;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
  private subscription: Subscription;
  public minSummary: SalesSummary;
  public maxSummary: SalesSummary;
  public totalSales = 0;
  public percentage = 0;
  public loadingAnalytics = true;
  public loadingSummary = true;
  public loadingTodaySummary = true;
  public loadingTodayOrder = true;
  public loadingTodayCustomer = true;
  public loadingNetSalesSummary = true;
  public loadingLocationSales = true;
  public loadingTransactions = true;
  public loadingDasboardData = true;

  public selectedDate: any = {
    fromDate: new Date(),
    toDate: new Date()
  } 
  public today: Date = new Date();
  public yesterday: Date = new Date();

  public businessDate: Date = new Date();

  public locationSales;
  public transactions;
  private selectedBrand;
  public selectedLocation: any;
  public selectedLocationName = 'All Locations';
  public dashboardData: DashboardReport;
  public dashboardTodaysSummary: SalesTodaySummary[];
  public dashboardTodayOrderSummary: OrderTodaySummary[];
  public dashboardTodayCustomerSummary: CustomerTodaySummary[];
  public dashboardNetSalesSummary: SalesTodaySummary[];
  LocationsList = [];
  industryURLName: string = '';
  brandCurrency: string = '';
  private companyCode;
  showLiveTransaction: boolean;
  active = 1;
  WaitTillFetched: boolean = true;
  @ViewChild(StatsordertypeComponent, { static: true }) orderType: StatsordertypeComponent;
  @ViewChild(StatspaymenttypeComponent, { static: true }) paymentType: StatspaymenttypeComponent;
  @ViewChild(DateRangePickerV2Component, { static: false }) dateComp;

  currenttime: any = new Date();
  currentJustify = 'justified';
  constructor(private dashboardService: DashboardService,
    private localStorageService: LocalStorageService,
    private location: LocationService,
    private socketService: WebSocketConnectionService) {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
  }
  ngOnInit() {
    //Commenting because OpeningTime value is empty
    var currentTime = new Date(this.today).setSeconds(0);
    var todatOpenTiming;
    if (this.localStorageService.getSelectedLocation().OpeningTime !== undefined && this.localStorageService.getSelectedLocation().OpeningTime !== null && this.localStorageService.getSelectedLocation().OpeningTime !== "") {
      todatOpenTiming = new Date().setHours(this.localStorageService.getSelectedLocation().OpeningTime.split(':')[0]);
      todatOpenTiming = new Date(todatOpenTiming).setMinutes(this.localStorageService.getSelectedLocation().OpeningTime.split(':')[1]);
      todatOpenTiming = new Date(todatOpenTiming).setSeconds(this.localStorageService.getSelectedLocation().OpeningTime.split(':')[2]);
    }

    const _todatOpenTiming = new Date((todatOpenTiming !== null && todatOpenTiming !== undefined && todatOpenTiming !== '') ? todatOpenTiming : currentTime);
    const _currentDateTime = new Date(currentTime);

    this.industryURLName = myGlobals.industryName(this.localStorageService);
    this.brandCurrency = myGlobals.brandCurrency(this.localStorageService);

    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selectedLocation = this.localStorageService.getSelectedLocation().LocationID;



    this.selectedLocationName = this.localStorageService.getSelectedLocation().Name;
    this.companyCode = this.localStorageService.getSelectedUser().CompanyDetails.CompanyCode;
    this.socketService.ConnectWebSocket(this.companyCode, this.selectedBrand);
    this.socketService.receiveData.pipe().subscribe((data) => {
      this.loadTransactions();
    });
    this.loadAllData();

  }

  private loadTodaySalesSummary() {
    this.loadingTodaySummary = true;
    this.WaitTillFetched = true;
    this.dashboardService.getTodaySummary(this.selectedBrand, this.selectedLocation, this.selectedDate)
      .subscribe(
        data => {
          this.dashboardTodaysSummary = data;
          this.loadingTodaySummary = false;
          this.WaitTillFetched = false;
        },
        error => {
          this.loadingTodaySummary = false;
          this.WaitTillFetched = false;
        });

  }

  private loadNetSalesSummary() {
    this.loadingNetSalesSummary = true;
    this.WaitTillFetched = true;
    this.dashboardService.getNetSalesSummary(this.selectedBrand, this.selectedLocation, this.selectedDate)
      .subscribe(
        data => {
          this.dashboardNetSalesSummary = data;
          this.loadingNetSalesSummary = false;
          this.WaitTillFetched = false;
        },
        error => {
          this.loadingNetSalesSummary = false;
          this.WaitTillFetched = false;
        });

  }


  private loadTodayOrderSummary() {
    this.loadingTodayOrder = true;
    this.WaitTillFetched = true;
    this.dashboardService.getTodayOrderSummary(this.selectedBrand, this.selectedLocation, this.selectedDate)
      .subscribe(
        data => {
          this.dashboardTodayOrderSummary = data;
          this.loadingTodayOrder = false;
          this.WaitTillFetched = false;
        },
        error => {
          this.loadingTodayOrder = false;
          this.WaitTillFetched = false;
        });

  }
  private loadTodayCustomerSummary() {
    this.loadingTodayCustomer = true;
    this.WaitTillFetched = true;
    this.dashboardService.getTodayCustomerSummary(this.selectedBrand, this.selectedLocation, this.selectedDate)
      .subscribe(
        data => {
          this.dashboardTodayCustomerSummary = data;
          this.loadingTodayCustomer = false;
          this.WaitTillFetched = false;
        },
        error => {
          this.loadingTodayCustomer = false;
          this.WaitTillFetched = false;
        });

  }

  private loadLocationSales() {
    this.loadingLocationSales = true;
    this.dashboardService.getLocationSales(this.selectedBrand, this.selectedDate)
      .subscribe(
        data => {
          this.locationSales = data;
          //console.log(this.locationSales);
          this.loadingLocationSales = false;
        },
        error => {
          this.loadingLocationSales = false;
        });

  }

  private loadTransactions() {
    this.loadingTransactions = true;
    this.dashboardService.getTransactions(this.selectedBrand, this.selectedLocation, new Date())
      .subscribe(
        data => {
          this.transactions = data;
          this.transactions.forEach(d => {
            d.timeStamp = this.getTimeDiff(d.dateTimeStamp).value;
            d.timeText = this.getTimeDiff(d.dateTimeStamp).text;
          });
          this.loadingTransactions = false;
        },
        error => {
          this.loadingTransactions = false;
        });
  }
  loadGraph() {
    switch (this.active) {
      case 1:
        this.loadTodayOrderSummary();
        break;
      case 2:
        this.loadTodaySalesSummary();
        break;
      case 3:
        this.loadNetSalesSummary();
        break;
      case 4:
        this.loadTodayCustomerSummary();
        break;
    }
  }

  loadAllData() {
    this.getDashboardReport();

  }

  displayDate = new Date();
 
  public setDate() {
    if (this.dateComp) {
      this.selectedDate = {
        fromDate: this.dateComp.fromDate,
        toDate: this.dateComp.toDate
      }
      this.loadAllData();
    }
  }

  public getTimeDiff(date: Date) {
    let oToday = new Date();
    let oDatePublished = new Date(date);

    let nDiff = oToday.getTime() - oDatePublished.getTime();
    // Get diff in days
    let days = Math.floor(nDiff / 1000 / 60 / 60 / 24);
    nDiff -= days * 1000 * 60 * 60 * 24;

    // Get diff in hours
    let hours = Math.floor(nDiff / 1000 / 60 / 60);
    nDiff -= hours * 1000 * 60 * 60;

    // Get diff in minutes
    let minutes = Math.floor(nDiff / 1000 / 60);
    nDiff -= minutes * 1000 * 60;

    // Get diff in seconds
    let seconds = Math.floor(nDiff / 1000);

    // Render the diffs into friendly duration string

    // Days
    let sDays = '00';
    if (days > 0) {
      sDays = String(days);
    }
    if (sDays.length === 1) {
      sDays = '0' + sDays;
    }

    // Format Hours
    let sHour = '00';
    if (hours > 0) {
      sHour = String(hours);
    }
    if (sHour.length === 1) {
      sHour = '0' + sHour;
    }

    //  Format Minutes
    let sMins = '00';
    if (minutes > 0) {
      sMins = String(minutes);
    }
    if (sMins.length === 1) {
      sMins = '0' + sMins;
    }

    //  Format Seconds
    let sSecs = '00';
    if (seconds > 0) {
      sSecs = String(seconds);
    }
    if (sSecs.length === 1) {
      sSecs = '0' + sSecs;
    }

    let friendlyNiceText;
    let result = {
      value: 0,
      text: ''
    };
    // Set friendly text for printing
    if (days === 0) {

      if (hours === 0) {

        if (minutes === 0) {
          let sSecHolder = seconds > 1 ? 'Seconds' : 'Second';
          friendlyNiceText = seconds + ' ' + sSecHolder + ' ago';
          result.text = sSecHolder + ' ago';
          result.value = seconds;
        } else {
          let sMinutesHolder = minutes > 1 ? 'Minutes' : 'Minute';
          friendlyNiceText = minutes + ' ' + sMinutesHolder + ' ago';
          result.text = sMinutesHolder + ' ago';
          result.value = minutes;
        }

      } else {
        let sHourHolder = hours > 1 ? 'Hours' : 'Hour';
        friendlyNiceText = hours + ' ' + sHourHolder + ' ago';
        result.text = sHourHolder + ' ago';
        result.value = hours;
      }
    } else {
      let sDayHolder = days > 1 ? 'Days' : 'Day';
      friendlyNiceText = days + ' ' + sDayHolder + ' ago';
      result.text = sDayHolder + ' ago';
      result.value = days;
    }

    return result;
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
    this.socketService.closeConnection();
  }

  private getDashboardReport() {
    this.loadingDasboardData = true;
    this.dashboardService.getDashboardReport(this.selectedBrand, this.selectedLocation, this.selectedDate)
      .subscribe(
        data => {
          this.dashboardData = data;
          this.loadingDasboardData = false;
          this.loadGraph();
        },
        error => {
          this.loadingDasboardData = false;
        });
  }

  getTotalPartnerOrder() {
    if (this.dashboardData) {

      return this.dashboardData.partnerReport.map(t => t.orderCount).reduce((a, value) => a + value, 0);
    }
    return 0;
  }
  loadLocations() {
    this.location.getLocations();

    this.location.locations$.subscribe((res: any) => {
      this.LocationsList = res;
    });
  }
  public setLocation(val) {
    if (val == null) {
      this.selectedLocation = null;
      this.selectedLocationName = 'All Locations';
      this.loadAllData();
    } else {
      this.selectedLocation = val.locationID;
      this.selectedLocationName = val.name;
      this.loadAllData();
    }
  }
  onSort({ column, direction }: SortEvent) {
    this.locationSales = this.dashboardService.sort(this.locationSales, column, direction);
  }
}
