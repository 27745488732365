
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ModifierService } from 'src/app/_services/modifier.service';
import { Modifier } from 'src/app/_models/Modifier';
import { ExcelService } from 'src/app/_services/excel.service';
import { ActionConfirmationService } from '../../../_services/action-confirmation.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { AddmodifierComponent } from '../addmodifier/addmodifier.component';
import { AlertService } from '../../../_services/alert.service';
import { ModifierUplifterComponent } from '../modifier-uplifter/modifier-uplifter.component';
import { TrackEventsService } from '../../../_services/track-events.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-item-modifiers',
  templateUrl: './item-modifiers.component.html',
  styleUrls: ['./item-modifiers.component.css']
})
export class ItemModifiersComponent implements OnInit {

  data$: Observable<Modifier[]>;
  oldData: Modifier[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  submit: boolean;
  excelData = [];
  selctedLang = 'en';
  StatusList = [];
  activeCount = 0;
  inActiveCount = 0;
  checkAllListSubscription: Subscription;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: ModifierService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private excelService: ExcelService,
    private trackEvents: TrackEventsService,
    private translate: TranslateService,
    private confirmationDialogService: ActionConfirmationService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selctedLang = this.localStorageService.getSelectedLanguage();

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  getBrandData() {
    this.service.headerFilter = { status: [''] };
    this.service.getModifiers(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

      }
    });

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddmodifierComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Modifier");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }
  import() {
    const modalRef = this.modalService.open(ModifierUplifterComponent, { size: 'xl', windowClass: 'small-modal' });
    this.trackEvents.Log_Screen("Edit Modifier");
    modalRef.result.then((result) => {
    }, (r) => {
      this.getBrandData();
    });
  }

  Edit(modifier) {

    const modalRef = this.modalService.open(AddmodifierComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.SelectedID = modifier.modifierID;
    modalRef.componentInstance.SelectedName = modifier.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Modifier has been Deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Deactive(modifier, rowVersion) {

    console.log(modifier);
    if (modifier.statusID === 2) {
      this.alertService.success("Modifier already deactivated");
    } else {
      this.service.deactive(parseInt(modifier.modifierID), rowVersion).subscribe((res: any) => {
        this.alertService.success("Modifier has been Deactived");
        this.getBrandData();
      }, error => {
        this.alertService.error(error);
      });
    }
  }
  SetStatus(modifier, statusID, rowVersion) {
    this.service.SetStatus(parseInt(modifier.modifierID), parseInt(statusID), rowVersion).subscribe((res: any) => {
      if (parseInt(statusID) === 1)
        this.alertService.success("Modifier has been Actived");
      else
        this.alertService.success("Modifier has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }

  public chkSelection($event, modifier) {
    this.service.modifiers.forEach((item, index) => {
      if (item.modifierID == modifier.modifierID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }
  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Modifier', type);
  }
  private getExportData() {
    let selected: Modifier[];
    let returnList = [];
    selected = [];
    this.service.modifiers.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.modifiers : selected;
    selected = selected.filter(function (modifier) {
      var data =
      {
        Identifier: modifier.modifierID,
        Name: modifier.name,
        ArabicName: modifier.alternateName,
        Barcode: modifier.barcode,
        Price: modifier.price,
        Inventory: modifier.trackInventory,
        Status: modifier.statusID == 1 ? 'Active' : 'Inactive'
      };
      returnList.push(data);
      return true;
    });
    return returnList;

  }
  public bulkSelection($event) {
    if (this.service.allData$) {
      this.checkAllListSubscription = this.service.allData$.subscribe(i => i.forEach(e => {
        if (this.service.searchTerm) {
          if (this.service.matches(e, this.service.searchTerm))
            e.selected = $event.target.checked;
        }
        else {
          e.selected = $event.target.checked;
        }
      }));
      this.checkAllListSubscription.unsubscribe();
    }
  }
  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Modifier has been Deleted");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
  checkSelection() {
    this.activeCount = this.service.modifiers.filter(e => e.selected === true && e.statusID === 1).length;
    this.inActiveCount = this.service.modifiers.filter(e => e.selected === true && e.statusID === 2).length;
    return this.activeCount + this.inActiveCount; 
  }
  updateStatus(statusId) {
    let ids = this.service.modifiers.filter(e => e.selected === true).map(a => a.modifierID).join(',');
    this.service.updateBulkStatus(ids, statusId).subscribe((res: any) => {
      this.alertService.success("Status updated");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  askForDelete(statusId) {
    let count = (this.activeCount + this.inActiveCount).toString() + ' ' + this.translate.instant('rows');
    this.confirmationDialogService.confirm(this.translate.instant('Delete') + ' ' + count, count.toString())
      .then((confirmed) => {
        if (confirmed) {
          this.updateStatus(statusId);
        }

      })
      .catch(() => { });
  }
}
