<form [formGroup]="groupmodifierForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Group Modifier' :SelectedName) | translate}} </h4>

    <button [disabled]="loading" class="btn-primary cutom-button" *hasPermission="['Products','Modifiers','Group Modifier', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="name">{{'Name' | translate }}</label>
              <input type="text" class="form-control" id=name placeholder="" formControlName="name">
              <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                <div *ngIf="f.name.errors.required">{{'Name is required' | translate }}.</div>
                <div *ngIf="f.name.invalid">{{'Name is invalid' | translate }}. </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="alternateName">{{'Arabic Name' | translate }}</label>
              <input type="text" class="form-control" id=alternateName placeholder="" formControlName="alternateName">

              <div *ngIf="f.alternateName.invalid && (f.alternateName.dirty || f.alternateName.touched)" class="alert alert-danger">
                <div *ngIf="f.alternateName.errors.required">
                  {{'Arabic Name is required' | translate }}.
                </div>
                {{'Invalid input. Length must be 3 or more' | translate }}.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="description">{{'Description' | translate }}</label>
              <input type="text" class="form-control" id=description placeholder="" formControlName="description">
            </div>
          </div>




          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="ModifierItems">{{'Modifier Items' | translate }}</label>



              <ng-select [items]="ItemModifiersList" [bindLabel]="(selctedLang == 'en' ? 'name' : 'alternateName')"
                         class="custom" bindValue="modifierID" [multiple]="true" [closeOnSelect]="false"
                         placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selecteditemModifierIds">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label"> {{selctedLang=='en'?item.name:item.alternateName}}  </span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #1f2532;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>

                </ng-template>
              </ng-select>
              <div class="alert alert-danger" *ngIf="!selecteditemModifierIds || selecteditemModifierIds.length === 0">
                {{'Item is required.' | translate }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="terminalName">{{'Display Order' | translate }}</label>
              <input type="number" class="form-control" id=displayOrder placeholder="" formControlName="displayOrder">
              <div *ngIf="f.displayOrder.invalid && (f.displayOrder.dirty || f.displayOrder.touched)" class="alert alert-danger">
                <div *ngIf="f.displayOrder.errors.required">
                  {{'Display order is Required' | translate }}
                </div>
                <div *ngIf="f.displayOrder.invalid">{{'Display order must be greater than 0 and less than 32000' | translate }} . </div>
              </div>
            </div>
          </div>

          <div class="tiltle-bar form-group">
            <div class="row">
              <div class="col-8">
                <label for="inventory">{{'Choices Customization' | translate }}</label>
              </div>
              <div class="col-4 text-right">

              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="min">{{'Add the Minimum required Modifier items' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" placeholder="{{'Auto' | translate}}" formControlName="min">
                <!--<div class="input-group-append">
          <span class="input-group-text" id="basic-addon2">{{'choices' | translate }}</span>
        </div>-->
              </div>
              <div *ngIf="f.min.invalid && (f.min.dirty || f.min.touched)" class="alert alert-danger">
                <div *ngIf="f.min.errors.required">
                  {{'Min is required' | translate }}.
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="min">{{'Add the Maximum required Modifier items' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" placeholder="{{'Auto' | translate}}" formControlName="max">
                <!--<div class="input-group-append">
          <span class="input-group-text" id="basic-addon2">{{'choices' | translate }}</span>
        </div>-->
              </div>
              <div *ngIf="f.max.invalid && (f.max.dirty || f.max.touched)" class="alert alert-danger">
                <div *ngIf="f.max.errors.required">
                  {{'Max is required' | translate }} .
                </div>
                <div *ngIf="f.max.invalid">{{'Max field must be greater than 0' | translate }} . </div>
              </div>
            </div>
          </div>

          <div class="form-group tiltle-bar">
            <div class="row">
              <div class="col-lg-8 col-sm-8 col-6">
                <label for="multimaxEnabled">{{ 'Advanced' | translate }}</label>
              </div>
              <div class="col-lg-4 col-sm-4 col-6 text-right">
                <div class="form-check form-switch">
                  <input type="checkbox"
                         class="form-check-input"
                         id="multimaxEnabled"
                         formControlName="multimaxEnabled"
                         (change)="onMultimaxToggle()">
                </div>
              </div>
              <div class="col-lg-12">
                <p class="d-block mt-2 m-0" for="multimaxEnabled">
                  {{ 'Add the Multimax (Maximum Amount per Modifier items)' | translate }}
                </p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="f.multimaxEnabled.value">
            <div class="form-group col-lg-12">
              <label for="multimax">{{ 'Multimax' | translate }}</label>
              <input type="number"
                     class="form-control"
                     id="multimax"
                     formControlName="multimax"
                     min="1"
                     max="{{f.max.value}}"
                     placeholder="{{'Enter Multimax Value' | translate}}">
              <div *ngIf="f.multimax.invalid && (f.multimax.dirty || f.multimax.touched)" class="alert alert-danger">
                <div *ngIf="f.multimax.errors.required">
                  {{ 'Multimax is required' | translate }}
                </div>
                <div *ngIf="f.multimax.errors.min">
                  {{ 'Multimax value must be greater than or equal to' | translate }}&nbsp;1
                </div>
                <div *ngIf="f.multimax.errors.max">
                  {{ 'Multimax value must be less than or equal to' | translate }}&nbsp;{{f.max.value}}
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="form-group col-lg-12">

              <!--<label for="Status">{{'Modifier Group Status' | translate }}</label>
      <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input" checked id="statusID" formControlName="statusID">
        <label class="form-check-label" for="statusID"></label>
      </div>-->


              <div class="status-activebar">
                <label for="Status">

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{'Modifier Group Status' | translate }}
                </label>

                <div class="form-check form-switch">
                  <span class="inactive" (click)="f.statusID.setValue(false)" [className]="f.statusID.value==false ? 'active' : 'inactive'" role="button"> {{'Inactive' | translate}}</span>
                  <span class="inactive active" (click)="f.statusID.setValue(true)" [className]="f.statusID.value==true ? 'active' : 'inactive'" role="button">{{'Active' | translate}}</span>
                </div>

              </div>
            </div>


          </div>

        </div>

        <div class="col-lg-4"></div>

      </div>
    </div>

  </div>

</form>
