import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../_directives/sortable.directive';
import { LocationGroup } from '../../_models/LocationGroup';
import { LocalStorageService } from '../../_services/local-storage.service';
import { LocationGroupService } from '../../_services/location-group.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackEventsService } from '../../_services/track-events.service';
import { CreateLocationGroupComponent } from './create-location-group/create-location-group.component';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { AlertService } from '../../_services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-location-group',
  templateUrl: './location-group.component.html',
  styleUrl: './location-group.component.css'
})
export class LocationGroupComponent {
  StatusList = [];
  locationGroups$: Observable<LocationGroup[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  checkAllListSubscription: Subscription;
  activeCount = 0;
  inActiveCount = 0;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: LocationGroupService,
    private router: Router,
    private modalService: NgbModal,
    private trackEvents: TrackEventsService,
    private alertService: AlertService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private confirmationDialogService: ActionConfirmationService) {
    
  }
  ngOnInit() {
    this.getData();

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  getData() {
    this.service.getlocationGroups(this.localStorageService.getSelectedBrand().BrandID);

    this.locationGroups$ = this.service.locationGroups$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });

  }
  open() {
    const modalRef = this.modalService.open(CreateLocationGroupComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Location Group");
    modalRef.componentInstance.name = '';
    modalRef.componentInstance.SelectedGroup = null;
    modalRef.result.then((result) => {
      this.getData();
    }, (reason) => {
      this.getData();
    });

  }
  Edit(item) {
    const modalRef = this.modalService.open(CreateLocationGroupComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Location Group");
    modalRef.componentInstance.SelectedGroup = item;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });
  }
  public openConfirmationDialog(item) {
    this.confirmationDialogService.confirm('Please confirm..', item.name)
      .then((confirmed) => {
        if (confirmed) {
          item.statusID = 3;
          this.service.save(item).subscribe(data => {
            this.getData();
            this.alertService.success("Location Group has been Deleted");

          }, error => {
            this.alertService.error(error);

          });

        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }

  activate(item) {
    item.statusID = 1;
    this.save(item);
  }

  deactivate(item) {
    item.statusID = 2;
    this.save(item);
  }
  save(item)
  {
    this.service.save(item).subscribe(data => {
      this.getData();
      this.alertService.success("Location Group has been saved");

    }, error => {
      this.alertService.error(error);

    });
  }
  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus]
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }

  public bulkSelection($event) {
    if (this.service.allData$) {
      this.checkAllListSubscription = this.service.allData$.subscribe(i => i.forEach(e => {
        if (this.service.searchTerm) {
          if (this.service.matches(e, this.service.searchTerm))
            e.selected = $event.target.checked;
        }
        else {
          e.selected = $event.target.checked;
        }
      }));
      this.checkAllListSubscription.unsubscribe();
    }
  }

  checkSelection() {
    this.activeCount = this.service.locationGroups.filter(e => e.selected === true && e.statusID === 1).length;
    this.inActiveCount = this.service.locationGroups.filter(e => e.selected === true && e.statusID === 2).length;
    return this.activeCount + this.inActiveCount;
  }
  askForDelete(statusId) {
    let count = (this.activeCount + this.inActiveCount).toString() + ' ' + this.translate.instant('rows');
    this.confirmationDialogService.confirm(this.translate.instant('Delete') + ' ' + count, count.toString())
      .then((confirmed) => {
        if (confirmed) {
          this.updateStatus(statusId);
        }

      })
      .catch(() => { });
  }
  updateStatus(statusId) {
    let ids = this.service.locationGroups.filter(e => e.selected === true).map(a => a.groupID).join(',');
    this.service.updateBulkStatus(ids, statusId).subscribe((res: any) => {
      this.alertService.success("Status updated");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
}
