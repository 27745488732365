
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Item } from '../../_models/Item';
import { LocalStorageService } from '../../_services/local-storage.service';
import { LocationService } from '../../_services/location.service';
import { AlertService } from '../../_services/alert.service';
import { ItemService } from 'src/app/_services/item.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { AdditemComponent } from './additem/additem.component';
import { ExcelService } from '../../_services/excel.service';
import { ItemsUploaderComponent } from '../items-uploader/items-uploader.component';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html'
})
export class ItemsComponent implements OnInit {
  data$: Observable<Item[]>;
  oldData: Item[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  excelData = [];
  submit: boolean;
  selctedLang = 'en';
  StatusList = [];
  breakCache = Math.floor((Math.random() * 100) + 1);
  activeCount = 0;
  inActiveCount = 0;
  checkAllListSubscription: Subscription;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: ItemService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private locationService: LocationService,
    private alertService: AlertService,
    private excelService: ExcelService,
    private translate: TranslateService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService) {
  }

  ngOnInit() {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.loading$ = this.service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  getBrandData() {
    this.breakCache = Math.floor((Math.random() * 100) + 1);
    this.service.headerFilter = { status: [''] };
    this.service.getItems(this.selectedBrand);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AdditemComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Item");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedItemName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(item) {
    const modalRef = this.modalService.open(AdditemComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Item");
    modalRef.componentInstance.SelectedID = item.itemID;
    modalRef.componentInstance.SelectedItemName = item.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Item has been Deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Deactive(id, rowVersion) {

    this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Item has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  SetStatus(id, statusID, rowVersion) {

    this.service.SetStatus(parseInt(id), parseInt(statusID), rowVersion).subscribe((res: any) => {
      if (parseInt(statusID) === 1)
        this.alertService.success("Item has been Actived");
      else
        this.alertService.success("Item has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Item', type);
  }
  private getExportData() {
    let selected: Item[];
    let returnList = [];
    selected = [];
    this.service.items.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.items : selected;
    selected = selected.filter(function (item) {
      var data =
      {
        Identifier: item.itemID,
        Name: item.name,
        ArabicName: item.nameOnReceipt,
        Subcategory: item.subCategoryName,
        Category: item.categoryName,
        Barcode: item.barcode,
        Order: item.displayOrder,
        Price: item.price,
        Status: item.statusID == 1 ? 'Active' : 'Inactive'
      };
      returnList.push(data);
      return true;
    });
    return returnList;

  }
  public chkSelection($event, _item) {
    this.service.items.forEach((item, index) => {
      if (item.itemID == _item.itemID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }
  public bulkSelection($event) {
    if (this.service.allData$) {
      this.checkAllListSubscription = this.service.allData$.subscribe(i => i.forEach(e => {
        if (this.service.searchTerm) {
          if (this.service.matches(e, this.service.searchTerm))
            e.selected = $event.target.checked;
        }
        else {
          e.selected = $event.target.checked;
        }
      }));
      this.checkAllListSubscription.unsubscribe();
    }
  }
  import() {
    const modalRef = this.modalService.open(ItemsUploaderComponent, { size: 'xl', windowClass: 'small-modal' });
    modalRef.result.then((result) => {
    }, (r) => {
      this.getBrandData();
    });
  }
  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Item has been Deleted");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
  checkSelection() {
    this.activeCount = this.service.items.filter(e => e.selected === true && e.statusID === 1).length;
    this.inActiveCount = this.service.items.filter(e => e.selected === true && e.statusID === 2).length;
    return this.activeCount + this.inActiveCount;
  }
  updateStatus(statusId) {
    let ids = this.service.items.filter(e => e.selected === true).map(a => a.itemID).join(',');
    this.service.updateBulkStatus(ids, statusId).subscribe((res: any) => {
      this.alertService.success("Status updated");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  askForDelete(statusId) {
    let count = (this.activeCount + this.inActiveCount).toString() + ' ' + this.translate.instant('rows');
    this.confirmationDialogService.confirm(this.translate.instant('Delete') + ' ' + count, count.toString())
      .then((confirmed) => {
        if (confirmed) {
          this.updateStatus(statusId);
        }

      })
      .catch(() => { });
  }
}
