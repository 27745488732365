<main class="views container">
  <div class="page-header tabheader mb-0">
    <div class="breadcrumb"> <a class="link" [routerLink]="['/restaurant/location']">{{'Locations' | translate}}</a> >{{'Setup Location' | translate}}  </div>
    <!-- <div class="title"> -->
    <span *ngIf="f.LocationID.value == 0">{{'New Location' | translate}}  </span>
    <select class="select-heading display-1 " *ngIf="f.LocationID.value > 0" [(ngModel)]="f.LocationID.value" (change)="changeLocation()">
      <option class="service-small" [ngValue]="option.locationID" *ngFor="let option of Locations">
        {{option.name}}
      </option>
    </select>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal" [(activeId)]="active">
          <li ngbNavItem="locationInfo" id="locationInfo">
            <a ngbNavLink> {{'Info' | translate}}</a>
            <!-- location templete start -->
            <ng-template ngbNavContent id="locationSection">
              <form [formGroup]="locationForm" class="form" (ngSubmit)="onSubmit()">
                <div class="row frmlocation">
                  <div class="col-lg-8 col-md-7 col-sm-12 ">
                    <!-- LocationInfo start -->
                    <section class="tile" [ngClass]="{'lazy': loadingLocation}">
                      <div class="tile-header">
                        <h6>{{'Location info' | translate}}</h6>
                      </div>
                      <div class="tile-body">
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="Name">{{'Location Name' | translate}}</label>
                            <input type="text" class="form-control" id=Name placeholder="" formControlName="Name">
                            <div *ngIf="f.Name.invalid && (f.Name.dirty || f.Name.touched)" class="alert alert-danger">
                              <div *ngIf="f.Name.errors.required">
                                {{'Name is required' | translate}}.
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--<div class="row">
                <div class="form-group col-lg-12 col-sm-12 location-coordinates">
                  <label for="Address">{{'Address' | translate}}</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Address">
                  <div class="float-end" (click)="openMap()" style="margin: -33px 13px 0px 13px">-->
                        <!--<button (click)="openMap()" class="btn-lg">
                  <span>{{'Assign' | translate }} </span> &nbsp;
                  <img src="../../../../../assets/images/icons/v2/navigate.svg" class="mr-1" />
                </button>-->
                        <!--<img src="../../../../../assets/images/icons/v2/navigate.svg" class="mr-1" />
                    </div>



                  </div>
                </div>-->


                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="Phone">{{'Phone No.' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="text" pattern="^((?:[+?0?0?966]+)(?:\s?\d{2})(?:\s?\d{7}))$" class="form-control" placeholder="" formControlName="ContactNo">

                            <div *ngIf="f.ContactNo.invalid && (f.ContactNo.dirty || f.ContactNo.touched)" class="alert alert-danger">
                              <!-- <div *ngIf="f.ContactNo.errors.required"> Mobile Number is required. </div> -->
                              <div *ngIf="f.ContactNo.invalid"> {{'Mobile Number is invalid.' | translate}} </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="Email">{{'Email' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="email" class="form-control" placeholder="" formControlName="Email">
                            <div *ngIf="f.Email.invalid && (f.Email.dirty || f.Email.touched)" class="alert alert-danger">
                              <!-- <div *ngIf="f.Email.errors.required"> Email Address is required. </div> -->
                              <div *ngIf="f.Email.invalid"> {{'Email Address is invalid.' | translate}} </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="locatioGroup">{{'Location Group' | translate}}</label>

                            <ng-select [items]="LocationGroups" bindLabel="name" class="custom" bindValue="groupID" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedLocationGroupIds">
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items">
                                  <span class="ng-value-label"> {{item.name}}  </span>
                                  <span class="ng-value-icon right" style="border-left: 1px solid #1f2532;" (click)="clear(item)" aria-hidden="true">×</span>
                                </div>
                              </ng-template>
                            </ng-select>

                          </div>
                        </div>


                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="Description">{{'Business Bio' | translate}}</label>
                            <textarea class="form-control mh-80 h-auto" formControlName="Descripiton" rows="3"></textarea>
                            <div *ngIf="f.Descripiton.invalid && (f.Descripiton.dirty || f.Descripiton.touched)" class="alert alert-danger">
                              <div *ngIf="f.Descripiton.errors.required">{{'Descripiton is required' | translate}}. </div>
                            </div>
                          </div>
                        </div>





                        <!-- <div class="row">
                  <div class="col-lg-12 col-sm-12">
                    <hr class="border-bottom" />
                  </div>
                </div> -->
                        <!-- <div class="row">
                  <div class="form-group col-lg-12 col-sm-12">
                    <label>{{'Order Types' | translate}}</label>
                  </div>
                </div>
                <div class="row">
                  <div *ngFor="let ot of OrderType" class="form-group col-lg-6 col-sm-6">
                    <input type="checkbox" id="{{ot.orderTypeID}}" class="form-check-inline mx-2 align-middle" [ngModelOptions]="{standalone: true}" [(ngModel)]="ot.allowed" />
                    <span for="{{ot.orderTypeID}}" class="align-middle">
                      {{ot.name | translate}}
                    </span>
                  </div>
                </div> -->

                      </div>
                    </section>
                    <section class="tile" [ngClass]="{'lazy': loadingLocation}" style="overflow:unset">
                      <div class="tile-header">
                        <h6>
                          {{'Address' | translate}}
                          <span class="highlight-title-blue move-to-end" (click)="openMap()" role="button">
                            {{'Quick Lookup' | translate }}
                            <img src="../../../../../assets/images/icons/v2/navigate.svg" class="mr-1" />
                          </span>
                        </h6>
                      </div>
                      <div class="tile-body">
                        <div class="row">
                          <div class="form-group col-lg-6 col-sm-6">
                            <label for="Country">{{'Country' | translate}}</label>
                            <select class="custom-select form-control custom-select-2" formControlName="CountryID" (change)="loadCities()">
                              <option [value]="option.code"
                                      selected="option.Code == CountryID"
                                      *ngFor="let option of Countries">
                                {{option.name}}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-lg-6 col-sm-6">
                            <label for="City">{{'City' | translate}}</label>
                            <select class="custom-select form-control custom-select-2" formControlName="CityID">
                              <option [ngValue]="option.id"
                                      selected="option.id == CityID"
                                      *ngFor="let option of Cities">
                                {{option.name}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6 col-sm-6">
                            <label for="StreetName">{{'Address Line 1 (Street Name)' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="text" class="form-control" id=StreetName placeholder="" formControlName="StreetName">
                            <div *ngIf="f.StreetName.invalid && (f.StreetName.dirty || f.StreetName.touched)" class="alert alert-danger">
                              <div *ngIf="f.StreetName.errors.required">
                                {{'Street Name is required' | translate}}.
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6 col-sm-6">
                            <label for="AdditionalNumber">{{'Address Line 2 (Additional No)' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="text" class="form-control" id=AdditionalNumber placeholder="" formControlName="AdditionalNumber">
                            <div *ngIf="f.AdditionalNumber.invalid && (f.AdditionalNumber.dirty || f.AdditionalNumber.touched)" class="alert alert-danger">
                              <div *ngIf="f.AdditionalNumber.errors.required">
                                {{'Additional No is required' | translate}}.
                              </div>
                              <div *ngIf="f.AdditionalNumber.invalid">{{'Additional No must have 4 values. e.g: XXXX' | translate }}. </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6 col-sm-6">
                            <label for="District">{{'District' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="text" class="form-control" id=District placeholder="" formControlName="District">
                            <div *ngIf="f.District.invalid && (f.District.dirty || f.District.touched)" class="alert alert-danger">
                              <div *ngIf="f.District.errors.required">
                                {{'District is required' | translate}}.
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6 col-sm-6">
                            <label for="PostalCode">{{'Postal Code' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="text" class="form-control" id=PostalCode placeholder="" formControlName="PostalCode">
                            <div *ngIf="f.PostalCode.invalid && (f.PostalCode.dirty || f.PostalCode.touched)" class="alert alert-danger">
                              <div *ngIf="f.PostalCode.errors.required">
                                {{'Postal Code is required' | translate}}.
                              </div>
                              <div *ngIf="f.PostalCode.invalid">{{'Postal Code must have 5 values. e.g: XXXXX' | translate }}. </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6 col-sm-6">
                            <label for="BuildingNumber">{{'Building Number' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="text" class="form-control" id=BuildingNumber placeholder="" formControlName="BuildingNumber">
                            <div *ngIf="f.BuildingNumber.invalid && (f.BuildingNumber.dirty || f.BuildingNumber.touched)" class="alert alert-danger">
                              <div *ngIf="f.BuildingNumber.errors.required">
                                {{'Building Number is required' | translate}}.
                              </div>
                              <div *ngIf="f.BuildingNumber.invalid">{{'Building Number must have 4 values. e.g: XXXX' | translate }}. </div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6 col-sm-6">
                            <label for="Address">{{'Short Address' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="text" class="form-control" id=Address placeholder="" formControlName="Address">
                            <div *ngIf="f.Address.invalid && (f.Address.dirty || f.Address.touched)" class="alert alert-danger">
                              <div *ngIf="f.Address.errors.required">
                                {{'Address is required' | translate}}.
                              </div>
                              <div *ngIf="f.Address.invalid">{{'Address must have 4 letters and 4 numbers. e.g: XXXX0000' | translate }}. </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6 col-sm-6">
                            <label for="Coordinates">{{'Coordinates' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="text" class="form-control" id=Coordinates placeholder="" formControlName="Coordinates">
                          </div>
                        </div>
                      </div>
                    </section>

                    <section class="tile" [ngClass]="{'lazy': loadingLocation}" style="overflow:unset">
                      <div class="tile-header">
                        <h6>{{'Order Types' | translate}}</h6>
                      </div>
                      <div class="tile-body">
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label>{{'Order Types' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <!--<div *ngFor="let ot of OrderType" class="form-group col-lg-6 col-sm-6">
                      <input type="checkbox" id="{{ot.orderTypeID}}" class="form-check-inline" [ngModelOptions]="{standalone: true}" [(ngModel)]="ot.allowed" />
                      <label for="{{ot.orderTypeID}}">
                        {{ot.name | translate}}
                      </label>
                    </div>-->
                            <ng-select [items]="OrderType" bindLabel="name" class="custom" bindValue="orderTypeID" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="OrderTypeAllowed">
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items">
                                  <span class="ng-value-label"> {{item.name}}  </span>
                                  <span class="ng-value-icon right" style="border-left: 1px solid #1f2532;" (click)="clear(item)" aria-hidden="true">×</span>
                                </div>
                              </ng-template>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </section>
                    <!-- LocationInfo start -->
                    <!-- Delivery Details start -->
                    <section class="tile" [ngClass]="{'lazy': loadingLocation}">
                      <div class="tile-header">
                        <h6>
                          {{'Delivery Details' | translate}}
                          <!--<div class="form-check form-switch" style="float: right;margin-top: -8px;">
                    <input type="checkbox" class="form-check-input" formControlName="IsDelivery" checked id="IsDelivery">
                    <label class="form-check-label" for="IsDelivery"></label>
                  </div>-->
                        </h6>
                      </div>
                      <div class="tile-body">
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="MinimumOrderAmount">{{'Minimum Order Amount' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <div class="input-group mb-3">
                              <input type="number" class="form-control" formControlName="MinOrderAmount" aria-label="Default" aria-describedby="inputGroup-sizing-default" required>
                              <div class="input-group-append">
                                <span class="input-group-text" id="inputGroup-sizing-default">{{brandCurrency | translate}}</span>
                              </div>
                            </div>
                            <div *ngIf="f.MinOrderAmount.invalid && (f.MinOrderAmount.dirty || f.MinOrderAmount.touched)" class="alert alert-danger">
                              <div *ngIf="f.MinOrderAmount.errors.required">{{'Min Order Amount is required' | translate}}. </div>
                            </div>
                          </div>
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="Deliverycharges">{{'Delivery charges' | translate}}</label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <div class="input-group mb-3">
                              <input type="number" class="form-control" formControlName="DeliveryCharges" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                              <div class="input-group-append">
                                <span class="input-group-text" id="inputGroup-sizing-default">{{brandCurrency | translate}}</span>
                              </div>
                            </div>
                            <div *ngIf="f.DeliveryCharges.invalid && (f.DeliveryCharges.dirty || f.DeliveryCharges.touched)" class="alert alert-danger">
                              <div *ngIf="f.DeliveryCharges.errors.required">{{'Delivery Charges is required' | translate}}. </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="AverageDeliveryTime">
                              {{'Average Delivery Time' | translate}}
                            </label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <div class="input-group mb-3">
                              <input type="text" class="form-control" formControlName="DeliveryTime" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                              <div class="input-group-append">
                                <span class="input-group-text" id="inputGroup-sizing-default">
                                  {{'mins' | translate}}
                                </span>
                              </div>
                            </div>
                            <div *ngIf="f.DeliveryTime.invalid && (f.DeliveryTime.dirty || f.DeliveryTime.touched)" class="alert alert-danger">
                              <div *ngIf="f.DeliveryTime.errors.required">{{'Delivery Time is required' | translate}}. </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="MarnNow">{{'Marn Now' | translate}}</label>
                            <select class="custom-select form-control custom-select-2" formControlName="MarnNowPartnerID">
                              <option>
                                {{ 'None' | translate}}
                              </option>
                              <option [value]="option.partnerID"
                                      *ngFor="let option of Partners">
                                {{option.name}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </section>

                    <!-- Delivery Details start -->
                    <!-- <button [disabled]="loading" class="btn btn-primary custom-submit">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{ButtonText | translate}}
            </button> -->
                    <!-- Partner Orders start -->
                    <section class="tile" [ngClass]="{'lazy': loadingLocation}">
                      <div class="tile-header">
                        <h6>
                          {{'Partner Orders' | translate}}
                        </h6>
                      </div>
                      <div class="tile-body">
                        {{'Auto Accept' | translate}}
                        <br />
                        <label class="pt-1">
                          {{'Orders coming from partners will be automatically accepted' | translate}}:
                        </label>
                        <div class="position-relative pt-4">
                          <section class="tile">
                            <!--[ngClass]="{'lazy': (loading$ | async)}"-->
                            <div class="tile-body p-0 table-responsive">
                              <table class="table table-striped">
                                <thead>
                                  <tr class="table-header">
                                    <th width="10%">{{'Partner' | translate }}</th>
                                    <th width="30%"></th>
                                    <th width="30%">{{'Auto Accept' | translate }}</th>
                                    <th width="30%">{{'Device' | translate }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let partner of partnersAutoOrder">
                                    <td>
                                      <img [src]="partner.logo" class="d-inline" width="40" height="40" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                                    </td>
                                    <td class="text-start name-blue">
                                      {{partner.name}}
                                    </td>
                                    <td>
                                      <div class="d-flex justify-content-center">
                                        <div class="form-check form-switch float-start">
                                          <input type="checkbox" class="form-check-input" [ngModelOptions]="{standalone: true}" [(ngModel)]="partner.status">

                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="d-flex justify-content-end">
                                        <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" *ngIf="(!f.LocationID.value || f.LocationID.value == 0) && partner.status"
                                             placement="top" ngbTooltip="{{'Auto accept Orders can not be updated while creating new location' | translate}}" />

                                        <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" *ngIf="(!partner.terminalID || partner.terminalID == 0) && f.LocationID.value && partner.status"
                                             placement="top" ngbTooltip="{{'Please select device' | translate}}" />

                                        <select class="w-75 custom-select form-control custom-select-2" [ngModelOptions]="{standalone: true}" [(ngModel)]="partner.terminalID"
                                                [disabled]="(!partner.status)? true : null" [class.disabled]="!partner.status">
                                          <option value=0 selected="option.terminalID == terminalID">
                                            {{'Select Device' | translate}}
                                          </option>
                                          <option [ngValue]="option.terminalID"
                                                  selected="option.terminalID == terminalID"
                                                  *ngFor="let option of devices">
                                            {{option.terminalName}}
                                          </option>
                                        </select>


                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </section>
                        </div>
                      </div>
                    </section>
                    <!-- Partner Orders end-->


                  </div>
                  <div class="col-lg-4 col-md-5 col-sm-12 ">
                    <!-- Time Settings start -->
                    <section class="tile timesetting" [ngClass]="{'lazy': loadingLocation}">
                      <div class="tile-header">
                        <h6>{{'Time Settings' | translate}} </h6>
                      </div>
                      <div class="tile-body">
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="Timezone">{{'Timezone' | translate}} </label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <select class="custom-select form-control custom-select-2" formControlName="TimeZoneID">
                              <option [ngValue]="option.timeZoneID"
                                      selected="option.timeZoneID == TimeZoneID"
                                      *ngFor="let option of TimeZones">
                                {{option.displayName}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <div class="input-group">
                              <span class="input-group-text" id="inputGroup-sizing-default" style=" border-bottom: 0 !important; border-bottom-left-radius: 0; width:160px">
                                {{'Opening Time' | translate}}
                              </span>
                              <input type="time" value="08:00" class="form-control custom-timepicker" placeholder="" formControlName="Open_Time" style=" border-bottom: 0 !important;" required>
                              <div *ngIf="f.Open_Time.invalid && (f.Open_Time.dirty || f.Open_Time.touched)" class="alert alert-danger">
                                <div *ngIf="f.Open_Time.errors.required">{{'Open Time Time is required' | translate}}. </div>
                              </div>
                            </div>

                            <div class="input-group">
                              <span class="input-group-text" id="inputGroup-sizing-default" style="border-top-left-radius: 0 !important; width: 160px ">
                                {{'Closing Time' | translate}}
                              </span>
                              <input type="time" value="23:00" class="form-control custom-timepicker" placeholder="" formControlName="Close_Time" style=" border-top-right-radius: 0 !important; border-bottom-right-radius: 6px !important;" required>
                              <div *ngIf="f.Close_Time.invalid && (f.Close_Time.dirty || f.Close_Time.touched)" class="alert alert-danger">
                                <div *ngIf="f.Close_Time.errors.required">{{'Close Time Time is required' | translate}}. </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <div class="status-activebar">
                              <label for="Status">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                {{'Status' | translate }}
                              </label>
                              <div class="form-check form-switch p-0">
                                <span class="inactive" (click)="f.StatusID.value=false"
                                      [className]="f.StatusID.value==false ? 'active' : 'inactive'" *ngIf="f.LocationID.value === 0 || (f.LocationID.value !== 0 && statusValue==false)">
                                  {{ 'Inactive' | translate}}
                                </span>
                                <span class="inactive active" (click)="f.StatusID.value=true"
                                      [className]="f.StatusID.value==true ? 'active' : 'inactive'">
                                  {{'Active' | translate}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="row">
                  <div class="form-group col-lg-12 col-sm-12">
                    <label for="ClosingTime">
                      <img class="mr-8"
                           src="/assets/images/icons/time.svg" alt="time">
                      {{'Closing Time' | translate}}
                    </label>
                    <input type="time" value="23:00" class="form-control custom-timepicker" placeholder="" formControlName="Close_Time" required>
                    <div *ngIf="f.Close_Time.invalid && (f.Close_Time.dirty || f.Close_Time.touched)" class="alert alert-danger">
                      <div *ngIf="f.Close_Time.errors.required">{{'Close Time Time is required' | translate}}. </div>
                    </div>
                  </div>
                </div>-->
                      </div>
                    </section>
                    <!-- Time Settings start -->
                    <!-- Licensing start -->
                    <section class="tile">
                      <div class="tile-header">
                        <h6>{{'Licensing' | translate}} </h6>
                      </div>
                      <div class="tile-body">
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="License">{{'License' | translate}}  </label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <select class="custom-select form-control custom-select-2" formControlName="LicenseID" [attr.disabled]="(f.LocationID.value !== 0)? true : null" [class.disabled]="f.LocationID.value !== 0">
                              <option value=0
                                      selected="option.licenseID == LicenseID">
                                {{'Select License' | translate}}
                              </option>
                              <option [ngValue]="option.licenseID"
                                      [disabled]=option.maped
                                      selected="option.licenseID == LicenseID"
                                      *ngFor="let option of Licenses">
                                {{option.licenseNo}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </section>
                    <!-- Licensing end -->
                    <!-- Integration start -->
                    <section class="tile" *ngIf="locationKey">
                      <div class="tile-header">
                        <h6>{{'Integration' | translate}} </h6>
                      </div>
                      <div class="tile-body">
                        <div class="row">
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="License">{{'Location Key' | translate}}  </label>
                            <img src="../../../../../assets/images/icons/v2/Info.svg" class="mx-1" />
                            <input type="text" class="form-control disabled" id=LocationKey placeholder="" [value]="locationKey" readonly="readonly">
                          </div>
                        </div>
                      </div>
                    </section>
                    <!-- Integration end -->
                  </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary custom-submit" (click)="onSubmit()" *hasPermission="['Locations', (f.LocationID.value > 0) ? 'Edit' : 'New']">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{ButtonText | translate}}
                </button>
              </form>
            </ng-template>
            <!-- location templete end -->
          </li>
          <li ngbNavItem="locationItem" id="locationItem" [disabled]="f.LocationID.value == 0" *hasPermission="['Locations','Items']" (click)="active='locationItem';">
            <a ngbNavLink>{{'Items' | translate}}  </a>
            <ng-template ngbNavContent>
              <app-location-items [LogEventType]="'screen'" [LogEvent]="'Location Items'"></app-location-items>
            </ng-template>
          </li>
          <li ngbNavItem="locationDevices" id="locationDevices" [disabled]="f.LocationID.value == 0" *hasPermission="['Locations','Device']" (click)="active='locationDevices';">
            <a ngbNavLink>{{'Devices' | translate}} </a>
            <ng-template ngbNavContent>
              <app-devices [LogEventType]="'screen'" [LogEvent]="'Location Devices'"></app-devices>
            </ng-template>
          </li>
          <li ngbNavItem="users" id="users" [disabled]="f.LocationID.value == 0" *hasPermission="['Setup','Users','Users']" (click)="active='users';">
            <a ngbNavLink>{{'Users' | translate}} </a>
            <ng-template ngbNavContent>
              <app-userlist [LogEventType]="'screen'" [LogEvent]="'Users'"></app-userlist>
            </ng-template>
          </li>
          <li ngbNavItem="locationFloors" id="locationFloors" [disabled]="f.LocationID.value == 0" *hasPermission="['Locations','Floors']" (click)="active='locationFloors';">
            <a ngbNavLink>{{'Floors' | translate}} </a>
            <ng-template ngbNavContent>
              <app-floor [LogEventType]="'screen'" [LogEvent]="'Location Floors'"></app-floor>
            </ng-template>
          </li>
          <li ngbNavItem="locationTables" id="locationTables" [disabled]="f.LocationID.value == 0" *hasPermission="['Locations','Tables']" (click)="active='locationTables';">
            <a ngbNavLink>{{'Tables' | translate}} </a>
            <ng-template ngbNavContent>
              <app-tables [LogEventType]="'screen'" [LogEvent]="'Location Tables'"></app-tables>
            </ng-template>
          </li>
          <li ngbNavItem="locationInventory" id="locationInventory" [disabled]="f.LocationID.value == 0" *hasPermission="['Locations','Inventory']" (click)="active='locationInventory';">
            <a ngbNavLink>{{'Inventory' | translate}}</a>
            <ng-template ngbNavContent>
              <app-location-inventory [LogEventType]="'screen'" [LogEvent]="'Location Inventory'"></app-location-inventory>
            </ng-template>
          </li>


          <li ngbNavItem="locationStats" id="locationStats" [disabled]="f.LocationID.value == 0" *hasPermission="['Locations','Stats']" (click)="active='locationStats';">
            <a ngbNavLink>{{'Stats' | translate}}</a>
            <ng-template ngbNavContent>
              <app-location-stats [LogEventType]="'screen'" [LogEvent]="'Location Stats'"></app-location-stats>
            </ng-template>
          </li>
          <!--*hasPermission="['ZATCAIntegration']"-->
          <li *ngIf="checkZatcaCompliance" id="ZATCAIntegration" ngbNavItem="ZATCAIntegration" [disabled]="f.LocationID.value == 0" (click)="active='ZATCAIntegration';">
            <a ngbNavLink>{{'Integration' | translate}}</a>
            <ng-template ngbNavContent>
              <app-location-integration></app-location-integration>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
