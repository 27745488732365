import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { LocationGroupService } from '../../../_services/location-group.service';
import { LocationService } from '../../../_services/location.service';

@Component({
  selector: 'app-create-location-group',
  templateUrl: './create-location-group.component.html',
  styleUrl: './create-location-group.component.css'
})
export class CreateLocationGroupComponent {
  @Input() SelectedGroup = null;

  submitted = false;
  locationGroupForm: UntypedFormGroup;
  loading = false;
  LocationsList = [];
  selectedLocationIds: string[];
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private service: LocationGroupService,
  ) {
    this.createForm();
  }
  private createForm() {
    //this.Location = DataService.Name;
    this.locationGroupForm = this.formBuilder.group({
      groupID: [0],
      name: ['', Validators.required],
      alternateName: [null],
      description: [null],
      locationIds: [null],
      statusID: [1, Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID)
    });
  }
  get f() { return this.locationGroupForm.controls; }
  ngOnInit() {
    this.editForm(this.SelectedGroup);
    this.loadLocations();
  }




  private editForm(item) {
    if (item) {
      this.f.groupID.setValue(item.groupID);
      this.f.name.setValue(item.name);
      this.f.alternateName.setValue(item.alternateName);
      this.f.description.setValue(item.description);
      this.f.statusID.setValue(item.statusID);
      if (item.locationIds) {
        this.f.locationIds.setValue(item.locationIds);
        this.selectedLocationIds = item.locationIds.split(',').map(entry => { return Number(entry) });
      }
    }
  }
  loadLocations() {
    this.service.getAllLocationsddl(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      this.LocationsList = res;
    });
  }


  onSubmit() {
    this.locationGroupForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    if (this.locationGroupForm.invalid) { return; }
    this.loading = true;

    if (this.selectedLocationIds) {
      this.f.locationIds.setValue(this.selectedLocationIds.join(','));
    }
    this.service.save(this.locationGroupForm.value).subscribe(data => {
      this.alertService.success("Location Group has been created");
      this.loading = false;
      this.activeModal.dismiss();

    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });


  }

}
