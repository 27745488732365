import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import * as myGlobals from '../_helpers/globals';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})

export class ZATCAService {
  URL: string = null;
  industry: string = null;
  checkZatcaCompliance: boolean = false;
  private http: HttpClient;
  constructor(private handler: HttpBackend,
    private localStorageService: LocalStorageService) {
    this.URL = environment.ZATCA_Config.URL;
    this.industry = myGlobals.industryName(this.localStorageService);
    this.checkZatcaCompliance = this.localStorageService.getSelectedBrand().ZATCACompliance;
  }
  PostInvoice(data) {
    if (this.checkZatcaCompliance) {
      let order = {
        orderID: data
      };
      this.http = new HttpClient(this.handler);
      const headerDict = {
        'Terminal-Auth-Code': data
      };

      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      let d = JSON.stringify(order);
      return this.http.post(this.URL + `api/${this.industry}/invoice/PostByID`, order, requestOptions)
        .pipe(map(res => {
          return res;
        }));
    }
  }
}
