import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../_directives/sortable.directive';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { AlertService } from '../../_services/alert.service';
import { CouponService } from '../../_services/coupon.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { CouponDto } from '../../_models/CouponDto';
import { AddCouponComponent } from './add-coupon/add-coupon.component';
@Component({
  selector: 'app-coupons-list',
  templateUrl: './coupons-list.component.html',
  styleUrl: './coupons-list.component.css'
})
export class CouponsListComponent {

  StatusList = [];
  CreatedByList = [];
  coupons$: Observable<CouponDto[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  checkAllListSubscription: Subscription;
  couponSubscription: Subscription;
  activeCount = 0;
  inActiveCount = 0;
  checkAllCreatedBy: boolean = false;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: CouponService,
    private router: Router,
    private modalService: NgbModal,
    private trackEvents: TrackEventsService,
    private alertService: AlertService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private confirmationDialogService: ActionConfirmationService) {

  }
  ngOnInit() {
    this.service.headerFilter = { createdBy: [''], status: [''] };
    this.getData();

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  getData() {
    this.service.getCoupons(this.localStorageService.getSelectedBrand().BrandID);

    this.coupons$ = this.service.coupons$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    this.couponSubscription=this.service.allData$.subscribe(res => {
      this.CreatedByList = [];
      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
        new Set(res.map(val => val.createdBy)).forEach(e => {
          this.CreatedByList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

      }
    });

  }
  open() {
    const modalRef = this.modalService.open(AddCouponComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Coupon");
    modalRef.componentInstance.SelectedID = null;
    modalRef.result.then((result) => {
      this.getData();
    }, (reason) => {
      this.getData();
    });

  }
  Edit(item) {
    const modalRef = this.modalService.open(AddCouponComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Coupon");
    modalRef.componentInstance.SelectedID = item.couponID;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });
  }

  public bulkSelection($event) {
    if (this.service.allData$) {
      this.checkAllListSubscription = this.service.allData$.subscribe(i => i.forEach(e => {
        if (this.service.searchTerm) {
          if (this.service.matches(e, this.service.searchTerm))
            e.selected = $event.target.checked;
        }
        else {
          e.selected = $event.target.checked;
        }
      }));
      this.checkAllListSubscription.unsubscribe();
    }
  }



  public openConfirmationDialog(item) {
    this.confirmationDialogService.confirm('Please confirm..', item.name)
      .then((confirmed) => {
        if (confirmed) {

          this.service.delete(parseInt(item.couponID), item.rowVersion).subscribe((res: any) => {
            this.alertService.success("Coupon has been Deleted");
            this.getData();
          }, error => {
            this.alertService.error(error);
          });


        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }

  SelectedStatus = '';
  SelectedCreatedBy = '';
  filters() {

    if (this.CreatedByList.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllCreatedBy = false;
    }
    else {
      this.checkAllCreatedBy = true;
    }

    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
      createdBy: this.CreatedByList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name })
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filters();
  }


  setStatus(item) {
    if (item.statusID === 1) {
      item.statusID = 2;
    }
    else {
      item.statusID = 1;
    }
    this.service.setStatus(parseInt(item.couponID), item.rowVersion).subscribe((res: any) => {
      if (parseInt(item.statusID) === 1)
        this.alertService.success("Coupon has been Actived");
      else
        this.alertService.success("Coupon has been Deactived");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.couponSubscription !== undefined)
      this.couponSubscription.unsubscribe();
  
  }
}
