<div class="mnt-header position-relative order-list">





  <div class="row flex-row-reverse mb-4 tCustom-header margin-top-minus-20" *ngIf="(service.subUsers?.length > 0)">

    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Users' | translate }} </h5>
    </div>


    <div class="col-md-auto" *hasPermission="['Setup','Users','Users', 'Insert']">
      <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
        {{'New User' | translate }}
      </button>
    </div>

    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
  </div>

  @if((data && data.length>0) ){

  <section class="tile" [ngClass]="{'lazy': loading }">
    <div class="tile-body p-0 table-responsive text-nowrap">
      <table class="table table-striped text-start">
        <thead>
          <tr class="table-header">
            <th width="20%" sortable="firstName" (sort)="onSort($event)">{{'Username' | translate }} <img src="assets/images/icons/sorting-icon.png" /></th>
            <th width="12%">

              <!--  UserType -->
              <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data)?.length <=5 ? 'body':'table'">
                <button class="btn btn-outline-filter" id="ddlUserTypeFilter" ngbDropdownToggle>
                  <span>{{SelectedUserType | translate}} </span>
                  <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                </button>
                <div ngbDropdownMenu aria-labelledby="ddlUserTypeFilter dropdown-header-filter-body"
                     class="dropdown-header-filter-body ddl-width-sm">
                  <div>
                    <div class="form-check" ngbDropdownToggle>
                      <input type="radio" class="form-check-input" [(ngModel)]="SelectedUserType" value="All Access Type"
                             name="usertypeOption" id="dropdownUserType_all" (change)="selectAllFilters($event,UserTypeList)">
                      <label class="form-check-label font-blue" for="dropdownUserType_all"
                             (click)="selectAllFilters($event,UserTypeList)">
                        {{'All Access Type' | translate}}
                      </label>
                    </div>
                  </div>
                  <div *ngFor="let usertype of UserTypeList; let i = index">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="radio" class="form-check-input" name="usertypeOption" [value]="usertype.name"
                             id="dropdownUserType_{{i+1}}" [(ngModel)]="SelectedUserType" (ngModelChange)="filters()">
                      <label class="form-check-label" for="dropdownUserType_{{i+1}}" (click)="filters()">
                        {{usertype.name | translate}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </th>
            <th width="15%" sortable="contactNo" (sort)="onSort($event)">{{'Phone No#' | translate }} <img src="assets/images/icons/sorting-icon.png" /></th>
            <th width="20%" sortable="email" (sort)="onSort($event)">{{'Email/Passcode' | translate }} <img src="assets/images/icons/sorting-icon.png" /></th>
            <th width="18%" sortable="groupName" (sort)="onSort($event)">{{'Roles' | translate }} <img src="assets/images/icons/sorting-icon.png" /></th>
            <th width="10%">

              <!-- Status Filter -->
              <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data)?.length <=5 ? 'body':'table'">
                <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                  <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                  <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                </button>
                <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                  <div class="form-check" ngbDropdownToggle>
                    <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                           name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                    <label class="form-check-label font-blue" for="dropdownStatus_all"
                           (click)="selectAllFilters($event,StatusList)">
                      {{'All Status' | translate}}
                    </label>
                  </div>
                  <div *ngFor="let item of StatusList; let i = index">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                      <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>

                        <!-- {{item.name}} -->

                        <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                       class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                        </ngb-highlight>


                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </th>
            <th width="5%"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let subUser of data" editRow>
            <td (click)="getUser(subUser)" class="name-blue">
              {{subUser.firstName +' '+subUser.lastName }}
            </td>
            <td (click)="getUser(subUser)">{{subUser.userType.replace(',',' & ') | translate}}</td>

            <td (click)="getUser(subUser)">{{subUser.contactNo}}</td>
            <td (click)="getUser(subUser)">
              @if(subUser.userType.includes('App') && subUser.userType.includes('Dashboard'))
              {
              {{subUser.email}}/{{subUser.passcode}}
              }
              @else if(subUser.userType.includes('App'))
              {
              {{subUser.passcode}}
              }
              @else
              {
              {{subUser.email}}
              }

            </td>
            <td (click)="getUser(subUser)" class="text-wrap">
              @if(subUser.groupName.includes(',')){
              @for(option of subUser.groupName.split(','); track option){
              <span class="my-1 btn btn-sm w-auto {{option.split(':')[1]}}" style="border-radius: 6px;">{{option.split(':')[0] }}</span>
              &nbsp;
              }
              }
              @else{
              <span class="btn btn-sm w-auto {{subUser.groupName.split(':')[1]}}" style="border-radius: 6px;">{{subUser.groupName.split(':')[0] }}</span>
              }
            </td>
            <td (click)="getUser(subUser)">
              <ngb-highlight [ngClass]="{'btn-active': subUser.statusID === 1, 'btn-inactive':subUser.statusID !== 1 }" class="btn btn-sm" [result]="(subUser.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
              </ngb-highlight>
            </td>
            <td>
              <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
              </div>
              <ng-template #popContent>
                <p class="dropdown-heading-mini">{{'User' | translate }}</p>

                <a *hasPermission="['Setup','Users','Users', 'Edit']" (click)="getUser(subUser)">{{'Edit' | translate }}</a>
                <span *hasPermission="['Setup','Users','Users', 'Deactivate']">
                  <a *ngIf="subUser.statusID === 1" (click)="setStatus(subUser.subUserID,2,subUser.rowVersion)">{{'Deactivate' | translate }}</a>
                  <a *ngIf="subUser.statusID === 2" (click)="setStatus(subUser.subUserID,1,subUser.rowVersion)">{{'Activate' | translate }}</a>
                </span>
                <a *hasPermission="['Setup','Users','Users', 'Delete']" (click)="openConfirmationDialog(subUser.subUserID,subUser.rowVersion,subUser.firstName +' '+subUser.lastName)">{{'Delete' | translate }}</a>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
  <div class="row mt-10">

    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
      <p class="pagination-count">
        <strong class="p-4">
          {{'Showing' | translate }} {{(service.pageSize * service.page)-9}} {{'of' | translate }} {{(total)!}} {{'results' | translate }}
        </strong>
      </p>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
        <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
        <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
        <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
        <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>
    </div>
  </div>
  }
  @else{

  <div class="blank-order">
    <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
    <strong>{{'No User yet' | translate }}</strong>
    <p class="text-center width-375">{{'Create new user to access Web and App.' | translate}}</p>
  </div>
  <div class="items-flex">
    <div class="create-btn mlmr-10">
      <div>
        <button type="button" (click)="open()" *hasPermission="['Setup','Users','Users', 'Insert']" class="btn btn-primary cutom-button form-control custom-create-btn">
          {{'+ Create New User' | translate }}
        </button>
      </div>
    </div>


  </div>
  }







</div>
