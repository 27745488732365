<main class="views container order-list">
  <div class="page">
    <div class="page-header">
      <div class="tile-header tCustom-header location">
        <div class="row">
          <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
            <h4>{{'Locations' | translate}}</h4>
          </div>
          <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
            <div class="top-bar-flex">
              <div class="filter-field">
                <input class="form-control search-text" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
              </div>
              <div class="create-btn" *hasPermission="['Locations', 'New']">
                <button type="button" (click)="New()" class="btn btn-primary cutom-button custom-create-btn ms-3">
                  {{'+ New Location' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="page-body position-relative">
      <section>
        <div class="row">
          <div class="col-12">
            <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">

              <div class="tile-body table-responsive p-0 text-nowrap">
                <table class="table table-striped location table-custom text-start p-0">
                  <thead>
                    <tr class="table-header">
                      <th width="20%" scope="col" sortable="name" (sort)="onSort($event)">{{'Location Name' | translate}} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                      <th width="15%" scope="col">
                        <!-- City Filter -->
                        <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(locations$ | async)?.length <=5 ? 'body':'table'">
                          <button class="btn btn-outline-filter" id="ddlCityFilter" ngbDropdownToggle>
                            <span>{{SelectedCity | translate}} </span>
                            <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                          </button>
                          <div ngbDropdownMenu aria-labelledby="ddlCityFilter dropdown-header-filter-body"
                               class="dropdown-header-filter-body ddl-width-sm">
                            <div>
                              <div class="form-check" ngbDropdownToggle>
                                <input type="radio" class="form-check-input" [(ngModel)]="SelectedCity" value="All Cities"
                                       name="cityOption" id="dropdownCity_all" (change)="selectAllFilters($event,CityList)">
                                <label class="form-check-label font-blue" for="dropdownCity_all"
                                       (click)="selectAllFilters($event,CityList)">
                                  {{'All Cities' | translate}}
                                </label>
                              </div>
                            </div>
                            <div *ngFor="let city of CityList; let i = index">
                              <div class="form-check" ngbDropdownToggle>
                                <input type="radio" class="form-check-input" name="cityOption" [value]="city.name"
                                       id="dropdownCity_{{i+1}}" [(ngModel)]="SelectedCity" (ngModelChange)="filters()">
                                <label class="form-check-label" for="dropdownCity_{{i+1}}" (click)="filters()">
                                  {{city.name}}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>



                      </th>
                      <th width="20%" scope="col" sortable="address">{{'Address' | translate}}</th>
                      <th width="11%" scope="col" sortable="contactNo">{{'Contact' | translate}}</th>
                      <th width="11%" scope="col" sortable="email" (sort)="onSort($event)">{{'Email' | translate}} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                      <th width="7%" scope="col">

                        <!-- Status Filter -->
                        <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(locations$ | async)?.length <=5 ? 'body':'table'">
                          <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                            <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                            <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                          </button>
                          <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                            <div class="form-check" ngbDropdownToggle>
                              <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                                     name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                              <label class="form-check-label font-blue" for="dropdownStatus_all"
                                     (click)="selectAllFilters($event,StatusList)">
                                {{'All Status' | translate}}
                              </label>
                            </div>
                            <div *ngFor="let item of StatusList; let i = index">
                              <div class="form-check" ngbDropdownToggle>
                                <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                                <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                                  <!-- {{item.name}} -->

                                  <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                                 class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                                  </ngb-highlight>


                                </label>
                              </div>
                            </div>
                          </div>
                        </div>



                      </th>
                      <th width="5%" scope="col">

                      </th>
                    </tr>
                  </thead>
                  <tbody *ngIf="(loading$ | async)==false">
                    <tr *ngFor="let item of locations$ | async " editRow>
                      <td (click)="Edit(item.locationID)" class="name-blue">
                        <a>
                          <ngb-highlight class="" (click)="Edit(item.locationID, item.name)" [result]="item.name" [term]="service.searchTerm"></ngb-highlight>
                          <!-- class=item-title -->
                        </a>
                      </td>
                      <td (click)="Edit(item.locationID)">
                        <ngb-highlight [result]="item.city" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td (click)="Edit(item.locationID)">
                        <ngb-highlight [result]="item.address" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td (click)="Edit(item.locationID)">
                        <ngb-highlight [result]="item.contactNo" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td (click)="Edit(item.locationID)">
                        <ngb-highlight [result]="item.email" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td (click)="Edit(item.locationID)">
                        <ngb-highlight [ngClass]="{'btn-active': item.statusID === 1, 'btn-inactive':item.statusID !== 1 }" class="btn btn-sm" [result]="(item.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <img class="doted-list-action rotate-90" [placement]="['left', 'right']" [ngbPopover]="popContent" src="/assets/images/dots-list.svg" />
                        <ng-template #popContent>
                          <p class="dropdown-heading-mini">{{'QUICK ACTION' | translate}}</p>
                          <a *hasPermission="['Locations', 'Items']" (click)="EditbyType(item.locationID,'item')">{{'Location Items' | translate}}</a>
                          <a *hasPermission="['Locations', 'Tables']" (click)="EditbyType(item.locationID,'table')">{{'Location Tables' | translate}}</a>
                          <a *hasPermission="['Locations', 'Edit']" (click)="Edit(item.locationID)">{{'Edit' | translate}}</a>
                          <!--<a class="selected-inner-text selected-inner-bottom">{{'Delete' | translate}}   </a>-->
                        </ng-template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>


            <div class="row mt-10">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <p class="pagination-count">
                  {{'Showing' | translate }}
                  <!-- <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }} -->
                  <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
                  <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
                </p>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                                [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
                  <ng-template ngbPaginationFirst>
                    <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
                  </ng-template>
                  <ng-template ngbPaginationLast>
                    <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
                  </ng-template>
                  <ng-template ngbPaginationPrevious>
                    <img src="../../../assets/images/icons/backward.svg" />
                  </ng-template>
                  <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
            <!-- </div>
            </div> -->
          </div>
        </div>
      </section>
    </div>
  </div>
</main>
