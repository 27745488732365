import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ImageUploadV2Component } from '../../../image-upload-v2/image-upload-v2.component';
import { SubCategoryService } from 'src/app/_services/subcategory.service';
import { CategoryService } from 'src/app/_services/category.service';
import { ImageuploadComponent } from '../../../imageupload/imageupload.component';

@Component({
  selector: 'app-addsubcategory',
  templateUrl: './addsubcategory.component.html',
  styleUrls: ['./addsubcategory.component.css']
})
export class AddsubcategoryComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  submitted = false;
  subcategoryForm: UntypedFormGroup;
  Categories: [];
  loading = false;
  loadingCategory = false;
  ButtonText = "Save";
  selctedLang = 'en';
  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private subcategoryService: SubCategoryService,
    private categoryService: CategoryService


  ) {
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.createForm();

    this.loadCategories();
  }


  ngOnInit() {
    this.setSelectedCategory();
  }
  get f() { return this.subcategoryForm.controls; }

  private createForm() {

    this.subcategoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9]*(?:[ \-,_+.;:’×“”’’!—…&()%*|/™é][a-zA-Z0-9]*)*[a-zA-Z0-9]$/)]],
      alternateName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Zء-ي0-9][a-zA-Zء-ي0-9]*(?:[ ؛،\-_+.:×“”’’!—…&()%*|/™é–,;][a-zA-Zء-ي0-9]*)*[a-zA-Zء-ي0-9]$/)]],
      description: [''],
      categoryName: [''],
      alternateCategoryName: [''],
      statusID: [true, Validators.required],
      displayOrder: [0, [Validators.required, Validators.min(1), Validators.max(32000)]],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      sortByAlpha: [false],
      categoryID: ['', Validators.required],
      subCategoryID: 0,
      subImage: [''],
      rowVersion: 0
    });
  }



  private editForm(subcategory) {

    this.f.name.setValue(subcategory.name);
    this.f.alternateName.setValue(subcategory.alternateName);
    this.f.alternateCategoryName.setValue(subcategory.alternateCategoryName);
    this.f.categoryName.setValue(subcategory.categoryName);
    this.f.subImage.setValue(subcategory.subImage);
    this.imgComp.imagepath = subcategory.subImage;
    this.f.description.setValue(subcategory.description);
    this.f.displayOrder.setValue(subcategory.displayOrder);
    this.f.rowVersion.setValue(subcategory.rowVersion);
    this.f.statusID.setValue(subcategory.statusID === 1 ? true : false);
    this.f.categoryID.setValue(subcategory.categoryID);
    this.f.subCategoryID.setValue(subcategory.subCategoryID);

  }

  setSelectedCategory() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingCategory = true;
      this.f.subCategoryID.setValue(this.SelectedID);
      this.subcategoryService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingCategory = false;
      });
    }
  }


  onSubmit() {
    this.f.name.setValue(this.f.name.value.trim());
    this.f.alternateName.setValue(this.f.alternateName.value.trim());

    this.subcategoryForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.subcategoryForm.invalid) { return; }
    this.loading = true;

    //this.f.subImage.setValue(this.imgComp.imagepath);
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    
    this.imgComp.upload((this.f.subImage.value && this.f.subImage.value !== '' ? this.f.subImage.value : '')).then(() => {
      this.f.subImage.setValue(this.imgComp.imagepath);
      if (parseInt(this.f.subCategoryID.value) === 0) {

        //Insert item
        console.log(JSON.stringify(this.subcategoryForm.value));
        this.subcategoryService.insert(this.subcategoryForm.value).subscribe(data => {
          this.alertService.success("Sub-category has been created");
          this.loading = false;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
        });

      } else {
        //Update item
        this.subcategoryService.update(this.subcategoryForm.value).subscribe(data => {
          this.alertService.success("Sub-category has been Updated");
          this.loading = false;
          this.SelectedID = 0;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
          this.activeModal.dismiss();
        });
      }
    });
  }

  private loadCategories() {
    this.categoryService.getCategoriesddl(this.f.brandID.value).subscribe((res: any) => {
      this.Categories = res;
    });

  }
  private selectedCategory(id) {
    this.loadDisplayOrder(id);
  }
  loadDisplayOrder(categoryID) {

    this.subcategoryService.getDisplayOrder(this.f.brandID.value, categoryID.value).subscribe((res: any) => {
      this.f.displayOrder.setValue(res);
    });
  }

  close() {
    this.activeModal.dismiss();
  }

}
