<div id="htmlData">
  <div class="container order-pdf p-4">

    <div class="row">
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <img class="img-responsive" [src]="SelectedBrand?.BrandLogo" alt="Brand picture" height="50" width="50"/>
        <br />
        <label class="main-label">
          {{SelectedBrand.BrandName}}
        </label>
        <br />
        <label class="secondary-label">
          {{orderData?.brandDetails.address}}
        </label>
        <br />
        <label class="secondary-label" *ngIf="orderData?.brandDetails && orderData?.brandDetails.vatid && orderData?.brandDetails.vatid !== ''">
          {{"VAT ID" | translate}}: {{orderData?.brandDetails.vatid}}
        </label>
        <br />
        <label class="secondary-label" *ngIf="orderData?.brandDetails && orderData?.brandDetails.crn && orderData?.brandDetails.crn !== ''">
          {{"CR" | translate}}: {{orderData?.brandDetails.crn}}
        </label>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <span class="align-self-end">
          <h5 class="font-weight-bold float-end pe-1 fw-bold mb-0" *ngIf="orderData?.order.orderStatus !== 'Refund'">
            {{'فاتورة ضريبية'}}
            {{"TAX INVOICE" }}
          </h5>
          <h5 class="font-weight-bold float-end pe-1 fw-bold mb-0" *ngIf="orderData?.order.orderStatus === 'Refund'">
            {{'إشعار دائن'}}
            {{"CREDIT NOTE" }}
          </h5>
        </span>
        <br />
        <br />
        <span class="float-end">
          <qrcode [qrdata]="QRCode" [width]="120" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
        </span>
      </div>
      <hr />
    </div>

    <div class="row">
      <div class="col-5 col-sm-5 col-md-5 col-lg-5">
        <label class="main-label" *ngIf="orderData?.customer">
          Customer  العميل
        </label>
        <br />
        <label class="main-label" *ngIf="orderData?.customer && orderData?.customer.customerName && orderData?.customer.customerName !== ''">
          {{orderData?.customer.customerName}}
        </label>
        <br />
        <ng-container *ngIf="orderData?.customer && orderData?.customer.address && orderData?.customer.address !== ''">
          <label class="secondary-label">
            {{orderData?.customer.address}}
          </label>
          <br />
        </ng-container>
        <ng-container *ngIf="orderData?.customer && orderData?.customer.vatID && orderData?.customer.vatID !== ''">
          <label class="secondary-label">
            {{'VAT ID' | translate}}: {{orderData?.customer.vatID }}
          </label>
          <br />
        </ng-container>
        <ng-container *ngIf="orderData?.customer && orderData?.customer.crn && orderData?.customer.crn !== ''">
          <label class="secondary-label">
            {{'CR' | translate}}: {{orderData?.customer.crn }}
          </label>
        </ng-container>
      </div>
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
        <div class="d-flex align-items-end flex-column bd-highlight">

          <div class="bd-highlight">
            <label class="main-label">
              {{'رقم العملية'}}
              {{'Transaction Number'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label">
              {{orderData?.order.deviceTransactionNo}}
            </label>
          </div>

          @if(orderData?.order.orderStatus === 'Refund'){
          <div class="bd-highlight">
            <label class="main-label">
              {{'رقم إشعار دائن'}}
              {{'Credit Note Number'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label">
              {{orderData?.order.refundOrderNo}}
            </label>
          </div>
          }
          @else{
          <div class="bd-highlight">
            <label class="main-label">
              {{'رقم الفاتورة'}}
              {{'Invoice Number'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label">
              {{ orderData?.order.orderNo}}
            </label>
          </div>
          }



          <div class="bd-highlight" *ngIf="orderData?.order.orderStatus === 'Refund'">
            <label class="main-label">
              {{'رقم الفاتورة الأصلية'}}
              {{'Original Invoice Number'}}
            </label>
          </div>
          <div class="bd-highlight" *ngIf="orderData?.order.orderStatus === 'Refund'">
            <label class="secondary-label">
              {{orderData?.order.orderNo}}
            </label>
          </div>


          <div class="bd-highlight">
            <label class="main-label">
              {{'تاريخ الفاتورة'}}
              {{'Invoice Date'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label" *ngIf="orderData?.order.orderStatus !== 'Refund'">
              {{orderData?.order.createdDate  | date}} {{orderData?.order.createdDate  | date:'shortTime'}}
            </label>
            <label class="secondary-label" *ngIf="orderData?.order.orderStatus === 'Refund'">
              {{orderData?.order.refundDate  | date}} {{orderData?.order.refundDate  | date:'shortTime'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="main-label">
              {{'فترة الفاتورة'}}
              {{'Invoice Period'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label">
              {{orderData?.order.orderPeriod.startDate  | date}} {{orderData?.order.orderPeriod.startDate.split('T')[0] + ' '+orderData?.order.orderPeriod.startTime  | date:'shortTime'}} - {{orderData?.order.orderPeriod.endDate  | date}} {{orderData?.order.orderPeriod.endDate.split('T')[0] +' '+ orderData?.order.orderPeriod.endTime  | date:'shortTime'}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <table class="table">
          <thead>
            <tr class="table-header">
              <th width="25%"> تفاصيل المنتج او الخدمة <br />Nature of Item or Service Details</th>
              <th width="10%"> سعر الوحدة <br />Unit Price</th>
              <th width="10%"> الكمية<br />Qty</th>
              <th width="15%"> المجموع الفرعي<br />Subtotal</th>
              <th width="10%"> الخصم<br /> Discount</th>
              <th width="15%"> % الضريبة <br /> Tax %</th>
              <th width="15%"> المجموع<br />Total</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!orderData?.order.pinpad">
              <tr *ngFor="let item of  orderData?.item" [ngStyle]="{'background': item.isRefunded ? '#FFE7E6': 'unset' }">
                <td>
                  <label class="main-label"><span>{{item.name}}</span> <span>{{item.altName}}</span></label>
                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label"> <span>{{mod.name}}</span>    <span>{{mod.altName}}</span></label>
                    </ng-container>
                  </ng-container>
                  <div class="item-note p-1" *ngIf="item.note && item.note !== ''">
                    <p class="secondary-label mx-1 my-0">{{'Item Note:' | translate}} {{item.note}} </p>
                  </div>
                </td>
                <td align="right">
                  <label class="main-label">{{(item.priceWithoutVAT) | number: '1.2-2'}}</label>
                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label">{{(mod.priceWithoutVAT) | number: '1.2-2'}}</label>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <label class="main-label">{{item.quantity}}</label>
                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label">{{mod.quantity}}</label>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <label class="main-label">{{item.quantity*item.priceWithoutVAT | number: '1.2-2'}}</label>
                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label">{{mod.quantity*mod.priceWithoutVAT | number: '1.2-2'}}</label>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <label class="main-label">{{item.discount | number: '1.2-2'}}</label>
                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label">{{mod.discount | number: '1.2-2'}}</label>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <label class="main-label">{{(item.taxPercentage) | number: '1.2-2'}}</label>
                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label">{{(item.taxPercentage ) | number: '1.2-2'}}</label>
                    </ng-container>
                  </ng-container>
                </td>

                <td align="right">
                  <label class="main-label" [ngClass]="{'text-decoration-line-through': item.isComplimentary,'text-decoration-line-through secondary-label pe-3': checkDiscount(orderData.discounts,item.itemID)}">{{((item.priceWithQty) + sumModifiers(item.itemDetails)) | number: '1.2-2'}} {{brandCurrency | translate}}</label>
                  <!-- <label class="main-label" *ngIf="industry==2" [ngClass]="{'text-decoration-line-through': item.isComplimentary,'text-decoration-line-through secondary-label pe-3': checkDiscount(orderData.discounts,item.itemID)}">{{item.price*item.quantity | number: '1.2-2'}} SAR</label>-->
                  <ng-container *ngIf="checkDiscount(orderData.discounts,item.itemID)">
                    <label class="main-label">{{sumDiscounts(orderData?.discounts,item.itemID) | number: '1.2-2'}} {{brandCurrency | translate}}</label>
                  </ng-container>

                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label">+ {{(mod.price) | number: '1.2-2'}} {{brandCurrency | translate}}</label>
                    </ng-container>
                  </ng-container>
                  <br *ngIf="item.isComplimentary" />
                  <img *ngIf="item.isComplimentary" src="../../../assets/images/icons/v2/complementary_item_badge.svg" />
                  <br />
                  <ng-container *ngFor="let discount of orderData?.discounts">
                    <ngb-highlight *ngIf="discount.levels.toLowerCase() === 'items' && discount.itemID === item.itemID" class="btn btn-sm btn-active m-0" [dir]="selctedLang == 'en'?'ltr':'rtl'" result="{{discount.name }} ({{discount.percentage | number: '1.0-2'}}%)">
                    </ngb-highlight>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
        <label class="secondary-label mb-1" *ngIf="orderData?.order.comments && orderData?.order.comments !== ''"> {{'Order notes' | translate}}:</label>
        <p class="secondary-label"> {{orderData?.order.comments}}</p>
      </div>
      <div class="col-5 col-sm-5 col-md-5 col-lg-5">
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <label class="secondary-label mb-1"><span>{{'Subtotal (Excluding VAT)'}}</span>   </label>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <p class="secondary-label mb-1 w-100 float-end">
              <label class="float-end">
                {{orderData?.order.subTotal | number: '1.2-2'}}
              </label>
            </p>
          </div>
        </div>
        <div class="row" *ngIf="!orderData?.discounts && orderData?.order.discountAmount > 0">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <label class="" [dir]="selctedLang == 'en'?'ltr':'rtl'"> {{'Total Discount' | translate}}</label>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <label class="float-end"> {{orderData?.order.discountAmount | number: '1.2-2'}}</label>
          </div>
        </div>
        <div class="row" *ngIf="orderData?.discounts?.length>0">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <label class="" [dir]="selctedLang == 'en'?'ltr':'rtl'"> {{'Total Discount' | translate}}</label>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <label class="float-end">
              {{sumDiscount(orderData?.discounts) | number: '1.2-2'}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <label class="secondary-label mb-1"> <span>{{'Total Taxable Amount (Excluding VAT)' | translate}}</span> </label>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <p class="secondary-label mb-1 w-100 float-end">
              <label class="float-end">
                {{orderData?.order.subTotal-orderData?.order.discountAmount | number: '1.2-2'}}
              </label>
            </p>
          </div>
        </div>
        <div class="row">
          @if(orderData?.taxes?.length>0){
          @for(tax of orderData?.taxes; track tax.taxID){
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <label class="secondary-label mb-1"> <span>{{tax.name }}</span> <span><pre class="secondary-label" style=" display: inline; font-family: inherit" [dir]="selctedLang == 'en'?'ltr':'rtl'">({{tax.percentage}}%)</pre></span></label>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <p class="secondary-label mb-1 w-100 float-end">
              <label class="float-end">
                {{tax.amount | number: '1.2-2'}}
              </label>
            </p>
          </div>

          }
          }
          @else{
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <label class="secondary-label mb-1"> <span>{{'Total VAT' | translate}}</span> <span><pre class="secondary-label" style=" display: inline; font-family: inherit" [dir]="selctedLang == 'en'?'ltr':'rtl'">({{VAT}}%)</pre></span></label>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <p class="secondary-label mb-1 w-100 float-end">
              <label class="float-end" *ngIf="!orderData?.taxes">
                {{orderData?.order.taxAmount | number: '1.2-2'}}
              </label>
              <label class="float-end" *ngIf="orderData?.taxes && orderData?.taxes?.length>0">
                {{sumTax(orderData?.taxes) | number: '1.2-2'}}
              </label>
            </p>
          </div>
          }
        </div>
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <label class="main-label mb-1 font-weight-bold"><span>{{'Total Amount'}}</span>  </label>
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            <p class="main-label font-weight-bold w-100 float-end mb-1">
              <label class="float-end">
                {{orderData?.order.grandTotal | number: '1.2-2'}} {{brandCurrency | translate}}
              </label>
            </p>
            <div class="discount-badge float-end">
              <span class="btn btn-sm btn-active m-0" *ngIf="(!orderData?.discounts && orderData?.order.discountAmount > 0) || (orderData?.discounts?.length>0)">
                {{'Discounted' | translate }}
              </span>
            </div>
          </div>
        </div>
        <!--<div class="row" *ngIf="orderData?.order.refundedAmount > 0">
          <div class="row order-refund">
            <div class="col-10 col-sm-10 col-md-10 col-lg-10 ps-0">
              <label class="main-label font-weight-bold">{{'Refunded Amount'}} {{'المبلغ المرتجع'}}</label>
            </div>
            <div class="col-2 col-sm-2 col-md-2 col-lg-2">
              <p class="main-label font-weight-bold w-100 float-end">
                <label class="float-end">
                  {{orderData?.order.refundedAmount | number: '1.2-2'}}
                </label>
              </p>
            </div>
          </div>

        </div>-->

      </div>
    </div>
    <div> Created by {{userName }}, at {{date | date}} {{date  | date:'shortTime'}}</div>
  </div>
</div>
