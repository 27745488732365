<form [formGroup]="locationGroupForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header ">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{ 'Location Groups'  | translate}}
    </h4>

    <button [disabled]="loading" style="float: right; " class="btn btn-primary cutom-button custom-create-btn ms-2" *hasPermission="['Setup','Locations Group', (SelectedGroup) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>   {{'Save' | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Table Title">{{'Group Name' | translate }}</label>
              <input type="text" class="form-control" id="name" placeholder="" formControlName="name">
              <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                <div *ngIf="f.name.errors.required">
                  {{'Name is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Locations' | translate }}</label>
              <ng-select #select [items]="LocationsList" bindLabel="name" [closeOnSelect]="true" bindValue="locationID" [multiple]="true" placeholder="{{'Location' | translate }}" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedLocationIds" formControlName="locationIds">

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>
