<main class="views container sales-summary-report ">


  <div class="row mt-2 custom-action-header">
    <div class="col-0 col-sm-0 col-md-2 col-lg-3 col-xl-5 col-xxl-6">
    </div>
    <div class="col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 col-xxl-6">
      <div class="row align-items-center flex-xxl-row-reverse flex-xl-row-reverse flex-lg-row-reverse flex-md-row-reverse flex-sm-row-reverse">

        <div class="col-auto col-sm-auto col-md-auto col-lg-auto col-xl-auto col-xxl-auto">
          <div class="p-0" *hasPermission="['Analytics','Summary','Summary Tab', 'Export']">
            <button class="profile export-btn btn-lg" (click)="openPDF()" [disabled]="loading">
              <span *ngIf="isGeneratingPDF" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
              <span>{{'Export' | translate }} </span> &nbsp;<img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
            </button>
          </div>
        </div>
        <div class="col-auto col-sm-auto col-md-auto col-lg-auto col-xl-auto col-xxl-auto">
          <div class="p-0" *hasPermission="['Analytics','Summary','Summary Tab', 'Filter']">
            <div ngbDropdown>
              <a ngbDropdownToggle class="form-control text-center selected-location" style=" text-decoration: auto; color: #000; width: fit-content !important">
                <span>{{selectedLocationName | translate}}</span>
                <img class="down-aero" src="assets/images/down-aero.svg">
              </a>
              <div ngbDropdownMenu class="dropdown-menu dropaero-menu">
                <a class="dropdown-item" [hidden]="selectedLocationName == 'All Locations' " (click)="setLocation(null)">{{'All Locations' | translate}} </a>
                <a class="dropdown-item" *ngFor="let location of LocationsList" [hidden]="location.name == selectedLocationName"
                   (click)="setLocation(location)">{{location.name}} </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-auto col-md-auto col-lg-auto col-xl-auto col-xxl-auto">
          <div class="p-0" *hasPermission="['Analytics','Summary','Summary Tab', 'Filter']">
            <ngbd-datepicker-range (OnHit)="loadSalesSummary()"></ngbd-datepicker-range>
          </div>
        </div>

      </div>
    </div>
  </div>

    <div class="page-body">
      <section>
        <div class="row stats-card">
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
            <div class="summary-sales-box fixheight relative">
              <div class="content sales-summary" style="height: 485px;overflow: scroll;" [ngClass]="{'lazy': (salesLoading)}">
                <div class="tabtitle">
                  {{'Total Sales' | translate }} &nbsp;({{salesSummary?.orderCount}}&nbsp;{{'Orders' | translate }})
                </div>
                <div>
                  <span class="total-sales w-75 align-middle">
                    {{
salesSummary?.totalSales | number:'1.2-2'
                    }}
                  </span>
                  <span class="badge grey-badge">{{brandCurrency | translate}}</span>
                </div>
                <hr />
                <div class="accordion pb-2" id="accordionPanelsStayOpen" *ngIf="salesSummary?.holdOrders !=0 || salesSummary?.voidOrders !=0">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Unfinished" aria-expanded="false" aria-controls="Unfinished">
                        {{'Unfinished orders' | translate}}
                        &nbsp; <img src="../../../assets/images/Info.svg" class="image-input m-0 start-0" placement="top" [ngbTooltip]="htmlContent" />
                      </button>
                      <ng-template #htmlContent>
                        <span class="text-start">{{'Unfinished Orders' | translate}}</span>
                        <br />
                        <span class="text-start">{{'Pending, Hold and Void orders are not added to the calculations' | translate}}</span>
                      </ng-template>
                    </h2>
                    <div id="Unfinished" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                      <div class="accordion-body">
                        <ul>
                          <li class="py-2">
                            <div class="d-flex justify-content-between">
                              <div class="title-left font-blue">{{'Hold Orders' | translate}}</div>
                              <div class="title-right font-blue">({{salesSummary?.holdOrders}}) {{salesSummary?.holdAmount | number:'1.2-2'}}</div>
                            </div>
                          </li>
                          <li class="py-2">
                            <div class="d-flex justify-content-between">
                              <div class="title-left font-red">{{'Void' | translate}}</div>
                              <div class="title-right font-red">({{salesSummary?.voidOrders}}) {{salesSummary?.voidAmount | number:'1.2-2'}}</div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion" id="accordionPanelsStayClose">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayClose-headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#paymentType" aria-expanded="false" aria-controls="paymentType">
                        {{'Payment types' | translate}}
                      </button>
                    </h2>
                    <div id="paymentType" class="accordion-collapse collapse" aria-labelledby="panelsStayClose-headingOne">
                      <div class="accordion-body">
                        <ul class="extra-padding-min">
                          <li class="py-2">
                            <div class="d-flex justify-content-between">
                              <div class="title-left">{{'Card Payments' | translate}}</div>
                              <div class="title-right">({{salesSummary?.cardPaymentsCount}}) {{salesSummary?.cardPayments | number:'1.2-2'}}</div>
                            </div>
                          </li>
                          <li class="py-2">
                            <div class="d-flex justify-content-between">
                              <div class="title-left">{{'Cash Payments' | translate}}</div>
                              <div class="title-right">({{salesSummary?.cashPaymentsCount}}) {{salesSummary?.cashPayments | number:'1.2-2'}}</div>
                            </div>
                          </li>
                          <li class="py-2">
                            <div class="d-flex justify-content-between">
                              <div class="title-left">{{'Others' | translate}}</div>
                              <div class="title-right">({{salesSummary?.otherPaymentCount}}) {{salesSummary?.otherPayments | number:'1.2-2'}}</div>
                            </div>
                          </li>
                          <li class="py-2">
                            <div class="d-flex justify-content-between">
                              <div class="title-left">{{'Wallet' | translate}}</div>
                              <div class="title-right">({{salesSummary?.walletPaymentsCount}}) {{salesSummary?.walletPayments | number:'1.2-2'}}</div>
                            </div>
                          </li>
                          <li class="py-2">
                            <div class="d-flex justify-content-between">
                              <div class="title-left">{{'Aggregator' | translate}}</div>
                              <div class="title-right">({{salesSummary?.aggregatorOrders}}) {{salesSummary?.aggregatorAmount | number:'1.2-2'}}</div>
                            </div>
                          </li>
                          <li class="py-2">
                            <div class="d-flex justify-content-between">
                              <div class="title-left">{{'Bank Transfer' | translate}}</div>
                              <div class="title-right">({{salesSummary?.bankTransferOrders}}) {{salesSummary?.bankTransferAmount | number:'1.2-2'}}</div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="extra-padding-min">
                  <li>
                    <div class="d-flex justify-content-between">
                      <div class="title-left font-red">- {{'Discounts' | translate}}</div>
                      <div class="title-right font-red">({{salesSummary?.discountedOrders}}) {{salesSummary?.discountedAmount | number:'1.2-2'}}</div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex justify-content-between">
                      <div class="title-left font-red">- {{'Refunds' | translate}}</div>
                      <div class="title-right font-red">({{salesSummary?.refundedOrders}}) {{salesSummary?.refundedAmount | number:'1.2-2'}}</div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex justify-content-between">
                      <div class="title-left font-red">- {{'Vouchers' | translate}}</div>
                      <div class="title-right font-red">({{salesSummary?.voucherOrders}}) {{salesSummary?.voucherAmount | number:'1.2-2'}}</div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex justify-content-between">
                      <div class="title-left font-red">- {{'Complimentary' | translate}}</div>
                      <div class="title-right font-red">({{salesSummary?.complimentaryOrders}}) {{salesSummary?.complimentaryAmount | number:'1.2-2'}}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="footer bg-grey">
                <div class="d-flex row justify-content-between">
                  <div class="title-left col-7">{{'Gross Sales' | translate}}</div>
                  <div class="title-right col-5">{{salesSummary?.grandTotal | number:'1.2-2'}}</div>
                </div>
                <div class="d-flex row justify-content-between">
                  <div class="title-left col-6">{{'Sales Tax' | translate}}</div>
                  <div class="col-6 title-right">
                    <img src="../../../assets/images/Info.svg" placement="top" [ngbTooltip]="taxSummary" />
                    <span>
                      {{salesSummary?.taxes | number:'1.2-2'}}
                    </span>
                    <ng-template #taxSummary>
                      <ng-container *ngFor="let tax of taxes">
                        <label>
                          {{selctedLang=='en'?tax.name:tax.arabicName}} = {{tax?.amount| number:'1.2-2'}}
                        </label>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>
                <div class="d-flex row justify-content-between pb-2">
                  <div class="title-left col-6 bold">{{'Net Sales' | translate}}</div>
                  <div class="title-right col-6 bold">{{salesSummary?.netSales | number:'1.2-2'}} {{brandCurrency | translate}}</div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
            <div class="summary-sales-box fixheight relative">
              <div class="content sales-summary " [ngClass]="{'lazy': (inventoryLoading)}">
                <div class="tabtitle">
                  {{'Inventory Alerts' | translate }}
                </div>
                <ul class="extra-padding-min inventoryalerts" style="height: 530px; overflow: scroll;">
                  <li *ngFor="let item of inventoryAlerts">
                    <div class="d-flex justify-content-between">
                      <div class="title-left">
                        <div class="row">
                          <div class="col-4">
                            <div class="image-container">
                              <!--<img [src]="item.imageURL">-->
                              <img class="img-responsive" [src]="item.imageURL" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                            </div>
                          </div>
                          <div class="col-8">
                            <div>{{item.name}}</div>
                            <div class="subtitle">{{item.locationName}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="title-right">
                        {{item.percentage}}%
                        <circle-progress [percent]="item.percentage"
                                         [radius]="12"
                                         [space]="-2"
                                         [outerStrokeWidth]="3"
                                         [innerStrokeWidth]="2"
                                         [outerStrokeColor]="
                                         item.percentage>
                          75?'#00A36A':
                          item.percentage>50?'#F79E1B':
                          '#D31E1E'
                          "

                          [innerStrokeColor]="'#CDD7E2'"
                          [animation]="true"
                          [animationDuration]="300"
                          [showInnerStroke]=true
                          [showTitle]=false
                          [showSubtitle]=false
                          [showUnits]=false
                          [showBackground]=false
                          [clockwise]=false
                          [startFromZero]=false
                          [outerStrokeGradient]=false
                          outerStrokeLinecap=square>
                        </circle-progress>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="footer" [hidden]="true">
                <div class="d-flex justify-content-center">
                  <div class="title-left btn-more">{{'See More' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
            <div class="summary-sales-box fixheight">
              <div class="content sales-summary" [ngClass]="{'lazy': (cardTypeLoading)}">
                <div class="tabtitle">
                  {{'Card Types' | translate }}
                </div>
                <ul class="extra-padding">
                  <li *ngFor="let item of cardTypes">
                    <div class="d-flex justify-content-between">
                      <div class="title-left">
                        <!--<img  src="../assets/images/Mada_ic.svg" loading="lazy" height="20" width="60" alt="">-->
                        <div class="payment-type">
                          <img *ngIf="item.name === 'Mada'" src="../assets/images/Mada_ic.svg"
                               loading="lazy" height="20" width="60" alt="">
                          <img *ngIf="item.name === 'Visa'" src="../assets/images/Visa_ic.svg"
                               loading="lazy" height="14" width="46" alt="">
                          <img *ngIf="item.name === 'MasterCard'" src="../assets/images/Mastercard_ic.svg"
                               loading="lazy" height="24" width="31" alt="">
                          <img *ngIf="item.name === 'AmericanExpress'" src="../assets/images/Amex_ic.svg"
                               loading="lazy" height="18" width="50" alt="">
                          <img *ngIf="item.name === 'StcPay'" src="../assets/images/STCPay_ic.svg"
                               loading="lazy" height="16" width="54" alt="">
                        </div>
                        <div class="payment-type" *ngIf="item.name === 'Aggregator' || item.name === 'Other Card' || item.name === 'Wallet' || item.name === 'Cash' || item.name === 'Multi'">
                          {{item.name | translate}}
                        </div>
                      </div>
                      <div class="title-right">{{item.percentage}} % ({{item.paidAmount}})</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row stats-card">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="summary-sales-box fixheight relative">
              <div class="content sales-summary extra-padding" [ngClass]="{'lazy': (loading)}">
                <div class="tabtitle">
                  {{'Refunds' | translate }}
                </div>
                <ul class="extra-padding" style="height: 530px; overflow: scroll;">
                  <li *ngFor="let item of refundReason">
                    <div class="d-flex justify-content-between">
                      <div class="title-left">
                        {{item.reason | translate}}
                      </div>
                      <div class="title-right">{{item.percentage}}%({{item.refundAmount}})</div>
                    </div>
                  </li>
                  <li *ngIf="refundReason?.length == 0">
                    <div class="p-lg-5">
                      <div class="blank-order">
                        <div class="img-fluid ">

                          <svg width="100" height="93" viewBox="0 0 100 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="46.2715" cy="46.2715" r="46.2715" fill="#BAD9B8" />
                            <rect x="22.3826" y="25.0698" width="72.6665" height="49.9072" rx="5" fill="white" stroke="black" stroke-width="2" />
                            <rect x="72.7714" y="25.8013" width="0.519072" height="48.5332" fill="#D4D8DD" />
                            <rect x="27.0922" y="39.6421" width="10.3814" height="10.3814" rx="2" fill="#D4D8DD" />
                            <rect x="40.0691" y="39.6421" width="10.3814" height="10.3814" rx="2" fill="#D4D8DD" />
                            <rect x="53.5651" y="39.6421" width="10.3814" height="10.3814" rx="2" fill="#D4D8DD" />
                            <rect x="75.8853" y="68.0166" width="16.64" height="4.67164" rx="2" fill="#425CC7" />
                            <circle r="4.39835" transform="matrix(-1 0 0 1 94.6017 25.8495)" fill="#A80011" stroke="black" stroke-width="2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1715 34.1996C32.0402 33.2929 32.3211 32.0322 32.2788 30.6121C32.2735 30.4334 32.2802 30.3328 32.1735 30.3132C32.0668 30.2936 29.7906 30.2937 29.6404 30.3132C29.4901 30.3327 29.5101 30.6489 29.5101 30.6489C29.5266 31.4083 29.4001 31.8876 29.0361 32.2319C28.6709 32.5773 28.1974 32.6967 27.4177 32.7256C27.0763 32.7382 27.0946 32.8767 27.0946 33.0534V35.1612C27.0946 35.3369 27.0429 35.4893 27.4128 35.4889L27.4331 35.4893C28.9669 35.473 30.3011 35.108 31.1715 34.1996Z" fill="#1F2532" />
                          </svg>
                        </div>

                        <strong class="mt-2">{{'No Orders yet' | translate }}</strong>
                        <p class="text-center max-height">{{' No transactions took place during the selected time frame.' | translate}} </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="footer" [hidden]="true">
                <div class="d-flex justify-content-center">
                  <div class="title-left btn-more">{{'See More' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="summary-sales-box fixheight relative">
              <div class="content sales-summary " [ngClass]="{'lazy': (loading)}">
                <div class="tabtitle">
                  {{'Most Sold Items' | translate }}
                </div>
                <ul class="extra-padding-min cardtypes" style="height: 530px; overflow: scroll;">
                  <li *ngFor="let item of mostSoldItems" class="section">
                    <div class="row align-items-center">
                      <div class="col-2">
                        <div class="image-container"><img [src]="item.itemImage"></div>
                      </div>
                      <div class="col-6 d-flex align-items-center">
                        <div class="scroll-text">
                          {{selctedLang=='en'?item.name:item.altName}}
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div>{{item.percentage}}%({{item.amount}})</div>
                      </div>
                    </div>
                    <!--<div class="title-left">
                    </div>
                    <div class="title-right">{{item.percentage}}%({{item.amount}})</div>
                  </div>-->
                  </li>

                  <li *ngIf="mostSoldItems?.length == 0">
                    <div class="p-lg-5">
                      <div class="blank-order">
                        c
                        <div class="img-fluid ">

                          <svg width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="46.2715" cy="46.2715" r="46.2715" fill="#F5EEA3" />
                            <path d="M38.2493 59.1598C39.0042 57.2526 40.8472 56 42.8983 56H49.3515C51.2756 56 53.0291 57.1042 53.8605 58.8394L60.5689 72.8394C62.1592 76.1583 59.7401 80 56.0598 80H37.3566C33.827 80 31.4085 76.4416 32.7076 73.1598L38.2493 59.1598Z" fill="#1F2532" />
                            <rect x="16" y="21" width="61" height="43" rx="5" fill="white" stroke="black" stroke-width="2" />
                            <rect x="21" y="35" width="9" height="9" rx="2" fill="#D4D8DD" />
                            <rect x="21" y="46" width="9" height="9" rx="2" fill="#D4D8DD" />
                            <rect x="32" y="36" width="23" height="3" rx="1" fill="#D4D8DD" />
                            <rect x="32" y="47" width="23" height="3" rx="1" fill="#D4D8DD" />
                            <rect x="32" y="40" width="8" height="3" rx="1" fill="#D4D8DD" />
                            <rect x="32" y="51" width="8" height="3" rx="1" fill="#D4D8DD" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.1715 30.1996C26.0402 29.2929 26.3211 28.0322 26.2788 26.6121C26.2735 26.4334 26.2802 26.3328 26.1735 26.3132C26.0668 26.2936 23.7906 26.2937 23.6404 26.3132C23.4901 26.3327 23.5101 26.6489 23.5101 26.6489C23.5266 27.4083 23.4001 27.8876 23.0361 28.2319C22.6709 28.5773 22.1974 28.6967 21.4177 28.7256C21.0763 28.7382 21.0946 28.8767 21.0946 29.0534V31.1612C21.0946 31.3369 21.0429 31.4893 21.4128 31.4889L21.4331 31.4893C22.9669 31.473 24.3011 31.108 25.1715 30.1996Z" fill="#1F2532" />
                          </svg>



                        </div>

                        <strong class="mt-2">{{'No Orders yet' | translate }}</strong>
                        <p class="text-center max-height">{{' No transactions took place during the selected time frame.' | translate}} </p>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
              <div class="footer" [hidden]="true">
                <div class="d-flex justify-content-center">
                  <div class="title-left btn-more">{{'See More' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="summary-sales-box fixheight relative">
              <div class="content sales-summary extra-padding" [ngClass]="{'lazy': (loading)}">
                <div class="tabtitle">
                  {{'Most Selling Order Takers' | translate }}
                </div>
                <ul class="extra-padding" style="height: 530px; overflow: scroll;">
                  <li *ngFor="let item of orderTakers">
                    <div class="d-flex justify-content-between">
                      <div class="title-left">
                        {{item.orderTaker}}
                      </div>
                      <div class="title-right">{{item.percentage}}%({{item.amount}})</div>
                    </div>
                  </li>

                  <li *ngIf="orderTakers?.length == 0">
                    <div class="p-lg-5">
                      <div class="blank-order">
                        <div class="img-fluid ">

                          <svg width="105" height="104" viewBox="0 0 105 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="46.2715" cy="46.2715" r="46.2715" fill="#EB9995" />
                            <path d="M31.3334 70.8333V67.6666C31.3334 65.1691 32.3255 62.774 34.0915 61.008C35.8574 59.242 38.2526 58.2499 40.75 58.2499H53.25C55.7475 58.2499 58.1427 59.242 59.9086 61.008C61.6746 62.774 62.6667 65.1691 62.6667 67.6666V70.8333H31.3334ZM47 52.0833C45.4898 52.0833 43.9944 51.7858 42.5992 51.2079C41.2039 50.6299 39.9362 49.7829 38.8683 48.715C37.8004 47.6471 36.9534 46.3794 36.3754 44.9841C35.7975 43.5889 35.5 42.0935 35.5 40.5833C35.5 39.0731 35.7975 37.5776 36.3754 36.1824C36.9534 34.7871 37.8004 33.5194 38.8683 32.4515C39.9362 31.3837 41.2039 30.5366 42.5992 29.9586C43.9944 29.3807 45.4898 29.0833 47 29.0833C50.05 29.0833 52.9751 30.2949 55.1318 32.4515C57.2884 34.6082 58.5 37.5333 58.5 40.5833C58.5 43.6332 57.2884 46.5583 55.1318 48.715C52.9751 50.8717 50.05 52.0833 47 52.0833Z" fill="white" stroke="black" stroke-width="2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.0793 41.901C48.948 40.9944 49.229 39.7336 49.1866 38.3135C49.1813 38.1348 49.188 38.0342 49.0813 38.0146C48.9746 37.995 46.6985 37.9951 46.5482 38.0146C46.398 38.0341 46.4179 38.3503 46.4179 38.3503C46.4344 39.1097 46.3079 39.589 45.944 39.9333C45.5787 40.2787 45.1053 40.3981 44.3255 40.427C43.9841 40.4396 44.0025 40.5782 44.0025 40.7548V42.8626C44.0025 43.0383 43.9508 43.1907 44.3206 43.1903L44.3409 43.1907C45.8747 43.1744 47.209 42.8094 48.0793 41.901Z" fill="#1F2532" />
                            <g filter="url(#filter0_d_2690_6235)">
                              <rect x="50" y="59" width="45" height="31" rx="4" fill="white" />
                            </g>
                            <rect x="55" y="64" width="9" height="9" rx="2" fill="#D4D8DD" />
                            <rect x="66" y="65" width="24" height="3" rx="1" fill="#D4D8DD" />
                            <rect x="66" y="69" width="9" height="3" rx="1" fill="#D4D8DD" />
                            <rect x="55" y="76" width="9" height="9" rx="2" fill="#D4D8DD" />
                            <rect x="66" y="77" width="24" height="3" rx="1" fill="#D4D8DD" />
                            <rect x="66" y="81" width="9" height="3" rx="1" fill="#D4D8DD" />
                            <defs>
                              <filter id="filter0_d_2690_6235" x="40" y="53" width="65" height="51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="5" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2690_6235" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2690_6235" result="shape" />
                              </filter>
                            </defs>
                          </svg>




                        </div>

                        <strong class="mt-2">{{'No Orders yet' | translate }}</strong>
                        <p class="text-center max-height">{{' No transactions took place during the selected time frame.' | translate}} </p>
                      </div>
                    </div>
                  </li>


                </ul>
              </div>
              <div class="footer" [hidden]="true">
                <div class="d-flex justify-content-center">
                  <div class="title-left btn-more">{{'See More' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row stats-card">
          <div class="col-lg-4 col-md-6 col-sm-12" [hidden]="true">
            <div class="summary-sales-box fixheight relative">
              <div class="content sales-summary ">
                <div class="tabtitle">
                  {{'Most Sold Combonations' | translate }}
                </div>
                <ul class="extra-padding-8 cardtypes">
                  <li>
                    <div class="d-flex justify-content-between">
                      <div class="title-left">
                        <div class="group-title">   Chocolate + Soup + Booaewf  </div>
                        <div class="group-images">
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102747439796385_e4224d61-d666-4c2f-9418-3986631ded17.png"></div>
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102746764791170_e997188e-0dc7-47c1-b070-315bd89e2713.png"></div>
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102728553404130_36f79b03-d5b8-404d-9055-42e4e633949c.png"></div>
                        </div>
                      </div>
                      <div class="title-right">17%(512)</div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex justify-content-between">
                      <div class="title-left">
                        <div class="group-title">   Chocolate + Soup + Booaewf  </div>
                        <div class="group-images">
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102747439796385_e4224d61-d666-4c2f-9418-3986631ded17.png"></div>
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102746764791170_e997188e-0dc7-47c1-b070-315bd89e2713.png"></div>
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102728553404130_36f79b03-d5b8-404d-9055-42e4e633949c.png"></div>
                        </div>
                      </div>
                      <div class="title-right">17%(512)</div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex justify-content-between">
                      <div class="title-left">
                        <div class="group-title">   Chocolate + Soup + Booaewf  </div>

                        <div class="group-images">
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102747439796385_e4224d61-d666-4c2f-9418-3986631ded17.png"></div>
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102746764791170_e997188e-0dc7-47c1-b070-315bd89e2713.png"></div>
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102728553404130_36f79b03-d5b8-404d-9055-42e4e633949c.png"></div>
                        </div>

                      </div>
                      <div class="title-right">17%(512)</div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex justify-content-between">
                      <div class="title-left">
                        <div class="group-title">   Chocolate + Soup + Booaewf  </div>

                        <div class="group-images">
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102747439796385_e4224d61-d666-4c2f-9418-3986631ded17.png"></div>
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102746764791170_e997188e-0dc7-47c1-b070-315bd89e2713.png"></div>
                          <div class="image-placeholder"><img style="width: 27px;" src="https://marnpossastorage.blob.core.windows.net/marnpos-v3-uat/1637102728553404130_36f79b03-d5b8-404d-9055-42e4e633949c.png"></div>
                        </div>
                      </div>
                      <div class="title-right">17%(512)</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="footer">
                <div class="d-flex justify-content-center">
                  <div class="title-left btn-more">{{'See More' | translate}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="summary-sales-box fixheight relative">
              <div class="content sales-summary extra-padding" [ngClass]="{'lazy': (loading)}">
                <div class="tabtitle">
                  {{'Order Types' | translate }}
                </div>
                <ul class="extra-padding" style="height: 530px; overflow: scroll;">
                  <li *ngFor="let item of orderTypes">
                    <div class="d-flex justify-content-between">
                      <div class="title-left">
                        {{item.orderType}}
                      </div>
                      <div class="title-right">{{item.percentage}}%({{item.amount}})</div>
                    </div>
                  </li>

                  <li *ngIf="orderTypes?.length == 0">
                    <div class="p-lg-5">
                      <div class="blank-order">
                        <div class="img-fluid ">

                          <svg width="105" height="104" viewBox="0 0 105 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="46.2715" cy="46.2715" r="46.2715" fill="#EB9995" />
                            <path d="M31.3334 70.8333V67.6666C31.3334 65.1691 32.3255 62.774 34.0915 61.008C35.8574 59.242 38.2526 58.2499 40.75 58.2499H53.25C55.7475 58.2499 58.1427 59.242 59.9086 61.008C61.6746 62.774 62.6667 65.1691 62.6667 67.6666V70.8333H31.3334ZM47 52.0833C45.4898 52.0833 43.9944 51.7858 42.5992 51.2079C41.2039 50.6299 39.9362 49.7829 38.8683 48.715C37.8004 47.6471 36.9534 46.3794 36.3754 44.9841C35.7975 43.5889 35.5 42.0935 35.5 40.5833C35.5 39.0731 35.7975 37.5776 36.3754 36.1824C36.9534 34.7871 37.8004 33.5194 38.8683 32.4515C39.9362 31.3837 41.2039 30.5366 42.5992 29.9586C43.9944 29.3807 45.4898 29.0833 47 29.0833C50.05 29.0833 52.9751 30.2949 55.1318 32.4515C57.2884 34.6082 58.5 37.5333 58.5 40.5833C58.5 43.6332 57.2884 46.5583 55.1318 48.715C52.9751 50.8717 50.05 52.0833 47 52.0833Z" fill="white" stroke="black" stroke-width="2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.0793 41.901C48.948 40.9944 49.229 39.7336 49.1866 38.3135C49.1813 38.1348 49.188 38.0342 49.0813 38.0146C48.9746 37.995 46.6985 37.9951 46.5482 38.0146C46.398 38.0341 46.4179 38.3503 46.4179 38.3503C46.4344 39.1097 46.3079 39.589 45.944 39.9333C45.5787 40.2787 45.1053 40.3981 44.3255 40.427C43.9841 40.4396 44.0025 40.5782 44.0025 40.7548V42.8626C44.0025 43.0383 43.9508 43.1907 44.3206 43.1903L44.3409 43.1907C45.8747 43.1744 47.209 42.8094 48.0793 41.901Z" fill="#1F2532" />
                            <g filter="url(#filter0_d_2690_6235)">
                              <rect x="50" y="59" width="45" height="31" rx="4" fill="white" />
                            </g>
                            <rect x="55" y="64" width="9" height="9" rx="2" fill="#D4D8DD" />
                            <rect x="66" y="65" width="24" height="3" rx="1" fill="#D4D8DD" />
                            <rect x="66" y="69" width="9" height="3" rx="1" fill="#D4D8DD" />
                            <rect x="55" y="76" width="9" height="9" rx="2" fill="#D4D8DD" />
                            <rect x="66" y="77" width="24" height="3" rx="1" fill="#D4D8DD" />
                            <rect x="66" y="81" width="9" height="3" rx="1" fill="#D4D8DD" />
                            <defs>
                              <filter id="filter0_d_2690_6235" x="40" y="53" width="65" height="51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="5" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2690_6235" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2690_6235" result="shape" />
                              </filter>
                            </defs>
                          </svg>

                        </div>
                        <strong class="mt-2">{{'No Orders yet' | translate }}</strong>
                        <p class="text-center max-height">{{' No transactions took place during the selected time frame.' | translate}} </p>
                      </div>
                    </div>
                  </li>


                </ul>
              </div>
              <div class="footer" [hidden]="true">
                <div class="d-flex justify-content-center">
                  <div class="title-left btn-more">{{'See More' | translate}}</div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </section>
    </div>
</main>
<app-sales-summary-pdf *ngIf="export === true" [selectedLocation]="selectedLocation" [startdate]="startdate" [enddate]="enddate" [selectedLocationName]="selectedLocationName" [salesSummaryData]="salesSummaryData" (html)="receive($event)"></app-sales-summary-pdf>
