import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { CategoryService } from 'src/app/_services/category.service';
import { ImageUploadV2Component } from '../../../image-upload-v2/image-upload-v2.component';
import { ImageuploadComponent } from '../../../imageupload/imageupload.component';

@Component({
  selector: 'app-addcategory',
  templateUrl: './addcategory.component.html',
  styleUrls: ['./addcategory.component.css']
})
export class AddcategoryComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  submitted = false;
  categoryForm: UntypedFormGroup;
  loading = false;
  loadingCategory = false;
  ButtonText = "Save";

  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private categoryService: CategoryService

  ) {
    this.createForm();
    this.loadDisplayOrder();
  }

  ngOnInit() {

    this.setSelectedCategory();
  }
  get f() { return this.categoryForm.controls; }

  private createForm() {

    this.categoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9]*(?:[ \-,_+.;:’×“”’’!—…&()%*|/™é][a-zA-Z0-9]*)*[a-zA-Z0-9]$/)]],
      alternateName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Zء-ي0-9][a-zA-Zء-ي0-9]*(?:[ ؛،\-_+.:×“”’’!—…&()%*|/™é–,;][a-zA-Zء-ي0-9]*)*[a-zA-Zء-ي0-9]$/)]],
      description: [''],
      statusID: [true, Validators.required],
      displayOrder: [0, [Validators.required, Validators.min(1), Validators.max(32000)]],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      categoryID: 0,
      image: [''],
      rowVersion: 0,
      sortByAlpha: [true]
    });
  }

  private editForm(category) {

    this.f.name.setValue(category.name);
    this.f.alternateName.setValue(category.alternateName);
    this.f.image.setValue(category.image);
    this.imgComp.imagepath = category.image;
    this.f.description.setValue(category.description);
    this.f.sortByAlpha.setValue(category.sortByAlpha);
    this.f.displayOrder.setValue(category.displayOrder);
    this.f.rowVersion.setValue(category.rowVersion);
    this.f.statusID.setValue(category.statusID === 1 ? true : false);
    this.f.categoryID.setValue(category.categoryID);

  }

  setSelectedCategory() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingCategory = true;
      this.f.categoryID.setValue(this.SelectedID);
      this.categoryService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingCategory = false;
      });
    }
  }


  onSubmit() {
    this.f.name.setValue(this.f.name.value.trim());
    this.f.alternateName.setValue(this.f.alternateName.value.trim());

    this.categoryForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.categoryForm.invalid) { return; }
    this.loading = true;
    //this.f.image.setValue(this.imgComp.imagepath);
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    
    this.imgComp.upload((this.f.image.value && this.f.image.value !== '' ? this.f.image.value : '')).then(() => {
      this.f.image.setValue(this.imgComp.imagepath);
      if (parseInt(this.f.categoryID.value) === 0) {

        //Insert category
        console.log(JSON.stringify(this.categoryForm.value));
        this.categoryService.insert(this.categoryForm.value).subscribe(data => {
          this.alertService.success("Category has been created");
          this.loading = false;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
        });

      } else {
        //Update category
        this.categoryService.update(this.categoryForm.value).subscribe(data => {
          this.alertService.success("Category has been updated");
          this.loading = false;
          this.SelectedID = 0;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
          this.activeModal.dismiss();
        });
      }
    });
  }

  private loadDisplayOrder() {

    this.categoryService.getDisplayOrder(this.f.brandID.value).subscribe((res: any) => {
      this.f.displayOrder.setValue(res);
    });
  }
  close() {
    this.activeModal.dismiss();
  }

}
