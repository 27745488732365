import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { LocationGroupService } from '../../../_services/location-group.service';
import { CustomPaymentTypeService } from '../../../_services/custom-payment-type.service';
import { LocationService } from '../../../_services/location.service';
import { ImageuploadComponent } from '../../../imageupload/imageupload.component';
import { kMaxLength } from 'buffer';

@Component({
  selector: 'app-create-custom-payment-type',
  templateUrl: './create-custom-payment-type.component.html',
  styleUrl: './create-custom-payment-type.component.css'
})
export class CreateCustomPaymentTypeComponent {
  @Input() SelectedID = null;
  title = 'Create Custom Payment Type';
  submitted = false;
  paymentTypeForm: UntypedFormGroup;
  loading = false;
  selectedLocations = [];
  Locations: any;
  selectedLang = 'en';

  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private location: LocationService,
    private service: CustomPaymentTypeService
  ) {
    this.createForm();
    
    this.loadLocations();
  }
  private createForm() {
    //this.Location = DataService.Name;
    this.paymentTypeForm = this.formBuilder.group({
      id: [0],
      paymentID: [0],
      name: ['', [Validators.minLength(3), Validators.maxLength(30),Validators.required]],
      alternateName: ['', [Validators.minLength(3), Validators.maxLength(30), Validators.required]],
      logo: [''],
      description: [''],
      accountingCode: [''],
      locations: [''],
      hasCustomPricing: [false],
      showOnCashier: [true],
      rowVersion: [0],
      statusID: [true, Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID)
    });
  }


  private editForm(item) {

    this.f.id.setValue(item.id);
    this.f.paymentID.setValue(item.paymentID);
    this.f.name.setValue(item.name);
    this.f.alternateName.setValue(item.alternateName);
    this.f.logo.setValue(item.logo);
    this.imgComp.imagepath = item.logo;
    this.f.description.setValue(item.description);
    this.f.accountingCode.setValue(item.accountingCode);
    this.f.hasCustomPricing.setValue(item.hasCustomPricing);
    this.f.rowVersion.setValue(item.rowVersion);
    this.f.showOnCashier.setValue(item.showOnCashier);
    this.f.statusID.setValue(item.statusID === 1 ? true : false);

    if (item.locations) {
      this.selectedLocations = item.locations.split(',').map(Number);
    }
  }
  get f() { return this.paymentTypeForm.controls; }
  ngOnInit() {
    this.setSelectedItem();
  }


  private loadLocations() {
    this.location.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
    });
  }

  setSelectedItem() {
    if (this.SelectedID) {

      this.loading = true;
      this.f.id.setValue(this.SelectedID);
      this.service.getById(this.SelectedID, parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loading = false;
      });
    }
  }


  public onSelectAll(control) {
    if (control == 'location') {
      const _selectedLocations = this.Locations.map(item => item.locationID);
      //this.f.values.patchValue(_selectedLocations);
      this.selectedLocations = _selectedLocations;
    }

  }
  onSubmit() {
    this.paymentTypeForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    if (this.paymentTypeForm.invalid) {

      return;
    }
    if (this.selectedLocations)
      this.f.locations.setValue(this.selectedLocations.toString());


    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.loading = true;


    this.imgComp.upload((this.f.logo.value && this.f.logo.value !== '' ? this.f.logo.value : '')).then(() => {
      this.f.logo.setValue(this.imgComp.imagepath);
    this.service.save(this.paymentTypeForm.value).subscribe(data => {
      this.alertService.success("Coupon has been created");
      this.loading = false;
      this.activeModal.dismiss();

    }, error => {

      if (this.f.statusID.value === 1)
        this.f.statusID.setValue(true);
      else
        this.f.statusID.setValue(false);



      this.alertService.error(error);
      this.loading = false;
    });

    });

  }
}

