<main class="dashboard-ui views container">
  <div class="page ">
    <div class="row page-header">
      <div class="col-xl-8 col-md-12 col-sm-12 title-space-mb">
        <div class="title">
          <span class="start">{{'Dashboard' | translate }}  </span>

          <div class="end fitler d-flex justify-content-end align-items-center" *hasPermission="['Dashboard', 'Filters']">
            <div class="dashboard-location-selection" [hidden]="selectedLocationName != 'All Locations'">
              <app-switchlocation></app-switchlocation>
            </div>
            <app-date-range-picker-v2 [MinMonths]="12" (OnHit)="setDate()"></app-date-range-picker-v2>
          </div>
        </div>

      </div>
    </div>
    <div class="page-body dashboard">
      <section>
        <div class="row mb-4">
          <div class="col-12 col-xl-8 col-sm-12">
            <div class="row">
              <div class="col-12">
                <div class="tab-chart" *hasPermission="['Dashboard', 'Tabs']">
                  <nav ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="active" (click)="loadGraph()">
                    <!--Orders-->
                    <ng-container [ngbNavItem]="1">
                      <a ngbNavLink>
                        <div class="tab-stats-title">{{'Orders' | translate }}</div>
                        <div class="dashboard-stats-text">{{dashboardData?.orderReport.orderCount}}</div>
                      </a>
                      <ng-template ngbNavContent *ngIf="active === 1">
                        <app-dailystats [selectedBrand]="selectedBrand" [selectedLocation]="selectedLocation"
                                        [selectedDate]="selectedDate" [GraphData]="dashboardTodayOrderSummary" [Currency]="brandCurrency"
                                        [Loading]="(loadingDasboardData || loadingTodayOrder)?true :false"></app-dailystats>
                      </ng-template>
                    </ng-container>
                    <!-- Sales -->
                    <ng-container [ngbNavItem]="2">
                      <a ngbNavLink>
                        <div class="tab-stats-title">{{'Sales' | translate }} ({{brandCurrency | translate }})</div>
                        <div class="dashboard-stats-text">{{dashboardData?.orderReport.totalSales}}</div>
                      </a>
                      <ng-template ngbNavContent *ngIf="active === 2">
                        <app-dailystats [selectedBrand]="selectedBrand" [selectedLocation]="selectedLocation"
                                        [selectedDate]="selectedDate" [GraphData]="dashboardNetSalesSummary" [Currency]="brandCurrency"
                                        [Loading]="(loadingDasboardData || loadingTodaySummary)?true :false"></app-dailystats>
                      </ng-template>
                    </ng-container>
                    <!-- Customer -->
                    <ng-container [ngbNavItem]="3">
                      <a ngbNavLink>
                        <div class="tab-stats-title">{{'Net Sales' | translate }}</div>
                        <div class="dashboard-stats-text">{{dashboardData?.orderReport.netSales}}</div>
                      </a>
                      <ng-template ngbNavContent *ngIf="active === 3">
                        <app-dailystats [selectedBrand]="selectedBrand" [selectedLocation]="selectedLocation"
                                        [selectedDate]="selectedDate" [GraphData]="dashboardNetSalesSummary" [Currency]="brandCurrency"
                                        [Loading]="(loadingDasboardData || loadingNetSalesSummary)?true :false"></app-dailystats>
                      </ng-template>
                    </ng-container>
                    <ng-container [ngbNavItem]="4">
                      <a ngbNavLink>
                        <div class="tab-stats-title">{{'Customers' | translate }}</div>
                        <div class="dashboard-stats-text">{{dashboardData?.orderReport.customerCount}}</div>
                      </a>
                      <ng-template ngbNavContent *ngIf="active === 4">
                        <app-dailystats [selectedBrand]="selectedBrand" [selectedLocation]="selectedLocation"
                                        [selectedDate]="selectedDate" [GraphData]="dashboardTodayCustomerSummary" [Currency]="brandCurrency"
                                        [Loading]="(loadingDasboardData || loadingTodayCustomer)?true :false"></app-dailystats>
                      </ng-template>
                    </ng-container>
                  </nav>
                  <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
              </div>
            </div>
            <div class="row stats-card" *hasPermission="['Dashboard', 'Cards']">
              <div class="col-12 col-lg-4 col-md-6 col-6">
                <div class="dash_fact_box">
                  <div class="tabtitle">
                    {{'Refunds' | translate }}
                    <span><img src="../../assets/images/icons/v2/Frame.svg" alt="" class="d-card-icon"></span>
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.orderReport.refunds !=0">
                    <strong>{{dashboardData?.orderReport.refunds}}</strong>
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.orderReport.refunds ==0">
                    <!-- <strong>{{dashboardData?.orderReport.refunds}}</strong> -->
                    <span class="card-empty-results"> {{'No Refunds' | translate }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-6">
                <div class="dash_fact_box">
                  <div class="tabtitle">
                    {{'Discounts' | translate }}
                    <span><img src="../../assets/images/icons/v2/Frame.svg" alt="" class="d-card-icon"></span>
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.orderReport.discountCount !=0">
                    ({{dashboardData?.orderReport.discountCount}})
                    <strong>{{dashboardData?.orderReport.discountSales | number:'1.2-2'}}</strong>
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.orderReport.discountCount ==0">
                    <span class="card-empty-results">{{'No Discounts' | translate }}</span>
                    <!-- ({{dashboardData?.orderReport.discountCount}})
                    <strong>{{dashboardData?.orderReport.discountSales | number:'1.2-2'}}</strong> -->
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-6">
                <div class="dash_fact_box">
                  <div class="tabtitle">
                    {{'Coupons' | translate }}
                    <!-- <span><img src="../../assets/images/icons/v2/Frame.svg" alt="" class="d-card-icon"></span> -->
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.couponReport.couponCount !=0">
                    ({{dashboardData?.couponReport.couponCount}})
                    <strong>{{dashboardData?.couponReport.couponSales | number:'1.2-2'}}</strong>
                  </div>

                  <div class="dashboard_stats_text" *ngIf="dashboardData?.couponReport.couponCount ==0">
                    <!-- ({{dashboardData?.couponReport.couponCount}})
                    <strong>{{dashboardData?.couponReport.couponSales | number:'1.2-2'}}</strong> -->
                    <span class="card-empty-results">{{'No Coupons' | translate }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-6">
                <div class="dash_fact_box">
                  <div class="tabtitle">
                    {{'Inventory Purchases' | translate }}
                    <span><img src="../../assets/images/icons/v2/Frame.svg" alt="" class="d-card-icon"></span>
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.inventoryReport.poAmount === 0">
                    <span class="card-empty-results">{{'No Purchases' | translate }}</span>
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.inventoryReport.poAmount !==0">
                    ({{dashboardData?.inventoryReport.poCount}})
                    <strong>{{dashboardData?.inventoryReport.poAmount | number:'1.2-2'}}</strong>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-6">
                <div class="dash_fact_box">
                  <div class="tabtitle">
                    {{'Inventory Alerts' | translate }}
                    <span class="card-empty-results"><img src="../../assets/images/icons/v2/Frame.svg" alt="" class="d-card-icon"></span>
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.inventoryReport.inventoryAlerts !=0">
                    <strong> {{dashboardData?.inventoryReport.inventoryAlerts}}</strong>
                  </div>

                  <div class="dashboard_stats_text" *ngIf="dashboardData?.inventoryReport.inventoryAlerts ==0">
                    <!-- <strong> {{dashboardData?.inventoryReport.inventoryAlerts}}</strong> -->
                    <span class="card-empty-results">{{'No Alerts' | translate }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-6">
                <div class="dash_fact_box">
                  <div class="tabtitle">
                    {{'Inventory Transfers' | translate }}
                    <span><img src="../../assets/images/icons/v2/Frame.svg" alt="" class="d-card-icon"></span>
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.inventoryReport.transferCount !=0">
                    <strong> {{dashboardData?.inventoryReport.transferCount}}</strong>
                  </div>
                  <div class="dashboard_stats_text" *ngIf="dashboardData?.inventoryReport.transferCount==0">
                    <!-- <strong> {{dashboardData?.inventoryReport.transferCount}}</strong> -->
                    <span class="card-empty-results">{{'No Transfers' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row stats-card chart-bx">
              <div class="col-lg-4 col-md-6 col-sm-12" *hasPermission="['Dashboard', 'Order Type']">
                <div class="dash_fact_box fixheight">
                  <div class="tabtitle">
                    <strong>{{'Order Types' | translate }}</strong>
                  </div>
                  <div class="chart-container">
                    <stats-order-type [report]="dashboardData?.orderTypeReport"></stats-order-type>
                  </div>
                  <div class="view-more text-center" [hidden]=true>
                    <a>{{'View more' | translate }}</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12" *hasPermission="['Dashboard', 'Payment Types']">
                <div class="dash_fact_box fixheight">
                  <div class="tabtitle">
                    <strong>{{'Payment Types' | translate }}</strong>
                  </div>
                  <div class="chart-container">
                    <stats-payment-type [report]="dashboardData?.paymentTypeReport"></stats-payment-type>
                  </div>
                  <div class="view-more text-center" [hidden]=true>
                    <label> <a>{{'View more' | translate }}</a></label>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12" *hasPermission="['Dashboard', 'Partner Orders']">
                <div class="dash_fact_box fixheight">
                  <div class="tabtitle">
                    <strong>{{'Partner Orders' | translate }}</strong>
                  </div>
                  <div *ngIf="getTotalPartnerOrder() !=0" class="chart-container">
                    <div class="dashboard_stats_text mb-3"> <strong> {{ getTotalPartnerOrder() }}</strong></div>
                    <div class="d-flex partner-card me-1" *ngFor="let partner of dashboardData?.partnerReport">
                      <div class="partner-logo">
                        <img *ngIf="partner.logo !== ''" [src]="partner.logo"
                             loading="lazy" alt="">
                      </div>
                      <div class="partner-wrapper left">
                        <div class="tc-title d-flex justify-content-between">
                          <div> {{partner.name | translate}}</div>
                          <div>{{partner.percentage | number:'1.2-2'}}%</div>
                        </div>
                        <div class="tc-subtitle inner">({{partner.orderCount}}) {{partner.amount | number:'1.2-2'}} {{brandCurrency | translate }}</div>
                        <hr />
                      </div>

                    </div>
                  </div>
                  <div *ngIf="getTotalPartnerOrder() ==0">
                    <div class="blank-partner">
                      <img src="../../assets/images/icons/No-partners.svg" alt="" class="img-fluid ">
                      <strong>{{'No Partner Orders yet' | translate }}</strong>
                      <p class="text-center">{{'Receive Online Orders by Installing an Integration from Marketplace' | translate}}</p>
                    </div>
                  </div>

                  <div class="view-more text-center" [hidden]=true>
                    <a>{{'View more' | translate }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-sm-12 hide-mobile live-orders-table" *hasPermission="['Dashboard', 'Live Orders']">
            <div class="heading-live-transaction">
              <h4 class="heading">{{'Recent Orders' | translate }}</h4>
              <a class="small-button" [routerLink]="[ '/'+industryURLName+'/orders']">{{'View' | translate }}</a>
            </div>
            <div class="transaction-body" *ngIf="transactions?.length > 0">
              <div class="transaction-card" *ngFor="let transaction of transactions">
                <div class="tc-wrapper">
                  <div class="left">
                    <div class="circle order-badge" [ngStyle]="transaction?.status.trim().toLowerCase() === 'paid'?{'background-color':'#328F60'} : {'background-color': '#ff6f6f'}"></div>
                    <div class="tc-title">{{transaction?.timeStamp | translate}}&nbsp;{{transaction?.timeText | translate}}</div>
                    &nbsp;
                    <small>
                      <ngb-highlight *ngIf="transaction?.status.trim().toLowerCase() !== 'paid'" class="btn btn-sm btn-inactive" [result]="transaction?.status | translate">
                      </ngb-highlight>
                    </small>
                  </div>
                  <div class="right">
                    <div class="payment-type">
                      <img *ngIf="transaction?.payMode.trim().toLowerCase() === 'mada'" src="../assets/images/Mada_ic.svg"
                           loading="lazy" width="40" alt="">
                      <img *ngIf="transaction?.payMode.trim().toLowerCase() === 'visa'" src="../assets/images/Visa_ic.svg"
                           loading="lazy" width="33" alt="">
                      <img *ngIf="transaction?.payMode.trim().toLowerCase() === 'mastercard'" src="../assets/images/Mastercard_ic.svg"
                           loading="lazy" width="33" height="24" alt="">
                      <img *ngIf="transaction?.payMode.trim().toLowerCase() === 'americanexpress'" src="../assets/images/Amex_ic.svg"
                           loading="lazy" width="42" alt="">
                      <img *ngIf="transaction?.payMode.trim().toLowerCase() === 'stcpay'" src="../assets/images/STCPay_ic.svg"
                           loading="lazy" width="37" alt="">
                    </div>
                  </div>
                </div>
                <div class="tc-wrapper">
                  <div class="left tc-subtitle">
                    <div class="tc-subtitle">{{transaction?.locationName}}</div>
                  </div>
                  <div class="right">
                    <div class="tc-subtitle">{{transaction?.grandTotal}} {{brandCurrency | translate}} ({{transaction?.payMode | translate}})</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="transaction-body" *ngIf="transactions?.length == 0">
              <div class="blank-order">
                <img src="../../assets/images/icons/empty-orders.svg" alt="" class="img-fluid ">
                <strong>{{'No Orders yet' | translate }}</strong>
                <p class="text-center">{{'No transactions took place' | translate}} <br> {{'during the selected time' | translate}} <br> {{'frame.' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row dashboard-location" *hasPermission="['Dashboard', 'Locations List']">
          <div class="col-12">
            <app-location-sales-sumamry-analytics [fromDate]="selectedDate.fromDate" [toDate]="selectedDate.toDate" [locationID]="selectedLocation" [Currency]="brandCurrency"
                                                  [Period]="dashboardTodayOrderSummary?.period" [WaitTillFetched]="WaitTillFetched"></app-location-sales-sumamry-analytics>

          </div>
        </div>
      </section>
    </div>
  </div>
</main>
