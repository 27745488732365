import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { LocationService } from 'src/app/_services/location.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ItemService } from 'src/app/_services/item.service';
import { UnitService } from 'src/app/_services/unit.service';
import { BarcodeService } from 'src/app/_services/barcode.service';
import { CategoryService } from 'src/app/_services/category.service';
import { Item, ItemRecipe, Supply } from 'src/app/_models/Item';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as myGlobals from '../../../_helpers/globals';
import { ImageUploadV2Component } from '../../../image-upload-v2/image-upload-v2.component';
import { ImageuploadComponent } from '../../../imageupload/imageupload.component';

@Component({
  selector: 'app-additem',
  templateUrl: './additem.component.html',
  styleUrls: ['./additem.component.css']
})
export class AdditemComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedItemName = '';
  RecipesList = [];
  Units = [];
  Groups = [];
  // public Supply = new Supply();
  public _supply: Supply[] = [];
  public arrRecipes: ItemRecipe[] = [];
  GroupModifiersList = [];
  InventoryTypes: Array<any> = [
    { inventoryType: "Item", name: 'Unit' },
    { inventoryType: "Recipe", name: 'Recipe' }
  ];
  PriceTypes: Array<any>;

  LocationsList = [];
  SuppliersList = [];
  SubCateogries: [];
  AllergenList = [];
  isRetailItem: boolean = true;
  submitted = false;
  itemForm: UntypedFormGroup;
  selectedSubcatName = 'SubCategory';
  loading = false;
  loadingItem = false;
  ButtonText = "Save"; selectedCityIds
  selectedSubCategoriesIds: string[];
  selectedLocationIds: string[];
  selectedSupplierIds: string[];
  selectedAllergenIds: string[];
  selectedgroupModifierIds: string[];
  selctedLang = 'en';
  brandCurrency: string = '';
  UnitName = '';
  industry = 0;
  manualSKUChangeByUser = false;
  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private location: LocationService,
    private alertService: AlertService,
    private itemService: ItemService,
    private unitService: UnitService,
    private barcodeService: BarcodeService,
    private categoryService: CategoryService) {
    this.industry = this.localStorageService.getSelectedUser().User.Industry;
    if (this.industry === 1) {
      this.PriceTypes = [
        { id: 1, name: 'Fixed Price' },
        { id: 2, name: 'Open Price' }
      ];
    }
    else {
      this.PriceTypes = [
        { id: 1, name: 'Fixed Price' },
        { id: 2, name: 'Open Price' },
        { id: 3, name: 'Unit Price' }
      ];
    }
    this.createForm();
  }

  ngOnInit() {
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.f.brandID.setValue(parseInt(this.localStorageService.getSelectedBrand().BrandID));
    this.brandCurrency = myGlobals.brandCurrency(this.localStorageService);
    this.loadGroupModifiers();
    this.loadLocations();
    this.loadUnits();
    this.loadSubcategories();
    this.loadSuppliers();
    this.loadGroups();
    this.loadRecipes();
    this.setSelectedItem();
    this.generateSKU();
    this.loadAllergens();
  }
  get f() { return this.itemForm.controls; }
  get enableSKU() {
    if (this.f.trackInventory.value && (!this.f.sku.value || this.f.sku.value === '' || this.manualSKUChangeByUser)) {
      return true;
    }
    else {
      return false;
    }
  }
  private createForm() {
    this.itemForm = this.formBuilder.group({
      Location: new UntypedFormControl({ value: "", disabled: true }, Validators.required),
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9]*(?:[ \-,_+.;:’×“”’’!—…&()%*|/™é][a-zA-Z0-9]*)*[a-zA-Z0-9]$/)]],
      nameOnReceipt: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Zء-ي0-9][a-zA-Zء-ي0-9]*(?:[ ؛،\-_+.:×“”’’!—…&()%*|/™é–,;][a-zA-Zء-ي0-9]*)*[a-zA-Zء-ي0-9]$/)]],
      description: [''],
      alternateDescription: [''],
      statusID: [true, Validators.required],
      subCatID: ['', Validators.required],
      unitID: [4, Validators.required],
      displayOrder: [0, [Validators.required, Validators.min(1), Validators.max(32000)]],
      price: [0, Validators.required],
      barcode: [''],
      brandID: 0,
      fixedPrice: [false],
      itemID: 0,
      itemImage: [''],
      rowVersion: 0,
      locations: [],
      modifiers: [],
      suppliers: [],
      inventoryTypeID: [1],
      supplierID: [0],
      cost: [0],
      sku: [''],
      type: ['Item'],
      trackInventory: [false],
      itemRecipes: [],
      Supply: [],
      groupID: [0],
      recipeID: [0],
      supplyID: [0],
      priceType: [1, Validators.required],
      nutriInfo: [false],
      calories: [null, [Validators.min(1), Validators.max(10000)]],
      allergenIDs: [null]
    });
  }
  private editForm(item) {

    this.f.name.setValue(item.name);
    this.f.nameOnReceipt.setValue(item.nameOnReceipt);
    this.f.price.setValue(item.price);
    this.f.subCatID.setValue(item.subCatID);

    if (item.modifiers !== "") {
      const stringToConvertmodifiers = item.modifiers;
      this.selectedgroupModifierIds = stringToConvertmodifiers.split(',').map(Number);
      this.f.modifiers.setValue(item.modifiers);
    }
    if (item.locations !== "") {
      const stringToConvert = item.locations;
      this.selectedLocationIds = stringToConvert.split(',').map(Number);
      this.f.locations.setValue(item.locations);
    }


    this.f.trackInventory.setValue(item.trackInventory);
    if (this.f.trackInventory.value === true) {
      if (item.supply === null) {
        this.isRetailItem = false;
        this.f.type.setValue("Recipe");
      }

      if (item.supply !== null) {
        if (item.supply.suppliers !== "") {
          const stringToConvert = item.supply.suppliers;
          this.selectedSupplierIds = stringToConvert.split(',').map(Number);
          this.f.suppliers.setValue(item.supply.suppliers);
        }
        this.f.cost.setValue(item.supply.cost);
        this.f.sku.setValue(item.supply.sku);
        this.f.groupID.setValue(item.supply.groupID);
        this.f.supplyID.setValue(item.supply.supplyID);
        this._supply.push({
          suppliers: this.selectedSupplierIds == undefined ? "" : this.selectedSupplierIds.toString(),
          cost: this.f.cost.value,
          groupID: this.f.groupID.value,
          sku: this.f.sku.value,
          type: this.f.type.value,
          supplyID: this.f.supplyID === undefined ? null : this.f.supplyID.value
        });
        this.f.Supply.setValue(this._supply[0]);
      }
      if (item.itemRecipes !== null) {
        this.arrRecipes = item.itemRecipes
        this.f.itemRecipes.setValue(this.arrRecipes);
      }
    }

    this.f.itemImage.setValue(item.itemImage);
    this.imgComp.imagepath = item.itemImage;
    this.f.description.setValue(item.description);
    this.f.alternateDescription.setValue(item.alternateDescription);
    this.f.barcode.setValue(item.barcode);
    this.f.displayOrder.setValue(item.displayOrder);
    this.f.rowVersion.setValue(item.rowVersion);
    this.f.statusID.setValue(item.statusID === 1 ? true : false);
    this.f.itemID.setValue(item.itemID);
    this.f.priceType.setValue(item.priceType);
    this.f.unitID.setValue(parseInt(item.unitID));

    if (item.allergenIDs) {
      const stringToConvert = item.allergenIDs;
      this.selectedAllergenIds = stringToConvert.split(',').map(Number);
      this.f.allergenIDs.setValue(item.allergenIDs);

    }
    this.f.calories.setValue(item.calories);
    this.f.nutriInfo.setValue(item.nutriInfo);
  
  }

  copyToClipboard(key) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(key).then(() => {
      }, (error) => {
      });
    } else {
      this.alertService.error("Browser do not support Clipboard API");
    }
  }
  copy() {
    this.copyToClipboard(this.f.itemID.value);
    this.alertService.success("Item ID copied");
  }

  loadLocations() {
    this.location.getLocations();

    //this.location.locations$.subscribe((res: any) => {
    //  this.LocationsList = res;
    //});


    this.location.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.LocationsList = res;
    });
  }
  loadSuppliers() {
    this.itemService.getSuppliers(this.f.brandID.value).subscribe((res: any) => {

      this.SuppliersList = res;
    });
  }
  loadAllergens() {
    this.itemService.getAllAllergens().subscribe((res: any) => {

      this.AllergenList = res;
    });
  }

  generateSKU() {
    if (this.enableSKU) {
      this.loading = true;
      this.manualSKUChangeByUser = false;
      this.itemService.generateSKU(this.f.brandID.value).subscribe((res: any) => {
        this.f.sku.setValue(res.sku);
        this.loading = false;
      });
    }
  }
  loadGroupModifiers() {
    this.itemService.getGroupModifiers(this.f.brandID.value).subscribe((res: any) => {
      this.GroupModifiersList = res;
    });
  }
  loadGroups() {
    this.itemService.getGroup(this.f.brandID.value).subscribe((res: any) => {
      this.Groups = res;
      if (this.Groups.length > 0)
        this.f.groupID.setValue(this.Groups[0].groupID);
    });
  }
  loadBarcode() {

    this.barcodeService.getBarcode(this.f.brandID.value).subscribe((res: any) => {
      this.f.barcode.setValue(res);
    });
  }

  loadDisplayOrder(subCategoryID) {

    this.itemService.getDisplayOrder(this.f.brandID.value, subCategoryID.value).subscribe((res: any) => {
      this.f.displayOrder.setValue(res);
    });
  }
  selectedSubCategory(subCategoryID, obj) {
    this.selectedSubcatName = obj.name;
    this.loadDisplayOrder(subCategoryID);
  }
  loadRecipes() {
    this.itemService.getRecipes(this.f.brandID.value).subscribe((res: any) => {
      this.RecipesList = res;
    });
  }
  private loadSubcategories() {
    this.categoryService.getSubCategories(this.f.brandID.value).subscribe((res: any) => {
      this.SubCateogries = res;
    });
  }

  private loadUnits() {

    this.unitService.getUnits().subscribe((res: any) => {
      this.Units = res;
      this.UnitName = this.Units.find(e => e.unitID === this.f.unitID.value).unit;
      //this.f.unitID.setValue(this.Units[0].unitID);
    });
  }

  setSelectedItem() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingItem = true;
      this.f.itemID.setValue(this.SelectedID);
      this.itemService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingItem = false;
      });
    }
  }

  onSubmit() {
    this.f.name.setValue(this.f.name.value.trim());
    this.f.nameOnReceipt.setValue(this.f.nameOnReceipt.value.trim());

    this.itemForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.itemForm.invalid) { return; }
    this.loading = true;

    if (this.selectedLocationIds == undefined || this.selectedLocationIds == null) {
      this.alertService.error("Please select locations");
      this.loading = false;
      return;
    }
    if (this.selectedLocationIds.length === 0) {
      this.alertService.error("Please select locations");
      this.loading = false;
      return;
    }

    if (this.f.trackInventory.value === true) {

      if (this.f.type.value === "Item") {
        this._supply = [];
        this._supply.push({
          suppliers: this.selectedSupplierIds === undefined ? "" : this.selectedSupplierIds.toString(),
          cost: this.f.cost.value,
          groupID: this.f.groupID.value,
          sku: this.f.sku.value,
          type: this.f.type.value,
          supplyID: this.f.supplyID === undefined ? null : this.f.supplyID.value
        });
        this.f.Supply.setValue(this._supply[0]);
        this.f.itemRecipes.setValue(null);
      }
      if (this.f.type.value === "Recipe") {
        this.f.Supply.setValue(null);
        this.f.itemRecipes.setValue(this.arrRecipes);
      }
    }
    else {
      this.f.Supply.setValue(null);
      this.f.itemRecipes.setValue(null);
    }

    this.f.barcode.setValue(this.f.barcode.value.toString());
    this.f.locations.setValue(this.selectedLocationIds === undefined ? "" : this.selectedLocationIds.toString());
    this.f.allergenIDs.setValue(this.selectedAllergenIds ? this.selectedAllergenIds.toString() : null);
    // this.f.suppliers.setValue(this.selectedSupplierIds == undefined ? "" : this.selectedSupplierIds.toString());
    this.f.modifiers.setValue(this.selectedgroupModifierIds === undefined ? "" : this.selectedgroupModifierIds.toString());
    //this.f.itemImage.setValue(this.imgComp.imagepath);
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);

    this.imgComp.upload((this.f.itemImage.value && this.f.itemImage.value !== '' ? this.f.itemImage.value : '')).then(() => {
      this.f.itemImage.setValue(this.imgComp.imagepath);
      if (parseInt(this.f.itemID.value) === 0) {
        //Insert item

        this.itemService.insert(this.itemForm.value).subscribe(data => {
          this.alertService.success("Item has been created");
          this.loading = false;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
        });

      } else {
        //Update item


        this.itemService.update(this.itemForm.value).subscribe(data => {
          this.alertService.success("Item has been Updated");
          this.loading = false;
          this.SelectedID = 0;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
          this.activeModal.dismiss();
        });
      }
    });

  }
  ChangeInventoryType() {
    this.isRetailItem = this.f.type.value === "Item" ? true : false;
  }
  close() {
    this.activeModal.dismiss();
  }

  deleteRow(obj) {
    const index = this.arrRecipes.indexOf(obj);
    this.arrRecipes.splice(index, 1);
  }

  addRow(obj) {
    if (obj.recipeID !== undefined && obj.recipeID !== null) {
      this.arrRecipes.push({
        itemRecipeID: 0,
        recipeID: obj.recipeID,
        itemID: obj.itemID,
        name: obj.name,
        quantity: obj.quantity === undefined ? 0 : obj.quantity
      })
    }
  }

  UpdateQty(newItem) {
    let updateItem = this.arrRecipes.find(this.findIndexToUpdate, newItem.recipeID);
    let index = this.arrRecipes.indexOf(updateItem);
    this.arrRecipes[index] = newItem;
  }

  findIndexToUpdate(newItem) {
    return newItem.quantity === this;
  }

  ngOnDestroy() {
    this.location.clear();
  }

  onSelectAll(control) {
    if (control === 'locations') {
      const _selectedLocations = this.LocationsList.map(item => item.locationID);
      this.selectedLocationIds = _selectedLocations;
      this.f.locations.setValue(_selectedLocations);
    }

  }
  closeSelect(select: NgSelectComponent) { select.close(); }


  setGroupBy() {


    return (this.selctedLang == 'en' ? 'parent' : 'parentAlternative');
  }
  changePriceType() {
    if (this.f.priceType.value === 3) {
      this.f.type.setValue("Item");
      this.ChangeInventoryType();
    }
  }
  getUnitName() {
    this.UnitName = this.Units.find(e => e.unitID === this.f.unitID.value).unit;
  }
}
