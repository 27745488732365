import { Component } from '@angular/core';

@Component({
  selector: 'app-location-tab',
  templateUrl: './location-tab.component.html',
  styleUrl: './location-tab.component.css'
})
export class LocationTabComponent {
  active = "locationlist";
}
