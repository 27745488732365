<div id="htmlData">
  <div class="container order-pdf p-4">
    <div class="row text-center">
      <label class="main-label" *ngIf="ZATCAConfig">
        {{invoiceLabel}}
      </label>
    </div>
    <div class="row">
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <img class="img-responsive img-rounded brand-logo" [src]="SelectedBrand?.BrandLogo" alt="Brand picture" height="50" width="50" />
      </div>
      <!-- <div class="col-4 col-sm-4 col-md-4 col-lg-4 align-self-end p-0">
      <span class="btn btn-sm grey w-auto order-type-badge float-end" *ngIf="orderData?.order?.orderType && orderData?.order?.orderType !== '' && (orderData?.order?.orderType.toLowerCase() === 'pick up' || orderData?.order?.orderType.toLowerCase() === 'delivery')"> {{orderData?.order?.orderType}}</span>
    </div> -->
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-end">

        <h5 class="font-weight-bold float-end pe-1" *ngIf="!invoiceLabelText && orderData?.order.orderStatus !== 'Refund'">
          {{'عرض سعر'}}
          {{"Quotation" }}
        </h5>
        <h5 class="font-weight-bold float-end pe-1" *ngIf="invoiceLabelText && orderData?.order.orderStatus !== 'Refund'">
          {{'فاتورة'}}
          {{"INVOICE" }}
        </h5>
        <h5 class="font-weight-bold float-end pe-1" *ngIf="invoiceLabelText && orderData?.order.orderStatus === 'Refund'">
          {{'إشعار دائن'}}
          {{"CREDIT NOTE" }}
        </h5>
        <!--<span class="btn btn-sm grey w-auto order-type-badge float-end" *ngIf="orderData?.order?.orderType && orderData?.order?.orderType !== '' && (orderData?.order?.orderType.toLowerCase() === 'pick up' || orderData?.order?.orderType.toLowerCase() === 'delivery')"> {{orderData?.order?.orderType}}</span>-->
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <label class="main-label">
          {{SelectedBrand.BrandName}}
        </label>
        <br />
        <label class="secondary-label">
          {{orderData?.brandDetails.address}}
        </label>
        <br />
        <label class="secondary-label" *ngIf="orderData?.brandDetails && orderData?.brandDetails.vatid && orderData?.brandDetails.vatid !== ''">
          {{"VAT" | translate}}: {{orderData?.brandDetails.vatid}}
        </label>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <ng-container *ngIf="orderData?.order.deviceTransactionNo && orderData?.order.deviceTransactionNo !== ''">
          <label class="secondary-label float-end">
            {{'Trans No.' | translate}} {{orderData?.order.deviceTransactionNo}}
          </label>
          <br />
        </ng-container>
        <ng-container *ngIf="orderData?.order.partnerLogo && orderData?.order.partnerLogo !== ''">
          <div class="order-partner-logo float-end p-1 m-0">
            <img [src]="orderData?.order?.partnerLogo" loading="lazy" alt="" style="width:30px !important; height:30px !important">
          </div>
          <br />
          <br />
          <label class="secondary-label float-end">
            {{orderData?.order.partnerName}} - {{(orderData?.order.orderRefrenceID) ?orderData?.order.orderRefrenceID:''}}
          </label>
          <br />
        </ng-container>
        <span class="float-end">
          <ngb-highlight *ngIf="orderData?.order.orderState === 'Waiting'" class="btn btn-sm bullet-button waiting p-0" result="{{orderData?.order.orderState | translate }}">
            <!-- btn-inactive -->
          </ngb-highlight>
          <ngb-highlight *ngIf="orderData?.order.orderState === 'In Kitchen'" class="btn btn-sm bullet-button kitchen p-0" result="{{'In Progress' | translate  }}">
          </ngb-highlight>
          <ngb-highlight *ngIf="orderData?.order.orderState === 'Ready'" class="btn btn-sm bullet-button ready p-0" result="{{orderData?.order.orderState | translate }}">
            <!-- btn-badge-blue -->
          </ngb-highlight>
          <ngb-highlight *ngIf="orderData?.order.orderState === 'Served'" class="btn btn-sm bullet-button served p-0" result="{{orderData?.order.orderState | translate }}">
            <!-- btn-active -->
          </ngb-highlight>
          <ngb-highlight *ngIf="orderData?.order.orderState === 'On the Way'" class="btn btn-sm bullet-button on-way p-0" result="{{orderData?.order.orderState | translate }}">
            <!-- btn-active -->
          </ngb-highlight>
          <ngb-highlight *ngIf="orderData?.order.orderState === 'Delivered'" class="btn btn-sm  bullet-button delivered p-0" result="{{orderData?.order.orderState | translate }}">
            <!-- btn-active -->
          </ngb-highlight>

        </span>
      </div>
      <hr />
    </div>

    <div class="row">
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <label class="main-label" *ngIf="orderData?.customer && orderData?.customer.customerName && orderData?.customer.customerName !== ''">
          {{'Customer' | translate}}: {{orderData?.customer.customerName}}
        </label>
        <br />
        <label class="secondary-label" *ngIf="orderData?.customer && orderData?.customer.mobile && orderData?.customer.mobile !== ''">
          {{'Number' | translate}}: {{orderData?.customer.mobile}}
        </label>
        <br />
        <label class="secondary-label" *ngIf="orderData?.customer && orderData?.customer.address && orderData?.customer.address !== ''">
          {{'Address' | translate}}: {{orderData?.customer.address}}
        </label>
        <br />
        <ng-container *ngIf="orderData?.customer && orderData?.customer.carDetails && orderData?.customer.carDetails !== ''">
          <label class="secondary-label">
            {{'Car Details' | translate}}:  {{orderData?.customer.carDetails}}
          </label>
          <br />
        </ng-container>
        <ng-container *ngIf="orderData?.customer && orderData?.customer.vatID && orderData?.customer.vatID !== ''">
          <label class="secondary-label">
            {{'VAT ID' | translate}}: {{orderData?.customer.vatID }}
          </label>
        </ng-container>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6" *ngIf="!showInvoicePeriod">
        <div class="d-flex align-items-end flex-column bd-highlight">
          <div class="bd-highlight">
            <label class="main-label">
              {{'رقم الفاتورة'}}
              {{'Invoice Number'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label">
              {{(orderData?.order.orderStatus === 'Refund')? orderData?.order.refundOrderNo: orderData?.order.orderNo}}
            </label>
          </div>

          <div class="bd-highlight" *ngIf="orderData?.order.orderStatus === 'Refund'">
            <label class="main-label">
              {{'رقم الفاتورة الأصلية'}}
              {{'Original Invoice Number'}}
            </label>
          </div>
          <div class="bd-highlight" *ngIf="orderData?.order.orderStatus === 'Refund'">
            <label class="secondary-label">
              {{orderData?.order.orderNo}}
            </label>
          </div>

          <div class="bd-highlight">
            <label class="main-label">
              {{'منشئ الطلب'}}
              {{'Created By'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label">
              {{orderData?.order.orderTaker}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="main-label float-end">
              {{'تاريخ الطلب'}}
              {{'Order Date'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label float-end">
              {{orderData?.order.createdDate  | date}} {{orderData?.order.createdDate  | date:'shortTime'}}
            </label>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6" *ngIf="showInvoicePeriod">
        <div class="d-flex align-items-end flex-column bd-highlight">
          <div class="bd-highlight">
            <label class="main-label">
              {{'تاريخ الفاتورة'}}
              {{'Invoice Date'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label">
              {{orderData?.order.createdDate  | date}} {{orderData?.order.createdDate  | date:'shortTime'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="main-label">
              {{'فترة الفاتورة'}}
              {{'Invoice Period'}}
            </label>
          </div>
          <div class="bd-highlight">
            <label class="secondary-label">
              {{orderData?.order.orderPeriod.startDate  | date}} {{this.orderData?.order.orderPeriod.startDate.split('T')[0] + ' '+orderData?.order.orderPeriod.startTime  | date:'shortTime'}} - {{orderData?.order.orderPeriod.endDate  | date}} {{this.orderData?.order.orderPeriod.endDate.split('T')[0] +' '+ orderData?.order.orderPeriod.endTime  | date:'shortTime'}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <table class="table">
          <thead>
            <tr class="table-header">
              <th width="40%" class="text-start">{{"Item name" }} <br> {{'اسم المنتج'}}</th>
              <th width="15%">{{"Price"}}<br> {{'سعر الوحدة'}}</th>
              <th width="15%">{{"Qty"}} <br>{{'الكمية'}}</th>
              <th width="30%">{{"Amount"}} <br>{{'الإجمالي'}}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="orderData?.order.pinpad">
              <tr>
                <td colspan="4" align="center" class="text-center">
                  <label class="main-label p-2"><span>Pinpad Order</span>  <span>طلب بدفع مباشر</span> </label>
                  <br />
                  <span class="btn btn-sm grey w-auto order-pinpad-badge"> {{'Pinpad' | translate}} </span>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="!orderData?.order.pinpad">
              <tr *ngFor="let item of  orderData?.item" [ngStyle]="{'background': item.isRefunded ? '#FFE7E6': 'unset' }">
                <td class="text-start">
                  <label class="main-label"><span>{{item.name}}</span> <span>{{item.altName}}</span></label>
                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label"> <span>{{mod.name}}</span>    <span>{{mod.altName}}</span></label>
                    </ng-container>
                  </ng-container>
                  <div class="item-note p-1" *ngIf="item.note && item.note !== ''">
                    <p class="secondary-label mx-1 my-0">{{'Item Note:' | translate}} {{item.note}} </p>
                  </div>
                </td>
                <td align="right">
                  <label class="main-label">{{(item.price) | number: '1.2-2'}}</label>
                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label">{{(mod.price) | number: '1.2-2'}}</label>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <label class="main-label">{{item.quantity}}</label>
                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label">{{mod.quantity}}</label>
                    </ng-container>
                  </ng-container>
                </td>
                <td align="right">
                  <label class="main-label" [ngClass]="{'text-decoration-line-through': item.isComplimentary,'text-decoration-line-through secondary-label pe-3': checkDiscount(orderData.discounts,item.itemID)}">{{((item.priceWithQty) + sumModifiers(item.itemDetails)) | number: '1.2-2'}} {{brandCurrency | translate}}</label>
                  <!-- <label class="main-label" *ngIf="industry==2" [ngClass]="{'text-decoration-line-through': item.isComplimentary,'text-decoration-line-through secondary-label pe-3': checkDiscount(orderData.discounts,item.itemID)}">{{item.price*item.quantity | number: '1.2-2'}} SAR</label>-->
                  <ng-container *ngIf="checkDiscount(orderData.discounts,item.itemID)">
                    <label class="main-label">{{sumDiscounts(orderData?.discounts,item.itemID) | number: '1.2-2'}} {{brandCurrency | translate}}</label>
                  </ng-container>

                  <ng-container *ngIf="item.itemDetails">
                    <ng-container *ngFor="let mod of  item.itemDetails">
                      <br />
                      <label class="secondary-label">+ {{(mod.price) | number: '1.2-2'}} {{brandCurrency | translate}}</label>
                    </ng-container>
                  </ng-container>
                  <br *ngIf="item.isComplimentary" />
                  <img *ngIf="item.isComplimentary" src="../../../assets/images/icons/v2/complementary_item_badge.svg" />
                  <br />
                  <ng-container *ngFor="let discount of orderData?.discounts">
                    <ngb-highlight *ngIf="discount.levels.toLowerCase() === 'items' && discount.itemID === item.itemID" class="btn btn-sm btn-active m-0" [dir]="selctedLang == 'en'?'ltr':'rtl'" result="{{discount.name }} ({{discount.percentage | number: '1.0-2'}}%)">
                    </ngb-highlight>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <!--Discount section-->
    <div class="row pt-3">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
        <label class="secondary-label mb-1" *ngIf="orderData?.order.comments && orderData?.order.comments !== ''"> {{'Order notes' | translate}}:</label>
        <p class="secondary-label"> {{orderData?.order.comments}}</p>
      </div>
      <!-- <ng-container *ngIf="orderData?.order.discountAmount > 0">
      <div class="col-5 col-sm-5 col-md-5 col-lg-5">
        <div class="row discount-row">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9 ps-1">  <label class="" [dir]="selctedLang == 'en'?'ltr':'rtl'"> {{'Total Discount' | translate}} ({{((orderData?.order.discountAmount/(orderData?.order.subTotal)) * 100) | number: '1.0-2'}}%)</label> </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3 pe-1">  <label class="float-end"> {{orderData?.order.discountAmount | number: '1.2-2'}}</label> </div>
        </div>
      </div>
    </ng-container>
   <ng-container *ngIf="orderData?.discounts?.length>0">
  <div class="col-5 col-sm-5 col-md-5 col-lg-5 discount-row h-0">
    <ng-container *ngFor="let discount of orderData?.discounts">
      <ng-container *ngIf="discount.levels.toLowerCase() !== 'items'">
        <div class="row">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9 ps-1">  <label class="" [dir]="selctedLang == 'en'?'ltr':'rtl'"> {{(selctedLang == 'en')? discount.name :(discount.arabicName) ? discount.arabicName:discount.name | translate}} ({{discount.percentage | number: '1.0-2'}}%)</label> </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3 pe-1">  <label class="float-end"> {{discount.amount | number: '1.2-2'}}</label> </div>
        </div>
      </ng-container>
    </ng-container>

  </div>
    </ng-container>-->
    </div>
    <!--Discount section-->
    <div class="row">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
      </div>
      <div class="col-3 col-sm-3 col-md-3 col-lg-3">
        <label class="secondary-label mb-1"><span>{{'Subtotal'}}</span>  <span>{{'المجموع'}}</span> </label>
      </div>
      <div class="col-2 col-sm-2 col-md-2 col-lg-2">
        <p class="secondary-label mb-1 w-100 float-end">
          <label class="float-end">
            {{orderData?.order.subTotal | number: '1.2-2'}}
          </label>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
      </div>
      <div class="col-3 col-sm-3 col-md-3 col-lg-3">
        <label class="secondary-label mb-1"><span>{{'Total Discount' | translate}}</span>  <span>({{((orderData?.order.discountAmount/(orderData?.order.subTotal)) * 100) | number: '1.0-2'}}%)</span> </label>
      </div>
      <div class="col-2 col-sm-2 col-md-2 col-lg-2">
        <p class="secondary-label mb-1 w-100 float-end">
          <label class="float-end">
            {{orderData?.order.discountAmount | number: '1.2-2'}}
          </label>
        </p>
      </div>
    </div>

    <div class="row" *ngIf="!orderData?.taxes">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
      </div>
      <div class="col-3 col-sm-3 col-md-3 col-lg-3">
        <label class="secondary-label mb-1"> <span>{{'VAT' | translate}}</span> <span><pre class="secondary-label" style=" display: inline; font-family: inherit" [dir]="selctedLang == 'en'?'ltr':'rtl'">({{VAT}}%)</pre></span></label>
      </div>
      <div class="col-2 col-sm-2 col-md-2 col-lg-2">
        <p class="secondary-label mb-1 w-100 float-end">
          <label class="float-end">
            {{orderData?.order.taxAmount | number: '1.2-2'}}
          </label>
        </p>
      </div>
    </div>
    <div *ngIf="orderData?.taxes && orderData?.taxes?.length>0">
      <div class="row " *ngFor="let tax of orderData?.taxes">
        <div class="col-7 col-sm-7 col-md-7 col-lg-7">
        </div>
        <div class="col-3 col-sm-3 col-md-3 col-lg-3">
          <label class="secondary-label mb-1 " [dir]="selctedLang == 'en'?'ltr':'rtl'"> <span>{{ (selctedLang == 'en')? tax.name :(tax.arabicName) ?tax.arabicName:tax.name | translate}}</span> <span> <pre class="secondary-label" style=" display: inline; font-family:inherit">({{tax.percentage}}%)</pre></span></label>
        </div>
        <div class="col-2 col-sm-2 col-md-2 col-lg-2">
          <p class="secondary-label mb-1 w-100 float-end">
            <label class="float-end">
              {{tax.amount | number: '1.2-2'}}
            </label>
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
      </div>
      <!--<div class="col-3 col-sm-3 col-md-3 col-lg-3">
      <label class="secondary-label "> <span> Total</span> <span> الإجمالي</span> </label>
    </div>-->
      <div class="col-3 col-sm-3 col-md-3 col-lg-3">
        <label class="main-label mb-1 font-weight-bold"><span>{{'Total'}}</span>  <span>{{'الإجمالي'}}</span> </label>
      </div>

      <div class="col-2 col-sm-2 col-md-2 col-lg-2">
        <p class="main-label font-weight-bold w-100 float-end mb-1">
          <label class="float-end">
            {{orderData?.order.grandTotal | number: '1.2-2'}} {{brandCurrency | translate}}
          </label>
        </p>
        <div class="discount-badge float-end">
          <span class="btn btn-sm btn-active m-0" *ngIf="(!orderData?.discounts && orderData?.order.discountAmount > 0) || (orderData?.discounts?.length>0)">
            {{'Discounted' | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="orderData?.order.refundedAmount > 0">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
      </div>
      <div class="col-5 col-sm-5 col-md-5 col-lg-5">
        <div class="row order-refund">
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 ps-1">
            <label class="main-label font-weight-bold">{{'Refunded Amount'}} {{'المبلغ المرتجع'}}</label>
          </div>
          <div class="col-2 col-sm-2 col-md-2 col-lg-2 pe-1">
            <p class="main-label font-weight-bold w-100 float-end">
              <label class="float-end">
                {{orderData?.order.refundedAmount | number: '1.2-2'}}
              </label>
            </p>
          </div>
        </div>
      </div>

    </div>
    <div class="row pt-3">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
      </div>
      <div class="col-5 col-sm-5 col-md-5 col-lg-5">
        <div class="row">
          <hr />
          <ng-container *ngIf="orderData?.order.orderStatus !== 'Hold'  && orderData?.order.orderStatus !== 'Pending' && orderData?.order.orderStatus !== 'Void'">
            <ng-container *ngFor="let payment of  orderData?.payment">
              <div class="col-7 col-sm-7 col-md-7 col-lg-7">
                <div class="payment-type" *ngIf="payment.paymentMode !== 'Aggregator' && payment.paymentMode !== 'Other' && payment.paymentMode !== 'Wallet' && payment.paymentMode !== 'Cash' &&
                     payment.paymentMode !== 'Multi' && payment.paymentMode !== 'Card' && payment.paymentMode !== 'Coupon'">
                  <img *ngIf="payment.paymentMode === 'Mada'" src="../assets/images/Mada_ic.svg"
                       loading="lazy" width="40" alt="">
                  <img *ngIf="payment.paymentMode === 'Visa'" src="../assets/images/Visa_ic.svg"
                       loading="lazy" width="33" alt="">
                  <img *ngIf="payment.paymentMode === 'MasterCard'" src="../assets/images/Mastercard_ic.svg"
                       loading="lazy" width="33" height="24" alt="">
                  <img *ngIf="payment.paymentMode === 'AmericanExpress'" src="../assets/images/Amex_ic.svg"
                       loading="lazy" width="42" alt="">
                  <img *ngIf="payment.paymentMode === 'StcPay'" src="../assets/images/STCPay_ic.svg"
                       loading="lazy" width="37" alt="">
                  <br />
                </div>
                <label class="main-label" *ngIf="payment.paymentMode === 'Aggregator' || payment.paymentMode === 'Other' || payment.paymentMode === 'Wallet' ||
                       payment.paymentMode === 'Cash' || payment.paymentMode === 'Multi' || payment.paymentMode === 'Card' || payment.paymentMode === 'Coupon'">
                  {{payment.paymentMode}}
                </label>
              </div>
              <div class="col-5 col-sm-5 col-md-5 col-lg-5">
                <label class="secondary-label float-end"> {{payment.amount | number: '1.2-2'}}</label>
                <br />
              </div>
            </ng-container>
          </ng-container>

        </div>
        <div class="row pt-4 mx-0" *ngIf="orderData?.order.orderStatus === 'Hold' || orderData?.order.orderStatus === 'Pending' || orderData?.order.orderStatus === 'Void' || orderData?.order.orderStatus === 'Partial Refund'">
          <span class="border border-dark text-center border-2 align-middle">
            <label class="main-label font-weight-bold py-2">
              {{(orderData?.order.orderStatus === 'Partial Refund') ?'Partially Paid': 'Unpaid' | translate}}
              <span class="main-label font-weight-bold void" *ngIf="orderData?.order.orderStatus === 'Void'" [dir]="selctedLang == 'en'?'ltr':'rtl'">({{'Void' | translate}}) </span>
            </label>
          </span>
        </div>
      </div>
    </div>
    <div class="row d-flex" *ngIf="ZATCAConfig">
      <div class="col-6 col-sm-6 col-md-6 col-lg-6" *ngIf="!Phase2QRCode">
        <qrcode [qrdata]="QRCode" [width]="120" [errorCorrectionLevel]="'L'" [allowEmptyString]="true"></qrcode>
        <p class="main-label m-0"> {{"Scan for E - Receipt" }}</p>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6" *ngIf="Phase2QRCode">
        <qrcode [qrdata]="Phase2QRCode" [width]="200" [errorCorrectionLevel]="'L'" [allowEmptyString]="true"></qrcode>
        <p class="main-label m-0 ms-4"> {{"Scan for E - Receipt" }}</p>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-end">
        <div class="d-flex align-items-end flex-column bd-highlight">
          <div class="bd-highlight">
            <p class="main-label font-weight-bold m-0">
              {{"Powered by" | translate}}
            </p>
          </div>
          <div class="bd-highlight">
            <img src="/assets/images/logo-full.svg">
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex" *ngIf="!ZATCAConfig">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 align-self-end pt-5">
        <div class="d-flex align-items-end flex-column bd-highlight">
          <div class="bd-highlight">
            <p class="main-label font-weight-bold m-0">
              {{"Powered by" | translate}}
            </p>
          </div>
          <div class="bd-highlight">
            <img src="/assets/images/logo-full.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
