import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../_directives/sortable.directive';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { AlertService } from '../../_services/alert.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { CustomPaymentType } from '../../_models/CustomPaymentType';
import { CreateCustomPaymentTypeComponent } from './create-custom-payment-type/create-custom-payment-type.component';
import { CustomPaymentTypeService } from '../../_services/custom-payment-type.service';
import { ExcelService } from '../../_services/excel.service';

@Component({
  selector: 'app-custom-payment-type-list',
  templateUrl: './custom-payment-type-list.component.html',
  styleUrl: './custom-payment-type-list.component.css'
})
export class CustomPaymentTypeListComponent {


  customPaymentType$: Observable<CustomPaymentType[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  checkAllListSubscription: Subscription;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: CustomPaymentTypeService,
    private router: Router,
    private modalService: NgbModal,
    private trackEvents: TrackEventsService,
    private alertService: AlertService,
    private translate: TranslateService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private confirmationDialogService: ActionConfirmationService) {

  }
  ngOnInit() {
    this.getData();

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  getData() {
    this.service.getPaymentTypes(this.localStorageService.getSelectedBrand().BrandID);

    this.customPaymentType$ = this.service.paymentType$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


  }
  open() {
    const modalRef = this.modalService.open(CreateCustomPaymentTypeComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Custom Payment Type");
    modalRef.componentInstance.SelectedID = null;
    modalRef.result.then((result) => {
      this.getData();
    }, (reason) => {
      this.getData();
    });

  }
  Edit(item) {
    const modalRef = this.modalService.open(CreateCustomPaymentTypeComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Custom Payment Type");
    modalRef.componentInstance.SelectedID = item.id;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });
  }

  public bulkSelection($event) {
    if (this.service.allData$) {
      this.checkAllListSubscription = this.service.allData$.subscribe(i => i.forEach(e => {
        if (this.service.searchTerm) {
          if (this.service.matches(e, this.service.searchTerm))
            e.selected = $event.target.checked;
        }
        else {
          e.selected = $event.target.checked;
        }
      }));
      this.checkAllListSubscription.unsubscribe();
    }
  }


  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-PaymentTypes', type);
  }
  private getExportData() {
    let selected: CustomPaymentType[];
    let returnList = [];
    selected = [];
    this.service.paymentTypes.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.paymentTypes : selected;
    selected = selected.filter(function (item) {
      var data =
      {
        "English Name": item.name,
        "Arabic Name": item.alternateName,
        Description: item.description,
        "Customized Pricing": item.hasCustomPricing ? 'Active' : 'Inactive',
        "Available to Cashier": item.showOnCashier ? 'Yes' : 'No',
        Status: item.statusID == 1 ? 'Active' : 'Inactive'
      };
      returnList.push(data);
      return true;
    });
    return returnList;

  }
  public openConfirmationDialog(item) {
    this.confirmationDialogService.confirm('Please confirm..', item.name)
      .then((confirmed) => {
        if (confirmed) {
          item.statusID = 3;
          this.service.save(item).subscribe((res: any) => {
            this.alertService.success("Payment Type has been Deleted");
            this.getData();
          }, error => {
            this.alertService.error(error);
          });


        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }

  setStatus(item) {
    if (item.statusID === 1) {
      item.statusID = 2;
    }
    else {
      item.statusID = 1;
    }
    this.service.save(item).subscribe((res: any) => {
      if (parseInt(item.statusID) === 1)
        this.alertService.success("Payment Type has been Actived");
      else
        this.alertService.success("Payment Type has been Deactived");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }

  diableCustomPricing(item) {
    if (item.hasCustomPricing) {
      item.hasCustomPricing = false;
    }
    else {
      item.hasCustomPricing = true;
    }
    this.service.save(item).subscribe((res: any) => {
      if (item.hasCustomPricing)
        this.alertService.success("Custom Price has been Actived");
      else
        this.alertService.success("Custom Price Type has been Deactived");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();


  }
}
