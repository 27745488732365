import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../_directives/sortable.directive';
import { CustomerDto } from '../_models/CustomerDto';
import { ActionConfirmationService } from '../_services/action-confirmation.service';
import { AlertService } from '../_services/alert.service';
import { CustomerService } from '../_services/customer.service';
import { ExcelService } from '../_services/excel.service';
import { LocalStorageService } from '../_services/local-storage.service';
import { TrackEventsService } from '../_services/track-events.service';
import { AddCustomerComponent } from './add-customer/add-customer.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  data$: Observable<CustomerDto[]>;
  submit: boolean;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  excelData = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(private modalService: NgbModal,
    public service: CustomerService,
    private excelService: ExcelService,
    private local: LocalStorageService,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService   ) {
    this.loading$ = service.loading$;
    this.submit = false;
    this.getData();
  }

  ngOnInit() {
  }

  open() {
    const modalRef = this.modalService.open(AddCustomerComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Customer");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
        this.getData();
    });
  }
GenderList = [];
  CityList = [];
  Locations = [];
  getData() {
    var brandID = this.local.getSelectedBrand().BrandID;
    this.service.headerFilter = { gender: [''], city: [''], locationName: [''] };
    this.service.getAll(brandID);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    //Header Dropdown
    this.service.allData$.subscribe(res => {
      //debugger

      if (res != null) {
        this.GenderList=[];
        this.CityList=[];
        this.Locations = [];

        new Set(res.map(val => val.gender)).forEach(e => {
          if (e !== "") {
            this.GenderList.push(
              {
                name: e,
                isChecked: false
              }
            )
          }
        });

        new Set(res.map(val => val.city)).forEach(e => {
          if (e !== "") {
            this.CityList.push(
              {
                name: e,
                isChecked: false
              }
            )
          }



        });

        new Set(res.map(val => val.locationName)).forEach(e => {

          this.Locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }


    });

  }
  Edit(customer) {
    const modalRef = this.modalService.open(AddCustomerComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Customer");
    modalRef.componentInstance.SelectedID = customer.customerID;
    modalRef.componentInstance.SelectedName = customer.fullName;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });

  }
  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Customer', type);
  }
  private getExportData() {
    let selected: CustomerDto[];
    let returnList = [];
    selected = [];
    this.service.customers.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.customers : selected;
    selected = selected.filter(function (customer) {
      var data =
      {
        fullName: customer.fullName,
        mobileNumber: customer.mobileNumber,
        email: customer.email,
        locationName: customer.locationName,
        gender: customer.gender,
        city: customer.city,
        age: customer.age,
        orders: customer.orders,
        lastVisit: customer.lastVisit,
        status: customer.statusID == 1 ? 'Active' : (customer.statusID == 2) ? 'Deactive' : 'Deleted'
      };
      returnList.push(data);
      return true;
    });
    return returnList;
  }
  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }
  public chkSelection($event, customer) {
    this.service.customers.forEach((item, index) => {
      if (item.customerID == customer.customerID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  

  UpdateStatus(customer, statusID) {
    let message = '';
    if (statusID === 1)
      message = 'Customer has been activated';
    else if (statusID === 2)
      message = 'Customer has been deactivated';
    else if (statusID === 3)
      message = 'Customer has been deleted';
    this.service.UpdateStatus(parseInt(customer.customerID), customer.rowVersion, parseInt(statusID)).subscribe((res: any) => {
      this.alertService.success(message);
      this.getData();
    }, error => {
      this.alertService.error(error);
    });

  }


  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription !== undefined)
      this.locationSubscription.unsubscribe();
  }
  public openConfirmationDialog(customer, statusID) {
    this.confirmationDialogService.confirm('Please confirm..', customer.fullName)
      .then((confirmed) => {
        if (confirmed) {
          this.service.UpdateStatus(parseInt(customer.customerID), customer.rowVersion, parseInt(statusID)).subscribe((res: any) => {
            this.alertService.success("Customer has been Deleted");
            this.getData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }

  checkMobile(i1) {
    var filter = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
    // filter = /^\(?(\d{2,3})\)?[ .-]?(\d{2,3})[ .-]?(\d{4})[ .-]?(\d{3})$/;
    if (filter.test(i1)) {
      return true;
    } else {

      return false;
    }
  }

  checkEmail(i1) {
    var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    // filter = /^\(?(\d{2,3})\)?[ .-]?(\d{2,3})[ .-]?(\d{4})[ .-]?(\d{3})$/;
    if (filter.test(i1)) {
      return true;
    } else {

      return false;
    }
  }



  SelectedGender = 'All Genders';
  SelectedCity = "All Cities";
  setSelectedResult(val) {

    if (val == 'location') {
      return this.Locations.filter(k => k.isChecked == true).length;
    }

  }


  filters() {

    let headerFilter = {
      gender: (this.SelectedGender == 'All Genders') ? this.GenderList.map(function (k) { return k.name }) : [this.SelectedGender],
      locationName: this.Locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      city: (this.SelectedCity == 'All Cities') ? this.CityList.map(function (k) { return k.name }) : [this.SelectedCity]
    }
    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}
