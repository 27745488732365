import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbdSortableHeader, SortColumn, SortEvent } from '../../../_directives/sortable.directive';
import { ProductMix } from '../../../_models/ProductMix';
import { AlertService } from '../../../_services/alert.service';
import { DataOperationsService } from '../../../_services/data-operations.service';
import { ExcelService } from '../../../_services/excel.service';
import { ProductMixService } from '../../../_services/product-mix.service';

@Component({
  selector: 'app-product-mix',
  templateUrl: './product-mix.component.html',
  styleUrls: ['./product-mix.component.css']
})
export class ProductMixComponent implements OnInit {
  data$: Observable<ProductMix[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  showLocationColumn$: Observable<boolean>;
  showModifierColumn$: Observable<boolean>;
  locations = [];
  checkAllLocations: boolean = false;
  submit: boolean;
  selectedReport: any;
  inventoryPurchase: string = '';
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  sortColumn: SortColumn;
  sortDirection: string;
  constructor(private excelService: ExcelService,
    public service: ProductMixService,
    private router: Router,
    private alertService: AlertService, private dataOperationService: DataOperationsService) {
    this.loading$ = service.loading$;
    this.submit = false;

    this.selectedReport = history.state;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
      if (!this.selectedReport?.filters.split('|')[2] === false) {
        this.inventoryPurchase = this.selectedReport?.filters.split('|')[2].trim();
      }
    }, 1000);
  }
  getReportData(dataURL) {
    this.service.getReport(dataURL);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    this.showLocationColumn$ = this.data$.pipe(
      //tap(data => console.log('Data received:', data)), // Debugging data
      map(data => {
        const shouldShow = data.some(order => order.LocationName);
        //console.log('Should show LocationName column:', shouldShow);
        return shouldShow;
      })
    );
    this.showModifierColumn$ = this.data$.pipe(
      //tap(data => console.log('Data received:', data)), // Debugging data
      map(data => {
        const shouldShow = data.some(order => order.ModifierID);
        //console.log('Should show LocationName column:', shouldShow);
        return shouldShow;
      })
    );
    this.service.allData$.subscribe(res => {
      this.locations = [];
      if (res) {
        new Set(res.map(report => report.LocationName)).forEach(e => {
          this.locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

  }
  export(type, data): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-ProductMix', type);
  }
  private getExportData() {
    var selected: any;

    selected = [];
    this.service.report.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });

    if (selected.length == 0) {
      this.alertService.error("Select records to export");
      return;
    }

    selected = selected.length == 0 ? this.service.report : selected;
    

    let report = [];
    selected.forEach(d => {
      let rp: any = {};
      if (d.LocationID) {
        rp.LocationID = d.LocationID;
        rp.LocationName = d.LocationName;
      }

      if (d.ModifierName) {
        rp.ModifierID = d.ModifierID;
        rp.ModifierName = d.ModifierName;
        rp.AlternateModifierName = d.AlternateModifierName;
      }
      rp.ItemID = d.ItemID;
      rp.Name = d.Name;
      rp.AlternateName = d.AlternateName;
      rp.Price = d.Price;
      rp.SalePrice = d.SalePrice;
      rp.Quantity = d.Quantity;
      rp.TotalSales = d.TotalSales;
      rp.ItemCost = d.ItemCost;
      rp.Margin = d.Margin;
      rp.Profit = d.Profit;
      report.push(rp);
    });

    report = this.dataOperationService.prepareExportData(report, this.sortColumn, this.sortDirection);
    return report;
  }
  public bulkSelection($event) {
    this.service.report.forEach(d => {
      d.selected = $event.target.checked;
    });

  }
  filterLocations() {
    if (this.locations.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllLocations = false;
    }
    else {
      this.checkAllLocations = true;
    }
    let headerFilter = {
      locations: this.locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
    }
    this.service.headerFilter = headerFilter;
  }


  public selectAllLocationFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filterLocations();
  }
}
