import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { SubUser } from '../_models/SubUser';
import { tap, switchMap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { State } from '../_models/State';



interface SearchSubUserResult {
  data: SubUser[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: SubUser[], column: SortColumn, direction: string): SubUser[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: SubUser, term: string) {
  return data.firstName?.toLowerCase().includes(term.toLowerCase()) || data.lastName?.toLowerCase().includes(term.toLowerCase()) || data.contactNo?.toLowerCase().includes(term.toLowerCase())
    || data.userType.toLowerCase().includes(term.toLowerCase()) || data.email?.toLowerCase().includes(term.toLowerCase())
}




//@@@ Header Filter Code Start
interface FilterHeaderState {
  status: string[],
  usertype: string[],
}


function matcheHeader(data: SubUser, filterValues: FilterHeaderState) {
  let status = false;
  let usertype = false;

  if (filterValues.status !== null && filterValues.status.length > 0) {
    for (var i = 0, len = filterValues.status.length; i < len; i++) {
      if (data.statusID.toString().includes(filterValues.status[i].toString())) {
        status = true;
      }
    }
  }
  else {
    status = true;
  }

  // userType
  if (filterValues.usertype !== null && filterValues.usertype.length > 0) {
    for (var i = 0, len = filterValues.usertype.length; i < len; i++) {
      if (data.userType.toLowerCase().toString().includes(filterValues.usertype[i].toLowerCase())) {
        usertype = true;
      }
    }
  }
  else {
    usertype = true;
  }

  if (usertype && status) {
    return data;
  }

}
//@@@ Header Filter Code End

@Injectable({
  providedIn: 'root'
})
export class SubUserService {
  private _loading$ = new Subject<boolean>();
  private _search$ = new Subject<void>();
  private _allData$ = new Subject<SubUser[]>();
  private _data$ = new Subject<SubUser[]>();
  private _total$ = new Subject<number>();
  public subUsers: SubUser[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }


  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {
    status: null,
    usertype: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchSubUserResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.subUsers, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  //@@@ Header Filter Code End
  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }





  constructor(private http: HttpClient) {

  }

  private _search(): Observable<SearchSubUserResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.subUsers, sortColumn, sortDirection);
    ////// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$.next(null);
    this._searchHeader$.next();
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }


  public getAll(brandID, locationID = null) {
    this.clear();
    let url = `api/subuser/all/${brandID}`;
    if (locationID) {
      url = `api/subuser/GetAllByLocation/${brandID}/location/${locationID}`;
    }
    tap(() => this._loading$.next(true)),
      this.http.get<SubUser[]>(url).subscribe(res => {
        this.subUsers = res;
        this._allData$.next(this.subUsers);



        //this._search$.pipe(
        //  switchMap(() => this._search()),
        //  tap(() => this._loading$.next(false))
        //).subscribe(result => {
        //  this._data$.next(result.data);
        //  this._total$.next(result.total);
        //});
        //this._search$.next();


        //@@@ Header Filter Code Start
        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });


        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

        //@@@ Header Filter Code End
      });
  }
  public delete(id, version) {
    return this.http.delete<any[]>(`api/subuser/${id}/${version}`);
  }
  public deactive(id, version) {
    return this.http.patch(`api/subuser/deactive/${id}/${version}`, null);
  }
  public get(subUserID, BrandID) {
    return this.http.get<SubUser[]>(`api/subuser/${subUserID}/brand/${BrandID}`);
  }
  insert(data) {
    return this.http.post(`api/subuser`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  update(data) {
    return this.http.put(`api/subuser`, data)
      .pipe(map(res => {
        return res;
      }));
  }
  public setStatus(id, statusID, version) {
    return this.http.patch(`api/subuser/SetStatus/${id}/${statusID}/${version}`, null);
  }
}
