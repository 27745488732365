<main class="views container order-list">
  <div class="page">
    <div class="page-header">
      <div class="row mb-4 tCustom-header align-items-center g-2">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-4 hide-mobile">
          <h4>    <a backLink>{{'Setup' | translate }} </a>&nbsp;/ {{'Custom Payment Type' | translate }}</h4>
        </div>
        <div class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-xxl-2">
        </div>
        <div class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-xxl-6">
          <div class="row justify-content-end g-2">

            <div class="col-auto">
              <div ngbDropdown class="nav-item" placement="bottom" *hasPermission="['Setup','Custom Payment Type', 'Export']">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <img src="assets/images/icons/v2/export.svg" class="mr-1" />&nbsp; <span>{{'Export' | translate }} </span>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
                  <button ngbDropdownItem (click)="export('csv')">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
                </div>
              </div>
            </div>


            <div class="col-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text"
                     name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>

            <div class="col-auto" *hasPermission="['Setup','Custom Payment Type', 'Insert']">
              <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
                +&nbsp;{{'Add Custom Payment Type' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    @if((service.paymentTypes?.length > 0) && (customPaymentType$ | async)?.length > 0){
    <div class="page-body position-relative">
      <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="tile-body p-0 table-responsive text-nowrap">
          <table class="table table-striped order-type text-start">
            <thead>
              <tr class="table-header">
                <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
                <th width="5%" class="text-center"> <img class="d-inline" src="assets/images/icons/ListImageIcon.svg" /></th>
                <th width="30%" sortable="name" (sort)="onSort($event)">{{'Custom Paymen Type' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" sortable="hasCustomPricing" (sort)="onSort($event)">{{'Custom Pricing' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" sortable="quantity" (sort)="onSort($event)">{{'Status' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of customPaymentType$ | async " editRow>
                <td class="text-center">
                  <input type="checkbox" class="form-check-input" (change)="chkSelection($event,item)" [(ngModel)]="item.selected" />
                </td>
                <td (click)="Edit(item)">
                  <img [src]="item.logo+'?refreshCache='+breakCache" *ngIf="(item.logo)" class="table-img" alt="image" />
                  <img *ngIf="(!item.logo)" src="assets/images/icons/ListImageIcon.svg" alt="" height="50" width="30" />


                </td>
                <td (click)="Edit(item)">{{item.name}}</td>
                <td (click)="Edit(item)">
                  <ngb-highlight [ngClass]="{'btn-active': item.hasCustomPricing , 'btn-inactive':!item.hasCustomPricing }" class="btn btn-sm" [result]="(item.hasCustomPricing ?'Active':'Inactive') | translate" [term]="service.searchTerm">
                  </ngb-highlight>
                </td>
                <td (click)="Edit(item)">
                  <ngb-highlight [ngClass]="{'btn-active': item.statusID === 1, 'btn-inactive':item.statusID !== 1 }" class="btn btn-sm" [result]="(item.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                  </ngb-highlight>
                </td>
                <td>
                  <img class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent" src="/assets/images/dots-list.svg" />
                  <ng-template #popContent>
                    <p class="dropdown-heading-mini">{{'Actions' | translate }}</p>
                    <a *hasPermission="['Setup','Custom Payment Type', 'Edit']" (click)="Edit(item)">{{'Edit' | translate }}</a>
                    <ng-container *ngIf="item.statusID === 1">
                      <a *hasPermission="['Setup','Custom Payment Type', 'Deactivate']" (click)="setStatus(item)">{{'Deactivate' | translate }}</a>
                    </ng-container>
                    <ng-container *ngIf="item.statusID === 2">
                      <a *hasPermission="['Setup','Custom Payment Type', 'Deactivate']" (click)="setStatus(item)">{{'Activate' | translate }}</a>
                    </ng-container>
                    <ng-container *ngIf="item.hasCustomPricing">
                      <a *hasPermission="['Setup','Custom Payment Type', 'Deactivate Custom Pricing']" (click)="diableCustomPricing(item)">{{'Disable Custom Pricing' | translate }}</a>
                    </ng-container>
                    <ng-container *ngIf="!item.hasCustomPricing">
                      <a *hasPermission="['Setup','Custom Payment Type', 'Deactivate Custom Pricing']" (click)="diableCustomPricing(item)">{{'Enable Custom Pricing' | translate }}</a>
                    </ng-container>
                    <a *hasPermission="['Setup','Custom Payment Type', 'Delete']" (click)="openConfirmationDialog(item)" class="selected-inner-text selected-inner-bottom">{{'Delete' | translate }}   </a>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
    }
    @else{
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Custom Payment Type' | translate }}</strong>
      <p class="text-center width-375">{{'The set payment Type are added but you didn’t add any custom Types. Add a custom payment Type so you can customise your prices on it' | translate}}</p>
      <div class="col-auto" *hasPermission="['Setup','Coupons', 'Insert']">
        <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
          +&nbsp;{{'Add Custom Payment Type' | translate}}
        </button>
      </div>
    </div>
    }

  </div>
</main>
