<form [formGroup]="couponForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header ">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{ title  | translate}}
    </h4>

    <button [disabled]="loading" style="float: right; " class="btn btn-primary cutom-button custom-create-btn ms-2" *hasPermission="['Setup','Coupons', (SelectedID) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>   {{'Save' | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Table Title">{{'Coupon Title' | translate }}</label>
              <input type="text" class="form-control" id="name" placeholder="{{'Coupon Title' | translate}}" formControlName="name">
              <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                <div *ngIf="f.name.errors.required">
                  {{'Name is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Table Title">{{'Coupon Code' | translate }}</label>
              <input type="text" class="form-control" id="couponCode" placeholder="{{'Coupon Code' | translate }}" formControlName="couponCode" [class.disabled]="SelectedID">
              <div *ngIf="f.couponCode.invalid && (f.couponCode.dirty || f.couponCode.touched)" class="alert alert-danger">
                <div *ngIf="f.couponCode.errors.required">
                  {{'Coupon Code is required' | translate }}.
                </div>
                {{'Invalid Coupon Code' | translate }}.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Table Title">{{'Coupon Quantity' | translate }}</label>
              <input type="number" class="form-control" id="quantity" placeholder="{{'Add Quantity' | translate }}" formControlName="quantity" [class.disabled]="SelectedID"
                     [readonly]="SelectedID">
              <div *ngIf="f.quantity.invalid && (f.quantity.dirty || f.quantity.touched)" class="alert alert-danger">
                <div *ngIf="f.quantity.errors.required">
                  {{'Quantity is required' | translate }}.
                </div>
                {{'Quantity must be greater than 1' | translate }}.
              </div>
              <small>{{'How many times this Coupon can be used?' | translate}}</small>
            </div>
          </div>
          <div class="row">

            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Locations' | translate }}</label>
              <ng-select [items]="Locations" #select bindLabel="name" class="custom" bindValue="locationID" [multiple]="true"
                         placeholder="" [(ngModel)]="selectedLocations" [ngModelOptions]="{standalone: true}">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('location')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #1f2532;"
                          (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>

              <!--<div *ngIf="(!selectedLocations || selectedLocations.length === 0)" class="alert alert-danger">
        {{'Location Required' | translate }}.
      </div>-->
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Location">{{'Coupon Type' | translate }}</label>
              <select class="custom-select form-control custom-select-2" formControlName="appliedOn" (change)="loadItems()">
                <option [ngValue]="option.id" *ngFor="let option of appliedOnList">
                  {{option.name | translate}}
                </option>
              </select>

            </div>
          </div>
          @if(f.appliedOn.value === 2){
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Table Title">{{'Redeemable Items Quantity' | translate }}</label>
              <input type="number" class="form-control" id="redeemItemQty" placeholder="{{'Add the Quantity of Items to be Redeemed' | translate }}" formControlName="redeemItemQty">
              <div *ngIf="f.redeemItemQty.invalid && (f.redeemItemQty.dirty || f.redeemItemQty.touched)" class="alert alert-danger">
                <div *ngIf="f.redeemItemQty.errors.required">
                  {{'Redeemable Quantity is required' | translate }}.
                </div>
                {{'Redeemable Quantity must be greater than 1' | translate }}.
              </div>
            </div>
          </div>

          <div class="form-group col-lg-12 col-sm-12 px-0">
            <label for="Country">{{'Select Items' | translate }}</label>

            <ng-select [items]="Items" class="po-box-autocomplete border-right-radius-unset "
                       bindLabel="name"
                       bindValue="itemID"
                       [multiple]="true"
                       placeholder="Search"
                       [(ngModel)]="selectedItems"
                       [ngModelOptions]="{standalone: true}"
                       (change)="addRow($event)">


              <ng-template ng-multi-label-tmp let-items="items">

              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                <div class="row d-flex align-items-center p-2">
                  <label class="col-5 m-0">

                    {{item.name}}
                  </label>

                </div>
              </ng-template>
            </ng-select>


            <div *ngIf="f.appliedOn.value == 2 && (!selectedItems || selectedItems.length === 0)" class="alert alert-danger">
              {{'Items Required' | translate }}.
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 form-group ">
              <section class="tile" style="border:none;box-shadow:none">
                <div class="p-0 table-responsive ">
                  <table class="table table-striped po-item">
                    <thead>
                      <tr class="table-header">
                        <th scope="col" width="5%"><img src="/assets/images/icons/v2/camera.svg" /></th>
                        <th scope="col" width="40%" class="border-radius-topleft-10">{{'Item Name' | translate }}</th>
                        <th scope="col" width="5%" class="border-radius-topright-10 border-start-width-0 border-end-width-0"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of arrItems">
                        <td>
                          <img [src]="item.itemImage" *ngIf="(item.itemImage !== '' && item.itemImage !== null)" class="table-img" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                          <img *ngIf="(item.itemImage === '' || item.itemImage === null)" src="https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG" class="table-img" alt="" />
                        </td>
                        <td scope="row" class="font-weight-bold">{{(selectedLang == 'en' ? item.name : ((item.nameOnReceipt !== '')? item.nameOnReceipt:item.name))}}</td>

                        <td>
                          <a><img src="/assets/images/icons/v2/Delete.svg" (click)="deleteRow(item)" /></a>
                        </td>
                      </tr>


                    </tbody>
                  </table>
                </div>

              </section>
            </div>
          </div>
          }
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Location">{{'Discount Type' | translate }}</label>
              <select class="custom-select form-control custom-select-2" formControlName="calculationBy" [class.disabled]="SelectedID" (change)="validateFields()">
                <option [ngValue]="option.id" *ngFor="let option of calculationByList">
                  {{option.name | translate}}
                </option>

              </select>

            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Price">{{(f.calculationBy.value === 'P')?'Percentage':'Amount' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" id=amount formControlName="amount" placeholder="{{(f.calculationBy.value === 'P')?'Percentage':'Amount' | translate }}">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2"> &nbsp;&nbsp; {{(f.calculationBy.value === 'P')?'%':'SAR' | translate }}&nbsp;&nbsp;</span>
                </div>
              </div>
              <div *ngIf="f.amount.invalid && (f.amount.dirty || f.amount.touched)" class="alert alert-danger">
                <div *ngIf="f.amount.errors.required">
                  {{(f.calculationBy.value === 'P')?'Percentage':'Amount'}}&nbsp;{{'is required' | translate }}.
                </div>
                {{(f.calculationBy.value === 'P')?'Percentage':'Amount'}}&nbsp;{{'must be greater than 1' | translate }}.
              </div>
            </div>
          </div>
          @if(f.calculationBy.value === 'P'){
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Price">{{'Price Cap' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" id=priceCap formControlName="priceCap" placeholder="{{'Price Cap' | translate }}">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2"> &nbsp;&nbsp; {{'SAR' | translate }}&nbsp;&nbsp;</span>
                </div>
              </div>
              <div *ngIf="f.priceCap.invalid && (f.priceCap.dirty || f.priceCap.touched)" class="alert alert-danger">
                <div *ngIf="f.priceCap.errors.required">
                  {{'Price Cap is required' | translate }}.
                </div>
                {{'Price Cap must be greater than 1' | translate }}.
              </div>
            </div>
          </div>
          }
          


          <div class="tiltle-bar form-group">
            <div class="row">
              <div class="col-12">
                <label>{{'Set Coupon Period' | translate}}</label>
                <p>{{'Select the dates when the Coupon should start and end' | translate}}</p>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 form-group">
              <div class="row">
                <div class="col-lg-12">
                  <label for="startDate">{{'Start Date' | translate }}</label>
                  <div class="form-group" style="padding:0px">
                    <div class="input-group">
                      <input class="form-control" placeholder="yyyy-mm-dd"
                             name="dpStart" ngbDatepicker #d="ngbDatepicker" [minDate]="currentdate"
                             formControlName="startDate" [readonly]="true">
                      <div class="input-group-append"><div class="btn-datepicker" (click)="d.toggle()"><img alt="Calender" src="/assets/images/calendar.svg"></div></div>
                    </div>
                  </div>
                  <div *ngIf="f.startDate.invalid && (f.startDate.dirty || f.startDate.touched)" class="alert alert-danger">
                    <div *ngIf="f.startDate.errors.required">
                      {{'Start Date is required' | translate }} .
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label for="expiryDate">{{'End Date' | translate }}</label>
                  <div class="col-lg-12">
                    <div class="input-group">
                      <input class="form-control" placeholder="yyyy-mm-dd"
                             name="dpExpire" ngbDatepicker #dExpire="ngbDatepicker" [minDate]="currentdate"
                             formControlName="expiryDate" [readonly]="true">
                      <div class="input-group-append"><div class="btn-datepicker" (click)="dExpire.toggle()"><img alt="Calender" src="/assets/images/calendar.svg"></div></div>
                    </div>
                  </div>
                  <div *ngIf="f.expiryDate.invalid && (f.expiryDate.dirty || f.expiryDate.touched)" class="alert alert-danger">
                    <div *ngIf="f.expiryDate.errors.required">
                      {{'End Date is required' | translate }} .
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="invalidDate" class="alert alert-danger">
                {{ 'Invalid date selection' | translate }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group  col-lg-12">
              <div class="status-activebar">
                <label for="Status">

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{'Coupon Status.' | translate }}
                </label>

                <div class="form-check form-switch">
                  <span class="inactive" (click)="f.statusID.setValue(false)" [className]="f.statusID.value==false ? 'active' : 'inactive'" role="button"> {{'Inactive' | translate}}</span>
                  <span class="inactive active" (click)="f.statusID.setValue(true)" [className]="f.statusID.value==true ? 'active' : 'inactive'" role="button">{{'Active' | translate}}</span>
                </div>

              </div>
            </div>
          </div>


        </div>
            <div class="col-lg-4"></div>
          </div>
        </div>
  </div>
</form>
