<main class="views container order-list">
  <div class="page">
    <div class="page-header">
      <div class="row mb-4 tCustom-header align-items-center g-2">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-3 hide-mobile">
          <h4>    <a backLink>{{'Setup' | translate }} </a>&nbsp;/ {{'Location Groups' | translate }}</h4>
        </div>
        <div class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-xxl-5">
          @if((service.locationGroups?.length > 0)){
          @if(checkSelection(); as count){
          @if(count > 1){
          <div class="col-md-auto row bulk-update align-items-center gy-2">
            <div class="col-md-auto">
              <a class="form-control text-center selected-location selected-items">
                <span style="display:block;width:auto">{{count}} {{'Selected' | translate}} </span>
              </a>
            </div>
            @if(inActiveCount>0 ){
            <div class="col-md-auto" *hasPermission="['Setup','Locations Group','Deactivate']">
              <button type="button" (click)="updateStatus(1)" class=" btn-primary cutom-button btn-activate">
                {{'Activate' | translate }}
              </button>
            </div>
            }
            @if(activeCount>0 ){
            <div class="col-md-auto" *hasPermission="['Setup','Locations Group','Deactivate']">
              <button type="button" (click)="updateStatus(2)" class=" btn-primary cutom-button btn-activate">
                {{'Deactivate' | translate }}
              </button>
            </div>
            }
            <div class="col-md-auto" *hasPermission="['Setup','Locations Group','Delete']">
              <button type="button" (click)="askForDelete(3)" class=" btn-primary cutom-button btn-delete fw-bold">
                <img src="/assets/images/icons/v2/Delete.svg" />
                <span style="margin: 0px 0px 0px 3px">{{'Delete' | translate }}</span>
              </button>
            </div>
          </div>
          }
          }
          }
        </div>
        <div class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-xxl-4">
          <div class="row justify-content-end g-2">
            <div class="col-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text"
                     name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>

            <div class="col-auto" *hasPermission="['Setup','Locations Group', 'Insert']">
              <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
                {{'Create Group' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body position-relative">
      <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="tile-body p-0 table-responsive text-nowrap">
          <table class="table table-striped order-type text-start">
            <thead>
              <tr class="table-header">
                <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
                <th width="40%" sortable="tableTitle" (sort)="onSort($event)">{{'Group Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="floorTitle" (sort)="onSort($event)">{{'Locations Associated' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%">
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(locationGroups$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                      <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                               name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                        <label class="form-check-label font-blue" for="dropdownStatus_all"
                               (click)="selectAllFilters($event,StatusList)">
                          {{'All Status' | translate}}
                        </label>
                      </div>
                      <div *ngFor="let item of StatusList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                          <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                            <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                           class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                            </ngb-highlight>


                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of locationGroups$ | async " editRow>
                <td class="text-center">
                  <input type="checkbox" class="form-check-input" (change)="chkSelection($event,item)" [(ngModel)]="item.selected" />
                </td>
                <td (click)="Edit(item)">{{item.name}}</td>
                <td (click)="Edit(item)">{{item.count}}</td>
                <td (click)="Edit(item)">
                  <ngb-highlight [ngClass]="{'btn-active': item.statusID === 1, 'btn-inactive':item.statusID !== 1 }" class="btn btn-sm" [result]="(item.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                  </ngb-highlight>
                </td>
                <td>
                  <img class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent" src="/assets/images/dots-list.svg" />
                  <ng-template #popContent>
                    <p class="dropdown-heading-mini">{{'Location Group' | translate }}</p>
                    <a *hasPermission="['Setup','Locations Group', 'Edit']" (click)="Edit(item)">{{'Edit' | translate }}</a>
                    <ng-container *ngIf="item.statusID === 1">
                      <a *hasPermission="['Setup','Locations Group', 'Deactivate']" (click)="deactivate(item)">{{'Deactivate' | translate }}</a>
                    </ng-container>
                    <ng-container *ngIf="item.statusID === 2">
                      <a *hasPermission="['Setup','Locations Group', 'Deactivate']" (click)="activate(item)">{{'Activate' | translate }}</a>
                    </ng-container>

                    <a *hasPermission="['Setup','Locations Group', 'Delete']" (click)="openConfirmationDialog(item)" class="selected-inner-text selected-inner-bottom">{{'Delete' | translate }}   </a>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</main>
