import { Injectable } from '@angular/core';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { CustomPaymentType } from '../_models/CustomPaymentType';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';


interface SearchResult {
  data: CustomPaymentType[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(locations: CustomPaymentType[], column: SortColumn, direction: string): CustomPaymentType[] {
  if (direction === '' || column === '') {
    return locations;
  } else {
    return [...locations].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}


@Injectable({
  providedIn: 'root'
})
export class CustomPaymentTypeService {



  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _paymentTypes$ = new BehaviorSubject<CustomPaymentType[]>([]);
  private _allData$ = new BehaviorSubject<CustomPaymentType[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  public paymentTypes: CustomPaymentType[];

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
  }

  get allData$() {
    return this._allData$.asObservable();
  }

  get paymentType$() {
    return this._paymentTypes$.asObservable();
  }


  getPaymentTypes(brandId) {
    //this._locations$.next(null);
    let url = `api/PaymentType/all/${brandId}`;


    this.http.get<any>(url).subscribe(res => {


      this.paymentTypes = res;
      this._paymentTypes$.next(this.paymentTypes);
      this._allData$.next(this.paymentTypes);



      //@@@ Header Filter Code Start
      this._search$.pipe(
        switchMap(() => this._searchByHeader()),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {

        this._paymentTypes$.next(result.data);
        this._total$.next(result.total);
      });


      this._searchHeader$.pipe(
        switchMap(() => this._searchByHeader()),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {

        this._paymentTypes$.next(result.data);
        this._total$.next(result.total);
      });

      this._search$.next();
      this._searchHeader$.next();

      //@@@ Header Filter Code End

    });
  }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  


  private _searchByHeader(): Observable<SearchResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.paymentTypes, sortColumn, sortDirection);

    //// 2. filter
    //sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => this.matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._paymentTypes$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  matches(item: CustomPaymentType, term: string) {
    return item.name.toLowerCase().includes(term.toLowerCase());
  }

  clear() {
    this._paymentTypes$.next(null);
    this._searchHeader$.next();


    this._search$.next();
    this._searchHeader$.next();
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.paymentTypes, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => this.matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }
 

  save(data) {
    return this.http.post(`api/PaymentType`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  public getById(id,brandid) {
    return this.http.get<CustomPaymentType>(`api/PaymentType/${id}/${brandid}`);
  }

  public delete(couponID, version) {
    return this.http.delete<any>(`api/PaymentType/${couponID}/${version}`);
  }

  public setStatus(couponID, version) {
    return this.http.patch<any>(`api/PaymentType/${couponID}/${version}`, null);
  }

}
