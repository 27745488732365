<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">{{ 'Import Manager' | translate }}  </h4>
  <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" (click)="onSubmit()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    {{ButtonText | translate}}
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="StepForm">
        <!-- start step indicators -->
        <div class="form-header d-flex mb-4">
          <span class="stepIndicator" [ngClass]="{'active':formNumber===1, 'finish':formNumber>1}" (click)="moveToPage(1)" role="button">
            <span class="fw-bold">
              {{'Select Method' | translate}}
            </span>
            <br />
            <span>
              {{'And fill in requirements' | translate}}
            </span>
          </span>

          <span class="stepIndicator" [ngClass]="{'active':formNumber===2, 'finish':formNumber>2}" (click)="moveToPage(2)" role="button">
            <span class="fw-bold">
              {{'Upload Files' | translate}}
            </span>
            <br />
            <span>
              {{'Upload required files' | translate}}
            </span>
          </span>

          <span class="stepIndicator" [ngClass]="{'active':formNumber===3, 'finish':formNumber>3}">
            <span class="fw-bold">
              {{'Processing' | translate}}
            </span>
            <br />
            <span>
              {{'Processing and converting the uploaded files' | translate}}
            </span>
          </span>

          <span class="stepIndicator" [ngClass]="{'active':formNumber===4, 'finish':formNumber>4}" (click)="moveToPage(4)" role="button">
            <span class="fw-bold">
              {{'Visualizer' | translate}}
            </span>
            <br />
            <span>
              {{'See and edit the results of your data before submitting' | translate}}
            </span>
          </span>

          <span class="stepIndicator" [ngClass]="{'active':formNumber===5, 'finish':formNumber>5}">
            <span class="fw-bold">
              {{'Verification' | translate}}
            </span>
            <br />
            <span>
              {{'Verifying data compliance' | translate}}
            </span>
          </span>

        </div>
        <!-- end step indicators -->
        <!-- step one -->
        <div class="step pt-5" *ngIf="formNumber ===1">
          <h6 class="text-center fw-bold">{{'Choose your preferred way to Import Data to your Menu Catalog' | translate}}</h6>
          <div class="row justify-content-center">
            <div class="col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 p-2" role="button">
              <div class="bg-gray px-3 pt-3 pb-5 mt-3 border border-2" [ngClass]="{'border-primary':selectedMethod==='manualImport'}" (click)="SelectMethod('manualImport')" (dblclick)="showUploader('manualImport')">
                <img src="assets/images/icons/Excel_Template_Image.svg" height="127" />
                <br />
                <label class="fw-bold">{{'Manual Importing' | translate}}</label>
                <br />
                <span>{{'Download our csv file, and fill it yourself' | translate}}</span>
              </div>
            </div>
            <div class="col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 p-2" role="button">
              <ngb-highlight class="btn btn-sm btn-badge-yellow rounded mb-2 ms-4 position-absolute" [result]="'Soon' | translate" [term]="'Soon'">
              </ngb-highlight>
              <div class="bg-gray px-3 pt-3 pb-2 mt-3 opacity-50 border border-2" [ngClass]="{'border-primary':selectedMethod==='mediaScanner'}" (click)="false && SelectMethod('mediaScanner')">
                <img src="assets/images/icons/Multimedia_Template_Image.svg" height="127" />
                <br />
                <label class="fw-bold">{{'Multimedia Scanner' | translate}}</label>
                <br />
                <span>{{'Upload an image of your menu, even paper menu, and let us convert into a csv sheet' | translate}}</span>

              </div>
            </div>
            <div class="col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 p-2" role="button">
              <ngb-highlight class="btn btn-sm btn-badge-yellow rounded mb-2 ms-4 position-absolute" [result]="'Soon' | translate" [term]="'Soon'">
              </ngb-highlight>
              <div class="bg-gray px-3 pt-3 pb-5 mt-3 opacity-50 border border-2" [ngClass]="{'border-primary':selectedMethod==='sheetConverter'}" (click)="false && SelectMethod('sheetConverter')">
                <img src="assets/images/icons/SheetConverter_Template_Image.svg" height="127" />
                <br />
                <label class="fw-bold">{{'Sheet Converter' | translate}}</label>
                <br />
                <span>{{'Upload your csv file, we will convert it to our format' | translate}}</span>
              </div>
            </div>
          </div>

          <div class="row justify-content-center" *ngIf="selectedMethod==='manualImport'">
            <h6 class="text-center fw-bold pt-5">{{'Download CSV Template' | translate}}</h6>
            <div class="col-9 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 p-2 bg-gray border rounded-2">
              <label class="blue" role="button" (click)="download_CSV()">
                <img src="assets/images/icons/Excel_Download_Tempate_Image.svg" />
                {{'Marn Items Catalog CSV' | translate}}
              </label>
            </div>
          </div>
        </div>
        <div class="step pt-5" *ngIf="formNumber ===2">
          <div class="row justify-content-md-center">
            <h6 class="text-center fw-bold">{{'Upload Files' | translate}}</h6>
            <label class="text-center">
              {{'After download Marn Items Catalog CSV and filling your' | translate}}
            </label>
            <label class="text-center">
              {{'Categories, Subcategories, And Items, you can now upload it' | translate}}
            </label>
            <label class="text-center">
              {{'again below' | translate}}.
            </label>
            <div class="form-group col-lg-5 col-sm-5 col-12 pt-4">
              <div class="row mx-1">
                <div class="form-group col-lg-12 col-sm-12 Upload-Files border p-2 rounded m-0">
                  <input type="file" (change)="onFileChange($event)" class="form-control" accept=".csv, .xlsx" max-size="5000"
                         #itemFile id="itemFile" (click)="itemFile.value = null; fileData=null" value="" />
                  <div class="d-flex row">
                    <img src="../../assets/images/UploadFileImage.svg" height="75" class="justify-content-center my-2" />
                    <label id="file-input-label" for="itemFile" (click)="currentFile = null"
                           role="button" class="text-center position-absolute" style="padding-top:85px">
                      <span class="blue">{{'Click to upload' | translate}}</span>
                      <span>
                        {{'or drag and drop'| translate}}
                      </span>
                    </label>

                    <label class="text-center pt-3">{{'CSV or XLSX' | translate}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-4 justify-content-md-center" *ngIf="fileData || (fileData && fileData.length>0)">
            <div class="form-group col-lg-5 col-sm-5 col-12">
              <div class="row align-items-center border rounded p-2 border-primary">
                <div class="col-lg-1 col-sm-1">
                  <img src="assets/images/icons/File_upload_icon.svg" class="float-start" />
                </div>
                <div class="col-lg-9 col-sm-9">
                  <label class="fw-bold">{{currentFile?.name}}</label>
                  <br />
                  <label>{{currentFile?.size/1024 | number:'1.0-2'}} KB</label>

                </div>
                <div class="col-lg-2 col-sm-2">
                  <a role="button" [target]="_blank">
                    <img src="assets/images/icons/File_Upload_Success_Icon.svg" class="move-to-end" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="step pt-5" *ngIf="formNumber ===3">
          <div class="row justify-content-md-center">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center">
              <h6 class="text-center fw-bold">{{'Processing' | translate}}</h6>
              <label>{{'Converting your file' | translate}}</label>
              <br />
              <img src="assets/images/icons/Loading_Image.svg" />
            </div>
          </div>
        </div>
        <div class="step pt-5" *ngIf="formNumber ===4">
          <div class="row justify-content-md-center">
            <h6 class="text-center fw-bold">{{'Edit and Review' | translate}}</h6>
            <label class="text-center">
              {{'Edit and review sheet before submitting to be verified' | translate}}
            </label>
            <label class="text-center">
              <span>
                {{'You can also' | translate}}
              </span>
              <span class="blue" (click)="ExportSheetData()" role="button">
                {{'Export This Sheet' | translate}}
              </span>
            </label>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  pt-4">

              <div class="handsontable overflow-scroll" style="max-height:500px">
                <cdk-virtual-scroll-viewport [itemSize]="500">
                  <table class="htCore w-100">
                    <thead>
                      <tr>
                        <th width="2%">
                          <div class="relative">
                            <span class="colHeader"></span>
                          </div>
                        </th>

                        <th width="10%">
                          <ng-template #categoryNameContent>
                            <div class="form-group">
                              <label>{{'Category Name' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'categoryName')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.Category && categoryNameContent" (click)="selectColumn('categoryName')">
                              {{'Category' | translate}}
                            </span>
                          </div>
                        </th>

                        <th width="10%">
                          <ng-template #alternateCategoryNameContent>
                            <div class="form-group">
                              <label>{{'Arabic Category Name' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'alternateCategoryName')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.AlternateCategory && alternateCategoryNameContent" (click)="selectColumn('alternateCategoryName')">
                              {{'Arabic Category' | translate}}
                            </span>
                          </div>
                        </th>

                        <th width="10%">
                          <ng-template #subcategoryNameContent>
                            <div class="form-group">
                              <label>{{'Subcategory Name' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'subcategoryName')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.SubCategory && subcategoryNameContent" (click)="selectColumn('subcategoryName')">
                              {{'Subcategory' | translate}}
                            </span>
                          </div>
                        </th>

                        <th width="12%">
                          <ng-template #alternateSubcategoryNameContent>
                            <div class="form-group">
                              <label>{{'Arabic Subcategory Name' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'alternateSubcategoryName')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.AlternateSubCategory && alternateSubcategoryNameContent" (click)="selectColumn('alternateSubcategoryName')">
                              {{'Arabic Subcategory' | translate}}
                            </span>
                          </div>
                        </th>
                        <th width="10%">
                          <ng-template #itemContent>
                            <div class="form-group">
                              <label>{{'Item Name' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'itemName')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.ItemName && itemContent" (click)="selectColumn('itemName')">{{'Item Name' | translate}}</span>
                          </div>
                        </th>

                        <th width="12%">
                          <ng-template #alternateItemNameContent>
                            <div class="form-group">
                              <label>{{'Arabic Item Name' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'alternateItemName')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.AlternateItemName && alternateItemNameContent" (click)="selectColumn('alternateItemName')">
                              {{'Arabic Item Name' | translate}}
                            </span>
                          </div>
                        </th>


                        <th width="10%">
                          <ng-template #descriptionContent>
                            <div class="form-group">
                              <label>{{'English Description' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'description')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.Description && descriptionContent" (click)="selectColumn('description')">
                              {{'English Description' | translate}}
                            </span>
                          </div>
                        </th>
                        <th width="10%">
                          <ng-template #alternateDescriptionContent>
                            <div class="form-group">
                              <label>{{'Arabic Description' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'alternateDescription')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.AlternateDescription && alternateDescriptionContent" (click)="selectColumn('alternateDescription')">
                              {{'Arabic Description' | translate}}
                            </span>
                          </div>
                        </th>
                        <th width="10%">
                          <ng-template #imageContent>
                            <div class="form-group">
                              <label>{{'Image' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'image')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.Image && imageContent" (click)="selectColumn('image')">
                              {{'Image' | translate}}
                            </span>
                          </div>
                        </th>
                        <th width="8%">
                          <ng-template #priceTypeContent>
                            <div class="form-group">
                              <label>{{'Price Type' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'priceType')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.PriceType && priceTypeContent" (click)="selectColumn('priceType')">
                              {{'Price Type' | translate}}
                            </span>
                          </div>
                        </th>
                        <th width="8%">
                          <ng-template #priceContent>
                            <div class="form-group">
                              <label>{{'Price' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'price')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.Price && priceContent" (click)="selectColumn('price')">
                              {{'Price' | translate}}
                            </span>
                          </div>
                        </th>
                        <th width="10%">
                          <ng-template #groupContent>
                            <div class="form-group">
                              <label>{{'Modifier Group' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'modifierGroup')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.GroupModifier && groupContent" (click)="selectColumn('modifierGrpup')">
                              {{'Group Modifier' | translate}}
                            </span>
                          </div>
                        </th>
                        <th width="6%">
                          <ng-template #statusContent>
                            <div class="form-group">
                              <label>{{'Status' | translate}} </label>
                              <input type="text" class="form-control" (keydown.enter)="ChangeAllValues($event,'status')" />
                            </div>
                          </ng-template>
                          <div class="relative">
                            <span class="colHeader" [autoClose]="'outside'" placement="top" container="body" [ngbPopover]="selectedColumn.Status && statusContent" (click)="selectColumn('status')">
                              {{'Status' | translate}}
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <ng-container *ngFor="let item of fileData; let i=index">
                        <tr ngbTooltip="{{item.HasDuplication ? 'Duplicate Record' : '' | translate}}">
                          <td class="text-center">{{i+1}}</td>
                          <td class="inputfield" [ngClass]="{'border-red':item.CategoryInvalid || item.HasDuplication,'selected-blue border-blue':selectedColumn.Category}"
                              container="body" ngbTooltip="{{item.CategoryInvalid ? 'Invalid or already exist' : '' | translate}}">
                            <div contenteditable="true" [textContent]="item.Category" (input)="item.Category = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.Category}">
                            </div>
                          </td>
                          <td class="inputfield" [ngClass]="{'border-red': item.AlternateCategoryInvalid || item.HasDuplication,'selected-blue border-blue':selectedColumn.AlternateCategory}"
                              container="body" ngbTooltip="{{item.AlternateCategoryInvalid ? 'Invalid or already exist' : '' | translate}}">
                            <div contenteditable="true" [textContent]="item.AlternateCategory" (input)="item.AlternateCategory = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.AlternateCategory}">
                            </div>
                          </td>
                          <td class="inputfield" [ngClass]="{'border-red':item.SubCategoryInvalid || item.HasDuplication,'selected-blue border-blue':selectedColumn.SubCategory}"
                              container="body" ngbTooltip="{{item.SubCategoryInvalid ? 'Invalid or already exist' : '' | translate}}">
                            <div contenteditable="true" [textContent]="item.SubCategory" (input)="item.SubCategory = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.SubCategory}">
                            </div>
                          </td>
                          <td class="inputfield" [ngClass]="{'border-red':item.AlternateSubCategoryInvalid || item.HasDuplication,'selected-blue border-blue':selectedColumn.AlternateSubCategory}"
                              container="body" ngbTooltip="{{item.AlternateSubCategoryInvalid ? 'Invalid or already exist' : '' | translate}}">
                            <div contenteditable="true" [textContent]="item.AlternateSubCategory" (input)="item.AlternateSubCategory = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.AlternateSubCategory}">
                            </div>
                          </td>
                          <td class="inputfield" [ngClass]="{'border-red':item.ItemNameInvalid || item.HasDuplication,'selected-blue border-blue':selectedColumn.ItemName}"
                              container="body" ngbTooltip="{{(item.ItemNameInvalid)?'Invalid or already exist':'' | translate}}">
                            <div contenteditable="true" [textContent]="item.ItemName" (input)="item.ItemName = $event.target.textContent; item.ItemNameExists = false" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.ItemName}">
                            </div>
                          </td>
                          <td class="inputfield" [ngClass]="{'border-red': (item.AlternateItemNameInvalid) || item.HasDuplication,'selected-blue border-blue':selectedColumn.AlternateItemName}"
                              container="body" ngbTooltip="{{(item.AlternateItemNameInvalid) ? 'Invalid or already exist':'' | translate}}">
                            <div contenteditable="true" [textContent]="item.AlternateItemName" (input)="item.AlternateItemName = $event.target.textContent; item.AlternateNameExists = false" [ngClass]="{'selected-blue':selectedColumn.AlternateItemName}">
                            </div>
                          </td>


                          <td class="inputfield" [ngClass]="{'selected-blue border-blue':selectedColumn.Description,'border-red': (item.DescriptionInvalid) || item.HasDuplication}"
                              container="body" ngbTooltip="{{(item.DescriptionInvalid) ? 'Invalid English Description':'' | translate}}">
                            <div contenteditable="true" [textContent]="item.Description" (input)="item.Description = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.Description}">
                            </div>
                          </td>
                          <td class="inputfield" [ngClass]="{'selected-blue border-blue':selectedColumn.AlternateDescription,'border-red': (item.AlternateDescriptionInvalid) || item.HasDuplication}"
                              container="body" ngbTooltip="{{(item.AlternateDescriptionInvalid) ? 'Invalid Arabic Description':'' | translate}}">
                            <div contenteditable="true" [textContent]="item.AlternateDescription" (input)="item.AlternateDescription = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.AlternateDescription}">
                            </div>
                          </td>

                          <td class="inputfield" [ngClass]="{'selected-blue border-blue':selectedColumn.Image,'border-red': item.HasDuplication || item.ImageInvalid }"
                              container="body" ngbTooltip="{{(item.ImageInvalid ) ? 'Invalid Image':'' | translate}}">
                            <div contenteditable="true" [textContent]="item.Image" (input)="item.Image = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.Image}">
                            </div>
                          </td>
                          <td class="inputfield" [ngClass]="{'border-red':item.PriceTypeInvalid || item.HasDuplication,'selected-blue border-blue':selectedColumn.PriceType}"
                              container="body" ngbTooltip="{{(item.PriceTypeInvalid) ? 'Invalid Price Type':'' | translate}}">
                            <div contenteditable="true" [textContent]="item.PriceType" (input)="item.PriceType = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.PriceType}">
                            </div>
                          </td>
                          <td class="inputfield" [ngClass]="{'border-red':item.PriceInvalid || item.HasDuplication,'selected-blue border-blue':selectedColumn.Price}"
                              container="body" ngbTooltip="{{(item.PriceInvalid) ? 'Invalid Price':'' | translate}}">
                            <div contenteditable="true" [textContent]="item.Price" (input)="item.Price = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.Price}">
                            </div>
                          </td>
                          <td class="inputfield" container="body" [ngClass]="{'border-red':item.ModifierInvalid || item.HasDuplication,'selected-blue border-blue':selectedColumn.GroupModifier}"
                              ngbTooltip="{{(item.ModifierInvalid && item.ModifierNotExistsName) ? '“'+item.ModifierNotExistsName+'” ':''}}{{(item.ModifierInvalid && item.ModifierNotExistsName) ? 'Invalid Modifier Group':'' | translate}}">
                            <div contenteditable="true" [textContent]="item.GroupModifier" (input)="item.GroupModifier = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.GroupModifier}">
                            </div>
                          </td>
                          <td class="inputfield" [ngClass]="{'border-red':item.StatusInvalid || item.HasDuplication,'selected-blue border-blue':selectedColumn.Status}"
                              container="body" ngbTooltip="{{(item.StatusInvalid) ? 'Invalid Status':'' | translate}}">
                            <div contenteditable="true" [textContent]="item.Status" (input)="item.Status = $event.target.textContent" class="tableInput" [ngClass]="{'selected-blue':selectedColumn.Status}">
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </cdk-virtual-scroll-viewport>
              </div>
            </div>
          </div>
        </div>

        <div class="step pt-5" *ngIf="formNumber ===5">
          <div class="row justify-content-md-center">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center">
              <ng-container *ngIf="sheetVerified ===false">
                <img src="assets/images/icons/black-shield-check.svg" height="50" />
                <h6 class="text-center fw-bold pt-2">{{'Verifying' | translate}}</h6>
                <div class="progress">
                  <div class="progress-bar bg-purple" [style.width]="currentPercentage + '%'" role="progressbar" aria-valuenow="{{currentPercentage}}" aria-valuemin="0" aria-valuemax="100">
                    {{currentPercentage}}%
                  </div>
                </div>
                <label class="pt-3">{{'Verifying the compatibility of the csv with Marn Standards' | translate}}.</label>
              </ng-container>
              <ng-container *ngIf="sheetVerified">
                <img src="assets/images/icons/green-shield-check.svg" height="50" />
                <h6 class="text-center fw-bold pt-2">{{'Verified' | translate}}</h6>
                <div class="progress">
                  <div class="progress-bar bg-success w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                    100%
                  </div>
                </div>
                <label class="pt-3">{{'Verifying the compatibility of the csv with Marn Standards' | translate}}.</label>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
