import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { LocationGroupService } from '../../../_services/location-group.service';
import { CouponService } from '../../../_services/coupon.service';
import { LocationService } from '../../../_services/location.service';

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrl: './add-coupon.component.css'
})
export class AddCouponComponent {
  @Input() SelectedID = null;
  title = 'New Coupon';
  submitted = false;
  couponForm: UntypedFormGroup;
  loading = false;
  invalidDateSelection: boolean = false
  arrItems: any[] = [];
  selectedItems = [];
  Items = [];
  selectedLocations = [];
  Locations: any;
  selectedLang = 'en';
  invalidDate: boolean = false;
  currentdate: any;
  appliedOnList = [
    { name: 'Orders', id: 1 },
    { name: 'Items', id: 2 }
  ];

  calculationByList = [
    { name: 'Percentage', id: 'P' },
    { name: 'Amount', id: 'A' }
  ];
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private location: LocationService,
    private service: CouponService
  ) {
    this.createForm();
    this.validateFields();
    this.selectedLang = this.localStorageService.getSelectedLanguage();
    const current = new Date();
    this.currentdate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.f.startDate.setValue({ day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() });
    this.f.expiryDate.setValue({ day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() });

    this.loadLocations();
  }
  private createForm() {
    //this.Location = DataService.Name;
    this.couponForm = this.formBuilder.group({
      couponID: [0],
      name: [''],
      couponCode: ['', Validators.pattern(/^[a-zA-Z0-9]{0,50}$/) ],
      quantity: [0, [Validators.required, Validators.min(1)]],
      appliedOn: [1],
      calculationBy: ['A'],
      amount: [0],
      available: [0],
      used: [0],
      rowVersion: [0],
      partner: [''],
      partnerLogo: [''],
      status: [''],
      createdBy: [''],
      priceCap: [0],
      startDate: [],
      expiryDate: [''],
      redeemItemQty: [0],
      statusID: [true, Validators.required],
      locations: [''],
      items: [''],
      couponItems: [[]],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID)
    });
  }
  get f() { return this.couponForm.controls; }
  ngOnInit() {
    this.setSelectedItem();
  }

  validateFields() {

    if (this.f.appliedOn.value === 1) {
      this.f.amount.setValidators([Validators.required, Validators.min(1)]);
      this.couponForm.get('amount').updateValueAndValidity();

      if (this.f.calculationBy.value === 'P') {
        this.f.priceCap.setValidators([Validators.required, Validators.min(1)]);
        this.couponForm.get('priceCap').updateValueAndValidity();
      }

      this.f.redeemItemQty.clearValidators();
      this.couponForm.get('redeemItemQty').updateValueAndValidity();
    }
    else {
      this.f.redeemItemQty.setValidators([Validators.required, Validators.min(1)]);
      this.couponForm.get('redeemItemQty').updateValueAndValidity();
      this.f.amount.clearValidators();
      this.f.priceCap.clearValidators();
      this.couponForm.get('amount').updateValueAndValidity();
      this.couponForm.get('priceCap').updateValueAndValidity();
    }
  }

  private loadLocations() {
    this.location.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
    });
  }

  setSelectedItem() {
    if (this.SelectedID) {

      this.loading = true;
      this.f.couponID.setValue(this.SelectedID);
      this.service.getById(this.SelectedID).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loading = false;
      });
    }
  }
  addRow(obj) {
    this.arrItems = obj;
  }

  deleteRow(obj) {

    const index = this.arrItems.indexOf(obj);
    this.arrItems.splice(index, 1);

    const index2 = this.selectedItems.indexOf(obj.itemID);
    this.selectedItems.splice(index2, 1);
    this.selectedItems = [...this.selectedItems];
  }


  loadItems() {
    this.validateFields();
    if (this.f.appliedOn.value === 2) {
      this.service.getAllItemsddl(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.Items = res;
      });
    }
  }

  private editForm(item) {

    this.f.name.setValue(item.name);
    this.f.couponID.setValue(item.couponID);
    this.f.couponCode.setValue(item.couponCode);
    this.title = item.couponCode;
    this.f.couponCode.disable();
    //this.f.quantity.disable();
    this.f.quantity.setValue(item.quantity);
    this.f.appliedOn.setValue(item.appliedOn);
    this.f.amount.setValue(item.amount);
    this.f.name.setValue(item.name);
    this.f.priceCap.setValue(item.priceCap);
    this.f.rowVersion.setValue(item.rowVersion);

    this.f.redeemItemQty.setValue(item.redeemItemQty);

    this.f.startDate.setValue({ day: new Date(item.startDate).getDate(), month: new Date(item.startDate).getMonth() + 1, year: new Date(item.startDate).getFullYear() });
    this.f.expiryDate.setValue({ day: new Date(item.expiryDate).getDate(), month: new Date(item.expiryDate).getMonth() + 1, year: new Date(item.expiryDate).getFullYear() });

    this.f.statusID.setValue(item.statusID === 1 ? true : false);

    this.f.calculationBy.setValue(item.calculationBy.toString());
    if (item.locations) {
      this.selectedLocations = item.locations.split(',').map(Number);
      //this.f.locations.setValue(this.selectedLocations);
    }
    this.loadItems();
    if (item.items) {
      this.selectedItems = item.items.split(',').map(Number);
    }
    if (item.couponItems && item.couponItems.length > 0) {
      this.arrItems = [];
      item.couponItems.forEach(e => {
        this.arrItems.push(e);
      });
    }
    this.validateFields();
    this.f.calculationBy.disable();
  }

  public onSelectAll(control) {
    if (control == 'location') {
      const _selectedLocations = this.Locations.map(item => item.locationID);
      //this.f.values.patchValue(_selectedLocations);
      this.selectedLocations = _selectedLocations;
    }

  }
  onSubmit() {
    this.couponForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    if (this.couponForm.invalid) {

      return;
    }
    if (this.selectedLocations)
      this.f.locations.setValue(this.selectedLocations.toString());

    if (this.f.appliedOn.value === 2) {
      if (this.selectedItems.toString() === '') {
        return;
      }
      this.f.items.setValue(this.selectedItems.toString());
    }


    let startDate = new Date(this.f.startDate.value.year + "/" + this.f.startDate.value.month + "/" + this.f.startDate.value.day + ", " + new Date().toTimeString());
    let expiryDate = new Date(this.f.expiryDate.value.year + "/" + this.f.expiryDate.value.month + "/" + this.f.expiryDate.value.day + ", " + new Date().toTimeString());

    if (startDate > expiryDate) {
      this.invalidDate = true;
      return;
    }

    this.f.startDate.setValue(startDate);
    this.f.expiryDate.setValue(expiryDate);
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.loading = true;


    if (this.f.couponID.value > 0) {
      this.service.update(this.couponForm.value).subscribe(data => {
        this.alertService.success("Coupon has been created");
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {

        if (this.f.statusID.value === 1)
          this.f.statusID.setValue(true);
        else
          this.f.statusID.setValue(false);

        let startDate = new Date(this.f.startDate.value);
        let expiryDate = new Date(this.f.expiryDate.value);


        this.f.startDate.setValue({ day: new Date(startDate).getDate(), month: new Date(startDate).getMonth() + 1, year: new Date(startDate).getFullYear() });
        this.f.expiryDate.setValue({ day: new Date(expiryDate).getDate(), month: new Date(expiryDate).getMonth() + 1, year: new Date(expiryDate).getFullYear() });


        
        this.alertService.error(error);
        this.loading = false;
      });
    }
    else {
      this.service.save(this.couponForm.value).subscribe(data => {
        this.alertService.success("Coupon has been updated");
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {

        if (this.f.statusID.value === 1)
          this.f.statusID.setValue(true);
        else
          this.f.statusID.setValue(false);

        let startDate = new Date(this.f.startDate.value);
        let expiryDate = new Date(this.f.expiryDate.value);


        this.f.startDate.setValue({ day: new Date(startDate).getDate(), month: new Date(startDate).getMonth() + 1, year: new Date(startDate).getFullYear() });
        this.f.expiryDate.setValue({ day: new Date(expiryDate).getDate(), month: new Date(expiryDate).getMonth() + 1, year: new Date(expiryDate).getFullYear() });


        this.alertService.error(error);
        this.loading = false;
      });
    }

  }
}
