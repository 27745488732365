import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/_services/country.service';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, MinValidator } from '@angular/forms';
import { CityService } from 'src/app/_services/city.service';
import { TimeZoneService } from 'src/app/_services/timezone.service';
import { LicenseService } from 'src/app/_services/license.service';
import { LocationService } from 'src/app/_services/location.service';
import { OrdertypeService } from 'src/app/_services/ordertype.service';
import { DecimalPipe } from '@angular/common';
import { first } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Ordertype } from '../../../_models/ordertype';
import { Partner } from '../../../_models/partner';
import { CommonService } from '../../../_services/common.service';
import * as myGlobals from '../../../_helpers/globals';
import { AddLocationCoordinatesComponent } from '../add-location-coordinates/add-location-coordinates.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationCoordinates } from '../../../_models/Location';
import { DevicesService } from '../../../_services/devices.service';
import { Devices } from '../../../_models/Devices';
import { LocationGroupService } from '../../../_services/location-group.service';
import { LocationGroup } from '../../../_models/LocationGroup';

@Component({
  selector: 'app-addlocation',
  templateUrl: './addlocation.component.html',
  styleUrls: ['./addlocation.component.css'],
  providers: [LocationService, DecimalPipe]
})
export class AddlocationComponent implements OnInit {
  Countries: any;
  Cities: any;
  TimeZones: any;
  Licenses: any;
  loading = false;
  loadingLocation = false;
  submitted = false;
  locationForm: UntypedFormGroup;
  ButtonText = "Create location";
  Locations: any;
  OrderType: any;
  Partners: Partner[];
  active = 'locationInfo';
  links = [
    { title: 'One', fragment: 'one' },
    { title: 'Two', fragment: 'two' }
  ];
  OrderTypeAllowed: any = [];
  selectedLocationGroupIds: any = [];
  LocationGroups: any = [];
  locationKey: string = null;
  statusValue: boolean = true;
  partnersAutoOrder: Partner[] = null;
  devices: Devices[] = null;
  checkZatcaCompliance: boolean = false;
  constructor(
    private countryService: CountryService,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private cityService: CityService,
    private timeZone: TimeZoneService,
    private license: LicenseService,
    private locationService: LocationService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private ordertypeService: OrdertypeService,
    public locationGroupservice: LocationGroupService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    public deviceService: DevicesService
  ) {
    this.createForm();
    this.loadCountries();
    this.loadCities();
    this.loadTimeZones();
    this.loadLicenses();
    this.loadPartners();
    this.loadLocationGroups();
  }


  ngOnInit() {


    this.setSelectedLocation();
    this.route.paramMap.subscribe(param => {
      if (param.get("type") === "item") {
        this.active = "locationItem";
      }
      else if (param.get("type") === "table") {
        this.active = "locationTable";
      }
      else if (param.get("type") === "inventory") {
        this.active = "locationInventory";
      }
    });

    this.loadOrderType();
    this.loadPartnersForAutoAccept();
    this.checkZatcaCompliance = this.localStorageService.getSelectedBrand().ZATCACompliance;

    //Get Parameters and set Update Values
    //this.route.params.subscribe(params => {
    // if (params['id'] !== undefined) {
    // if (DataService.Id !== 0) {
    //   this.ButtonText = "Update location";
    //   this.loadingLocation = true;
    //   this.f.LocationID.setValue(DataService.Id);
    //   this.locationService.getById(DataService.Id).subscribe(locationRes => {

    //     //Set Forms
    //     this.editForm(locationRes);
    //     this.loadingLocation = false;

    //     //Bind Location Dropdown
    //     this.loadLocations();
    //   });
    // }
    //});
  }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.localStorageService);
  }

  setSelectedLocation() {
    if (LocationService.locationId !== 0) {
      this.ButtonText = "Update location";
      this.loadingLocation = true;
      this.f.LocationID.setValue(LocationService.locationId);
      this.locationService.getById(LocationService.locationId).subscribe(locationRes => {

        //Set Forms
        this.editForm(locationRes);
        this.loadingLocation = false;

        //Bind Location Dropdown
        this.loadLocations();
      });
    }
  }

  private createForm() {
    this.locationForm = this.formBuilder.group({
      Name: ['', Validators.required],
      CountryID: ['SA', Validators.required],
      CityID: [4080, Validators.required],
      Address: [''], //Validators.pattern(/^[A-Za-z]{4}\d{4}$/)
      Descripiton: [''],
      Email: ['', Validators.email],
      ContactNo: [''],
      StatusID: [true, Validators.required],
      IsDelivery: [false, Validators.required],
      DeliveryServices: [false, Validators.required],
      MinOrderAmount: [0],
      DeliveryCharges: [0],
      DeliveryTime: [''],
      TimeZoneID: [54, Validators.required],
      LicenseID: [0, Validators.required],
      Open_Time: ['08:00', Validators.required],
      Close_Time: ['23:00', Validators.required],
      BrandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      UserID: parseInt(this.localStorageService.getSelectedUser().User.UserID),
      CompanyCode: this.localStorageService.getSelectedUser().CompanyDetails.CompanyCode,
      LastUpdatedBy: this.localStorageService.getSelectedUser().User.FirstName,
      LocationID: 0,
      Version: 0,
      OrderTypeIds: '',
      locationGroupIds: '',
      MarnNowPartnerID: 0,
      Latitude: [''],
      Longitude: [''],
      StreetName: ['', Validators.required],
      BuildingNumber: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
      AdditionalNumber: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
      District: ['', Validators.required],
      PostalCode: ['', [Validators.required, Validators.pattern(/^\d{5}$/)]],
      Coordinates: ['']
    });
  }

  private editForm(location) {
    this.f.Name.setValue(location.name);
    this.f.CountryID.setValue(location.countryID);
    this.f.CityID.setValue(parseInt(location.cityID));
    this.f.Address.setValue(location.address);
    this.f.Descripiton.setValue(location.descripiton);
    this.f.Email.setValue(location.email);
    this.f.ContactNo.setValue(location.contactNo);
    this.f.IsDelivery.setValue(location.isDelivery);
    this.f.DeliveryServices.setValue(location.isDelivery);
    this.f.MinOrderAmount.setValue(parseInt(location.minOrderAmount));
    this.f.DeliveryCharges.setValue(parseFloat(location.deliveryCharges));
    this.f.DeliveryTime.setValue(location.deliveryTime);
    this.f.TimeZoneID.setValue(parseInt(location.timeZoneID));
    this.f.LicenseID.setValue(parseInt(location.licenseID));
    this.f.Open_Time.setValue(location.open_Time);
    this.f.Close_Time.setValue(location.close_Time);
    this.f.StatusID.setValue(location.statusID === 1 ? true : false);
    this.statusValue = this.f.StatusID.value;
    this.f.Version.setValue(location.version);
    this.f.MarnNowPartnerID.setValue(location.marnNowPartnerID);
    this.f.Latitude.setValue(location.latitude);
    this.f.Longitude.setValue(location.longitude);
    if (this.f.Latitude.value && this.f.Latitude.value !== '')
      this.f.Coordinates.setValue(this.f.Latitude.value + ',' + this.f.Longitude.value);

    this.f.StreetName.setValue(location.streetName);
    this.f.BuildingNumber.setValue(location.buildingNumber);
    this.f.District.setValue(location.district);
    this.f.PostalCode.setValue(location.postalCode);
    this.f.AdditionalNumber.setValue(location.additionalNumber);
    this.locationKey = location.locationKey;
    if (location.locationGroupIds)
      this.selectedLocationGroupIds = location.locationGroupIds.split(',').map(entry => { return Number(entry)  }); 
    this.loadDevices();

  }

  private loadLocations() {
    this.locationService.getAll(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      this.Locations = res;
      this.updateLocalStorageLocations();
    });

  }


  private loadCountries() {
    this.countryService.getCountries().subscribe((res: any) => {
      this.Countries = res;
    });
  }

  private loadOrderType() {
    this.ordertypeService.getAllowedOrderTypesForLocation(this.localStorageService.getSelectedBrand().BrandID, this.f.LocationID.value).subscribe((res: Ordertype[]) => {
      this.OrderType = res;

      const arrOTIds = res.filter(function (ot) { return ot.allowed === true }).map(item => item.orderTypeID)
      this.OrderTypeAllowed = arrOTIds;
    });
  }

  private loadLocationGroups() {
    this.locationGroupservice.getlocationGroupsDDL(this.localStorageService.getSelectedBrand().BrandID).subscribe((res: LocationGroup[]) => {
      res=res.map((p) =>
        p.statusID ===1 ? p : { ...p, disabled: true }
      );
      this.LocationGroups = res;
    });
  }
  private loadPartnersForAutoAccept() {
    this.locationService.getPartners(this.localStorageService.getSelectedBrand().BrandID, this.f.LocationID.value).subscribe(res => {
      this.partnersAutoOrder = res;
    });
  }
  private loadDevices() {
    this.deviceService.getLocationDevicesDDL(this.f.LocationID.value).subscribe(res => {
      this.devices = res;
    });
  }

  private loadPartners() {
    this.commonService.getMarnNowPartners(this.localStorageService.getSelectedBrand().BrandID).subscribe((res: Partner[]) => {
      this.Partners = res;
    });
  }

  private loadCities() {
    this.cityService.getCities(this.f.CountryID.value).subscribe((res: any) => {
      this.Cities = res;
    });
  }

  private loadTimeZones() {
    this.timeZone.getTimeZones().subscribe((res: any) => {
      this.TimeZones = res;
    });

  }

  private loadLicenses() {
    this.license.getLicenses("LC").subscribe((res: any) => {
      this.Licenses = res;
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.locationForm.controls; }

  onSubmit() {
    console.log(this.OrderType);
    this.locationForm.markAllAsTouched();
    if (this.Licenses.length > 0 && parseInt(this.f.LicenseID.value) === 0) {
      this.alertService.error("Please Select License");
      return false
    }
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    // stop here if form is invalid
    if (this.locationForm.invalid) { return; }

    this.f.LicenseID.setValue(parseInt(this.f.LicenseID.value));

    this.f.StatusID.setValue(this.f.StatusID.value === true ? 1 : 2);

    // const arrOTIds = this.OrderType.filter(function (ot) { return ot.allowed === true }).map(item => item.orderTypeID)
    // const oTIds = arrOTIds.join(',')

    const oTIds = this.OrderTypeAllowed.join(',')
    this.f.OrderTypeIds.setValue(oTIds);


    this.f.OrderTypeIds.setValue(oTIds);

    this.f.locationGroupIds.setValue(this.selectedLocationGroupIds.join(','));

    if (this.f.MarnNowPartnerID.value !== 0)
      this.f.MarnNowPartnerID.setValue(parseInt(this.f.MarnNowPartnerID.value));
    else
      this.f.MarnNowPartnerID.setValue(null);


    this.loading = true;
    if (parseInt(this.f.LocationID.value) === 0) {

      //Insert Location
      this.locationService.insert(this.locationForm.value).subscribe((res: any) => {
        LocationService.locationId = res.locationid;
        let partnerAutoOrderError: boolean = false;
        this.partnersAutoOrder.forEach(e => {
          e.brandID = this.f.BrandID.value;
          e.locationID = LocationService.locationId;
          if (e.status == true) {
            e.statusID = 1;
          }
          else {
            e.statusID = 2;
          }

          if (e.status == true && !e.terminalID && e.terminalID === 0) {
            partnerAutoOrderError = true;
          }

        });
        if (partnerAutoOrderError === false) {
          this.locationService.updatePartner(this.partnersAutoOrder).subscribe((res: any) => { }, error => { this.alertService.error(error); });
          this.alertService.success("Location has been created");
        }
        else {
          this.alertService.error("Partner Order failed to save.");
        }

        this.loading = false;
        this.loadLocations();
        this.locationForm.reset();
        //this.setSelectedLocation();
        this.OrderTypeAllowed = [];

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update Location
      this.locationService.update(this.locationForm.value).subscribe(data => {
        let partnerAutoOrderError: boolean = false;
        this.partnersAutoOrder.forEach(e => {
          e.brandID = this.f.BrandID.value;
          e.locationID = this.f.LocationID.value;
          if (e.status == true) {
            e.statusID = 1;
          }
          else {
            e.statusID = 2;
          }
          if (e.status == true && !e.terminalID && e.terminalID === 0) {
            partnerAutoOrderError = true;
          }
        });
        if (partnerAutoOrderError === false) {
          this.locationService.updatePartner(this.partnersAutoOrder).subscribe((res: any) => { }, error => { this.alertService.error(error); });
          this.alertService.success("Location has been Updated");
        }
        else {
          this.alertService.error("Partner Order failed to save.");
        }

        this.loading = false;
        this.loadLocations();
        this.setSelectedLocation();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
  }

  changeLocation() {
    this.locationService.setLocationId(this.f.LocationID.value);
    this.setSelectedLocation();
  }
  updateLocalStorageLocations() {
    var allLocations = [];
    this.Locations.filter(function (location) {
      var data =
      {
        LocationID: location.locationID,
        Name: location.name,
        OpeningTime: location.open_Time,
        ClosingTime: location.close_Time,
        Selected: false
      };
      allLocations.push(data);
      return true;
    });
    var user = this.localStorageService.getUser();
    var userbrand = JSON.parse(user.data);
    userbrand.Brands.filter(brand => brand["BrandID"] === parseInt(this.localStorageService.getSelectedBrand().BrandID))[0].Locations = allLocations;
    user.data = JSON.stringify(userbrand);
    this.localStorageService.setSelectedUser(user);

    var brand = this.localStorageService.getSelectedBrand();
    var userbrand = brand.Locations;
    brand.Locations = allLocations;
    this.localStorageService.setSelectedBrand(brand);
  }
  openMap() {
    let coordinate: LocationCoordinates = {
      locationName: this.f.Name.value,
      description: this.f.Address.value,
      position: {
        lat: +this.f.Latitude.value,
        lng: +this.f.Longitude.value
      }
    };
    const modalRef = this.modalService.open(AddLocationCoordinatesComponent, { size: 'lg', windowClass: 'small-modal' });
    modalRef.componentInstance.location = coordinate;
    modalRef.result.then((result) => {
      if (result) {
        this.f.Address.setValue(result.description);
        this.f.Latitude.setValue((result.latitude) ? result.latitude.toString() : '');
        this.f.Longitude.setValue((result.longitude) ? result.longitude.toString() : '');
        if (this.f.Latitude.value && this.f.Latitude.value !== '')
          this.f.Coordinates.setValue(this.f.Latitude.value + ',' + this.f.Longitude.value);
      }

    }, (reason) => {

    });
  }
}
