import { Injectable } from '@angular/core';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { LocationGroup } from '../_models/LocationGroup';
import { State } from '../_models/State';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap, map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
interface SearchResult {
  data: LocationGroup[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(locations: LocationGroup[], column: SortColumn, direction: string): LocationGroup[] {
  if (direction === '' || column === '') {
    return locations;
  } else {
    return [...locations].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}



//@@@ Header Filter Code Start
interface FilterHeaderState {
  status: string[]
}


function matcheHeader(data: LocationGroup, filterValues: FilterHeaderState) {
  let status = false;

  if (filterValues.status !== null && filterValues.status.length > 0) {
    for (var i = 0, len = filterValues.status.length; i < len; i++) {
      if (data.statusID.toString().includes(filterValues.status[i].toString())) {
        status = true;
      }
    }
  }
  else {
    status = true;
  }

  if (status) {
    return data;
  }

}
@Injectable({
  providedIn: 'root'
})
export class LocationGroupService {
  
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _locationGroups$ = new BehaviorSubject<LocationGroup[]>([]);
  private _allData$ = new BehaviorSubject<LocationGroup[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  public locationGroups: LocationGroup[];

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
  }
  
  get allData$() {
    return this._allData$.asObservable();
  }

  get locationGroups$() {
    return this._locationGroups$.asObservable();
  }

  getlocationGroupsDDL(brandId) {
    return this.http.get<LocationGroup[]>(`api/locationGroup/all/${brandId}`);
  }

  getlocationGroups(brandId) {
    //this._locations$.next(null);
    let url = `api/locationGroup/all/${brandId}`;
     

    this.http.get<any>(url).subscribe(res => {
      

      this.locationGroups = res;
      this._locationGroups$.next(this.locationGroups);
      this._allData$.next(this.locationGroups);

     

      //@@@ Header Filter Code Start
      this._search$.pipe(
        switchMap(() => this._searchByHeader()),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {

        this._locationGroups$.next(result.data);
        this._total$.next(result.total);
      });


      this._searchHeader$.pipe(
        switchMap(() => this._searchByHeader()),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {

        this._locationGroups$.next(result.data);
        this._total$.next(result.total);
      });

      this._search$.next();
      this._searchHeader$.next();

      //@@@ Header Filter Code End

    });
  }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {
    status: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.locationGroups, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => this.matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._locationGroups$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  matches(location: LocationGroup, term: string) {

  return location.name.toLowerCase().includes(term.toLowerCase());

}

  clear() {
    this._locationGroups$.next(null);
    this._searchHeader$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.locationGroups, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => this.matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }
  
  save(data) {
    return this.http.post(`api/locationgroup`, data)
      .pipe(map(res => {
        return res;
      }));
  }
  updateBulkStatus(ids, statusID) {
    let data = { ids: ids, statusID: statusID };
    return this.http.put(`api/locationgroup/updateBulkStatus`, data)
      .pipe(map(res => {
        return res;
      }));

  }
  getAllLocationsddl(brandId) {
    return this.http.get(`api/location/all/${brandId}`);
  }
  
}
