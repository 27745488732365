import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../../_services/location.service';
import { Observable, Subscription } from 'rxjs';
import { AlertService } from '../../../_services/alert.service';
import { SubUser } from '../../../_models/SubUser';
import { SubUserService } from '../../../_services/sub-user.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { RoleService } from '../../../_services/role.service';
import { Role } from '../../../_models/Role';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  addUserForm: UntypedFormGroup;
  UserButtonText = "Save";
  LocationsList = [];
  data$: SubUser[];
  submitted = false;
  loading = false;
  total$: Observable<number>;
  selectedLocationIds = [];
  selectedGroupIds = [];
  selectedRoles: Role[];
  colorOptions: string[];
  passcodeEnabled = false;
  private locationSubscription: Subscription;
  constructor(private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    public location: LocationService,
    public subUserService: SubUserService,
    private alertService: AlertService,
    private local: LocalStorageService,
    private roleService: RoleService) {

    this.locationSubscription = this.location.locationIdChange.subscribe((id) => {
      if (id) {

      }
    });
  }

  ngOnInit() {
    this.createForm();
    this.loadLocations();
    this.loadRoles();

    this.colorOptions = ["blue", "orange", "red", "green", "grey", "light-green"];
  }
  close() {
    this.activeModal.dismiss();
  }

  get f() { return this.addUserForm.controls; }
  private createForm() {

    this.addUserForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: '',
      groupIDs: ['', Validators.required],
      groupName: '',
      passcode: 0,
      subUserID: 0,
      statusID: [true, Validators.required],
      password: '',
      email: '',
      contactNo: [''],
      cssClass: "",
      userType: ['', Validators.required],
      rowVersion: 0,
      locations: [],
      brandID: 0
    });

  }

  validateUser(object) {

    let type = Array.from(new Set(object.map(e => e.type))).join(',');
    if (type.includes('Dashboard')) {
      this.f.email.setValidators([Validators.required, Validators.email]);
      this.f.password.setValidators([Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d~`!@#$%^&*()_+=[\]\{}|;:",.\/<>?]{8,}$/)]);
      this.addUserForm.get('email').updateValueAndValidity();
      this.addUserForm.get('password').updateValueAndValidity();

    }
    else {
      this.f.email.clearValidators();
      this.f.password.clearValidators();
      this.addUserForm.get('email').updateValueAndValidity();
      this.addUserForm.get('password').updateValueAndValidity();
    }
    this.f.userType.setValue(type);
    this.selectedRoles = this.selectedRoles.map(e => ({
      ...e, disabled: (
        type.split(',').includes(e.type)
      ) ? true : false
    }));;
  }

  loadRoles() {
    this.loading = true;
    this.roleService.getAll();
    this.roleService.allData$.subscribe(e => {
      this.loading = false;
      this.selectedRoles = e;
      this.getData();
    });
  }

  loadLocations() {
    this.loading = true;
    this.location.getAll(this.local.getSelectedBrand().BrandID).subscribe((res: any) => {
      this.loading = false;
      this.LocationsList = res;
    });
  }

  getData() {
    if (this.SelectedID !== 0) {
      this.loading = true;
      this.UserButtonText = "Update";
      const brandID = this.local.getSelectedBrand().BrandID;
      this.subUserService.get(this.SelectedID, brandID).subscribe(res => {
        //Set
        this.editForm(res);
        this.loading = false;
      });
    }

  }
  editForm(subUser) {
    this.f.firstName.setValue(subUser.firstName + subUser.lastName);
    this.f.statusID.setValue(subUser.statusID === 1 ? true : false);
    this.f.password.setValue(subUser.password);
    this.f.email.setValue(subUser.email);
    this.f.contactNo.setValue(subUser.contactNo);
    this.f.cssClass.setValue(subUser.cssClass);
    this.f.userType.setValue(subUser.userType);
    this.f.rowVersion.setValue(subUser.rowVersion);
    this.f.subUserID.setValue(subUser.subUserID);
    //this.f.groupID.setValue(subUser.groupID);
    this.f.passcode.setValue(subUser.passcode);

    if (subUser.userType.split(',').includes('Dashboard') || subUser.userType.split(',').includes('Admin'))
      this.f.email.disable();
    this.f.passcode.disable();

    //this.selectedRoles = this.selectedRoles.filter(function (obj) { return obj.type === subUser.userType; })
    if (subUser.locations !== "") {
      const stringToConvert = subUser.locations;

      this.selectedLocationIds = stringToConvert.split(',').map(Number);
      this.f.locations.setValue(this.selectedLocationIds);
    }
    if (subUser.groupIDs) {

      this.selectedGroupIds = subUser.groupIDs.split(',').map(Number);
      this.f.groupIDs.setValue(this.selectedGroupIds);
    }
    else {
      this.selectedGroupIds = [subUser.groupID];
      this.f.groupIDs.setValue(subUser.groupID.toString());
    }
    this.selectedRoles = this.selectedRoles.map(e => ({
      ...e, disabled: (
        subUser.userType.split(',').includes(e.type)
      ) ? true : false
    }));;
  }
  onSubmit() {

    this.f.groupIDs.setValue(this.selectedGroupIds === undefined ? "" : this.selectedGroupIds.toString());

    this.alertService.clear();
    this.addUserForm.markAllAsTouched();
    this.submitted = true;
    if (this.addUserForm.invalid) { return; }
    this.loading = true;
    this.f.brandID.setValue(this.local.getSelectedBrand().BrandID);

    this.f.locations.setValue(this.selectedLocationIds === undefined ? "" : this.selectedLocationIds.toString());



    const statusValue = (this.f.statusID.value === true ? 1 : 2);
    this.f.statusID.setValue(statusValue);
    this.f.cssClass.setValue("");

    //console.log(this.f);

    if (this.f.subUserID.value === 0) {
      this.subUserService.insert(this.addUserForm.value).subscribe(() => {
        this.alertService.success("User has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
    else {
      this.subUserService.update(this.addUserForm.value).subscribe(() => {
        this.alertService.success("User has been updated");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
    if (statusValue === 1) {
      this.f.statusID.setValue(true);
    }
    else {
      this.f.statusID.setValue(false);
    }
  }
  closeSelect(select: NgSelectComponent) { select.close(); }
  public onSelectAll() {
    const _selectedLocations = this.LocationsList.map(item => item.locationID);
    this.selectedLocationIds = _selectedLocations;
    //this.f.locations.setValue(this.selectedLocationIds);
    //this.addUserForm.get('locations').patchValue(_selectedLocations);
  }

  ngOnDestroy() {
    this.subUserService.clear();
    this.alertService.clear();
    this.locationSubscription.unsubscribe();
  }
}
