  <div ngbDropdown class="nav-item dropdown">
    <a ngbDropdownToggle class="dropdown-toggle selected-location location-dd form-control text-center" [ngbPopover]="popLocContent" [placement]="['bottom-start']"
       data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
      <span>{{SelectedLocation.Name | translate}}</span>
      <img class="down-aero" style="margin-left: 15px; margin-right: 10px;" src="assets/images/down-aero.svg">
    </a>
    <ng-template #popLocContent style="margin-left:-73px" >
      <p class="dropdown-heading-mini ">{{'CURRENT LOCATION' | translate}}</p>
      <a class="dropdown-item ">
        <img style="margin-left: 0 !important; " src="/assets/images/brand-logo.png " class="img-circle wh20 m-1 location-img" />
        {{SelectedLocation.Name | translate}}

      </a>
      <div class="inner-content ">
        <p class="dropdown-heading-mini ">{{'SWITCH TO' | translate}}</p>
        <a class="dropdown-item " (click)="changeLocation(location) " *ngFor="let location of Locations " [hidden]="location.Name == SelectedLocation.Name">{{location.Name}}</a>
      </div>
    </ng-template>
</div>
