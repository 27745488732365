<form [formGroup]="taxsetupForm" class="form taxesForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Tax' :SelectedName) | translate }} </h4>


    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" *hasPermission="['Setup','Taxes', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText  | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4" style="margin-top: 40px;">
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Tax Name">{{'Tax Name' | translate }}</label>
              <input type="text" class="form-control" id="name" placeholder="" formControlName="name">
              <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                <div *ngIf="f.name.errors.required">
                  {{'Name is required' | translate }} .
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Name ar">{{'Arabic Name' | translate }}</label>
              <input type="text" class="form-control" id="arabicName" placeholder="" formControlName="arabicName" pattern="^[\u0621-\u064A ]+$">
              <div *ngIf="f.arabicName.invalid && (f.arabicName.dirty || f.arabicName.touched)" class="alert alert-danger">
                <div *ngIf="f.arabicName.errors.required">
                  {{'Name (AR) is required' | translate }} .
                </div>
                <div *ngIf="f.arabicName.invalid">{{'Only Arabic characters are allowed' | translate }}. </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Price">{{'Percentage' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" id=percentage formControlName="percentage">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2"> &nbsp;&nbsp; {{'%' | translate }}&nbsp;&nbsp;</span>
                </div>
              </div>
              <div *ngIf="f.percentage.invalid && (f.percentage.dirty || f.percentage.touched)" class="alert alert-danger">
                <div *ngIf="f.percentage.errors.required">
                  {{'Percentage is required' | translate }}.
                </div>
                <div *ngIf="f.percentage.invalid">{{'Percentage must be 0-100' | translate }}. </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Calculation">{{'Applicable On' | translate }}</label>
              <select class="custom-select form-control custom-select-2" formControlName="applicableOn">
                <option [ngValue]="option.id" selected="option.id == applicableOn" *ngFor="let option of ApplicableOn">
                  {{option.name | translate}}
                </option>
              </select>
              <div *ngIf="f.applicableOn.invalid && (f.applicableOn.dirty || f.applicableOn.touched)" class="alert alert-danger">
                <div *ngIf="f.applicableOn.errors.required">
                  {{'Applicable On is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Calculation">{{'Calculate  By' | translate }}</label>
              <select class="custom-select form-control" formControlName="calculationBy">
                <option [ngValue]="option.id" selected="option.id == calculationBy" *ngFor="let option of CalculatedOn">
                  {{option.name | translate}}
                </option>
              </select>
              <div *ngIf="f.calculationBy.invalid && (f.calculationBy.dirty || f.calculationBy.touched)" class="alert alert-danger">
                <div *ngIf="f.calculationBy.errors.required">
                  {{'Calculate By is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <!--<div class="row">
    <div class="col-lg-12 form-group ">
      <label for="Calculation">{{'Tax Ref' | translate }}</label>
      <select class="custom-select form-control" formControlName="taxRef">
        <option [ngValue]="option.id" selected="option.id == taxRef" *ngFor="let option of taxRefs">
          {{option.name | translate}}
        </option>
      </select>
      <div *ngIf="f.taxRef.invalid && (f.taxRef.dirty || f.taxRef.touched)" class="alert alert-danger">
        <div *ngIf="f.taxRef.errors.required">
          {{'Tax Ref is required' | translate }}.
        </div>
      </div>
    </div>
  </div>-->

        <div class="tiltle-bar form-group row mx-0">
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
            <label for="applyToAllLocations" class="label-bold">
              {{'Apply to all locations' | translate }}
            </label>
            <p class="font-12">{{'Make the tax applicable on all existing or future locations' | translate }}</p>
          </div>
          <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 ">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" id="applyToAllLocations" formControlName="applyToAllLocations" (change)="applyToAllLocations()">
              <label class="form-check-label" for="applyToAllLocations"></label>
            </div>
          </div>
        </div>

          <div class="row" *ngIf="f.applicableOn.value == 'Locations' && !f.applyToAllLocations.value">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Select Locations' | translate }}</label>
              <ng-select [items]="Locations" bindLabel="name" class="custom" bindValue="locationID" [multiple]="true"
                         placeholder="" [ngModelOptions]="{standalone: true}" formControlName="location" [(ngModel)]="selectedLocations">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll()">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #1f2532;"
                          (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
              <div class="alert alert-danger" *ngIf="!selectedLocations || selectedLocations.length === 0">
                {{'Location is required' | translate }}.
              </div>
            </div>
          </div>

          <ng-container *ngIf="f.applicableOn.value == 'Items'">
            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <label for="Price">{{'Minimum tax amount per unit' | translate }}</label>
                <div class="input-group">
                  <input type="number" class="form-control" id=minTax formControlName="minTax">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2"> &nbsp;&nbsp; {{'SAR' | translate }}&nbsp;&nbsp;</span>
                  </div>
                </div>
                <div *ngIf="f.minTax.invalid && (f.minTax.dirty || f.minTax.touched)" class="alert alert-danger">
                  <div *ngIf="f.minTax.errors.required">
                    {{'Min tax is required' | translate }}.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <label for="Conversion">{{'Add Items' | translate }}</label>
              <div class="input-group form-group">
                <div class="input-group-append w-100">
                  <ng-select [items]="Items" #select [closeOnSelect]="true" [multiple]="true"
                             class="po-box-autocomplete border-right-radius-unset " placeholder="{{'Click here to add Item' | translate }}"
                             [bindLabel]="(selectedLang == 'en' ? 'name' : 'nameOnReceipt')"
                             bindValue="itemID" (add)="addRow($event)" [(ngModel)]="dropdownSelectedItems"
                             (remove)="deleteRow($event)"
                             (clear)="onDeSelectAllItem(select)"
                             [ngModelOptions]="{standalone: true}" [searchFn]="SearchItem">
                    <ng-template ng-header-tmp>
                      <div (click)="closeItemSelect(select)" *ngIf="isSelectAll === false">
                        <button class="dropdown-item"
                                (click)="onSelectAllItem(select)">
                          {{'Select All' | translate }}
                        </button>
                      </div>

                      <!--<div (click)="closeItemSelect(select)" *ngIf="isSelectAll === true">
                <button class="dropdown-item"
                        (click)="onDeSelectAllItem(select)">
                  {{'Deselect All' | translate }}
                </button>
              </div>-->
                    </ng-template>
                    <ng-template ng-multi-label-tmp>
                      <span style="display:block" class="ng-placeholder">Select items</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <section class="tile" style="border:none;box-shadow:none">
              <div class="p-0 table-responsive ">
                <table class="table table-striped po-item">
                  <thead>
                    <tr class="table-header">
                      <th scope="col" width="5%"><img src="/assets/images/icons/v2/camera.svg" /></th>
                      <th scope="col" width="40%" class="border-radius-topleft-10">{{'Item Name' | translate }}</th>
                      <th scope="col" class="border-start-width-0 border-end-width-0">{{'Price' | translate }}</th>
                      <th scope="col" class="border-end-width-0">{{'Item Tax' | translate }}</th>
                      <th scope="col" width="5%" class="border-radius-topright-10 border-start-width-0 border-end-width-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of selectedItems">
                      <td>
                        <img [src]="item.itemImage" *ngIf="(item.itemImage !== '' && item.itemImage !== null)" class="table-img" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                        <img *ngIf="(item.itemImage === '' || item.itemImage === null)" src="https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG" class="table-img" alt="" />
                      </td>
                      <td scope="row" class="font-weight-bold">{{(selectedLang == 'en' ? item.name : ((item.nameOnReceipt !== '')? item.nameOnReceipt:item.name))}}</td>
                      <td>
                        <span>{{item.price | number : '1.2-2'}}</span>
                      </td>

                      <td class="name-blue">
                        <span>{{((item.price * (f.percentage.value/100)) >= f.minTax.value ? (item.price * (f.percentage.value/100)): f.minTax.value ) | number : '1.2-2'}}</span>
                      </td>
                      <td>
                        <a><img src="/assets/images/icons/v2/Delete.svg" (click)="deleteRow(item)" /></a>
                      </td>
                    </tr>
                    <tr *ngIf="selectedItems.length === 0">
                      <td colspan="10">
                        <div class="row p-1">
                          <div class="col-12 text-center">
                            <h6 class="pt-3 pb-3">{{'No Items!' | translate }}</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </ng-container>
          <div class="form-group" [hidden]=true>
            <div class="row high-safety-mode">
              <div class="col-8">
                <label for="highSafety" class="label-bold">
                  <svg style="margin-left:10px" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 13V15H11V13H9ZM9 5V11H11V5H9Z" fill="#545F75" />
                  </svg>
                  {{'This tax is included on Items Prices' | translate }}
                </label>
              </div>
              <div class="col-4 text-right">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="includedItemPrice" formControlName="includedItemPrice">
                  <label class="form-check-label" for="includedItemPrice"></label>
                </div>
              </div>
              <div class="form-group col-lg-7 col-sm-12 " style=" margin-top: -13px;">
                <label style=" margin-left: 31px; font-size: 12px !important; color: #545F75;">
                </label>
              </div>
            </div>
          </div>

          <!--<div class="tiltle-bar form-group">
    <div class="row">
      <div class="col-8">
        <label for="includedItemPrice" style="margin-bottom: 0; line-height: 29px; ">{{'This tax is included on Items Prices' | translate }}</label>
      </div>
      <div class="col-4 text-end px-1">
        <div class="form-check form-switch">
          <input type="checkbox" class="form-check-input" id="includedItemPrice" formControlName="includedItemPrice">
          <label class="form-check-label" for="includedItemPrice"></label>
        </div>
      </div>
    </div>
  </div>-->
          <!--<div class="row">
    <div class="col-lg-12 form-group ">
      <label for="Group Status">{{'Status' | translate }} </label>
      <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input" checked id="statusID"
               formControlName="statusID">
        <label class="form-check-label" for="statusID"></label>
      </div>
    </div>
  </div>-->


          <div class="row">
            <div class="form-group  col-lg-12">
              <div class="status-activebar">
                <label for="Status">

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{'Tax Status.' | translate }}
                </label>

                <div class="form-check form-switch">
                  <span class="inactive" (click)="f.statusID.setValue(false)" [className]="f.statusID.value==false ? 'active' : 'inactive'" role="button"> {{'Inactive' | translate}}</span>
                  <span class="inactive active" (click)="f.statusID.setValue(true)" [className]="f.statusID.value==true ? 'active' : 'inactive'" role="button">{{'Active' | translate}}</span>
                </div>

              </div>
            </div>
          </div>



        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel cutom-button" style="color: unset; width: unset;min-width: unset;" (click)="activeModal.close('Close click')">{{'Cancel' | translate }}</button>
    <button [disabled]="loading" style="float: right; " class="btn btn-primary cutom-button">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> {{ButtonText | translate }}
    </button>
  </div>
</form>
