<form [formGroup]="itemForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L20.7133 20.7133" stroke="black" stroke-width="2" stroke-linecap="round" />
          <path d="M20.7129 1L0.999611 20.7133" stroke="black" stroke-width="2" stroke-linecap="round" />
        </svg>
      </span>
    </button>
    <h4 class="modal-title form-copy">
      {{ ((SelectedItemName === '') ? 'New Item' :SelectedItemName) | translate}}

      @if(f.itemID.value && f.itemID.value>0){
      <img src="../../../assets/images/Info.svg" [placement]="['bottom']" [ngbPopover]="itemID" role="button" />

      <ng-template #itemID>
        <span class="d-inline-flex">
          {{ 'Item ID' | translate}}: {{f.itemID.value}}
          <img src="assets/images/white-copy.svg" class="mt-0 code-copy mx-2" (click)="copy()" role="button">
        </span>
      </ng-template>
      }
    </h4>
    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" *hasPermission="['Products','Items', (SelectedID > 0) ? 'Edit' : 'New']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12 mb-0">
              <div class="row">
                <div class="form-group col-lg-7 col-sm-7 mb-0">
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="name">{{'Name in English' | translate }}</label>
                      <input type="text" class="form-control" id=name placeholder="" formControlName="name">
                      <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                        <div *ngIf="f.name.errors.required">
                          {{'Name is required' | translate }}.
                        </div>
                        {{'Invalid input. Length must be 3 or more' | translate }}.
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="nameOnReceipt">{{'Name in Arabic' | translate }}</label>
                      <input type="text" class="form-control" id=nameOnReceipt placeholder="" formControlName="nameOnReceipt">
                      <div *ngIf="f.nameOnReceipt.invalid && (f.nameOnReceipt.dirty || f.nameOnReceipt.touched)" class="alert alert-danger">
                        <div *ngIf="f.nameOnReceipt.errors.required">
                          {{'Arabic Name is required' | translate }}.
                        </div>
                        {{'Invalid input. Length must be 3 or more' | translate }}.
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="Country">{{'Subcategory' | translate }}</label>

                      <ng-select [items]="SubCateogries" [bindLabel]="(selctedLang == 'en' ? 'name' : 'alternateName')"
                                 bindValue="subCategoryID"
                                 [groupBy]="(selctedLang == 'en' ? 'parent' : 'parentAlternative')"
                                 (change)="selectedSubCategory(f.subCatID,$event)" formControlName="subCatID">
                      </ng-select>
                      <div *ngIf="f.subCatID.invalid && (f.subCatID.dirty || f.subCatID.touched)" class="alert alert-danger">
                        <div *ngIf="f.subCatID.errors.required">
                          {{'Select subcategory is required' | translate }}.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-lg-5 col-sm-5 mb-0">
                  <div class="row">

                    <div class="form-group col-lg-12 col-sm-12">
                      <app-imageupload></app-imageupload>
                    </div>

                  </div>
                  <div class="row">
                    <div class="form-group col-lg-8">
                      <label for="Item Status" class="display-block">{{'Item Status' | translate }}</label>
                      <div class="form-check form-switch float-clear">
                        <input type="checkbox" class="form-check-input" id="statusID" formControlName="statusID" checked>
                        <label class="form-check-label" for="statusID"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-lg-12 col-sm-12">

              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="Description">{{'English Description' | translate }}</label>
                  <textarea class="form-control h-75 mh-80" formControlName="description"></textarea>

                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="Description">{{'Arabic Description' | translate }}</label>
                  <textarea class="form-control h-75 mh-80" formControlName="alternateDescription"></textarea>

                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label>{{'Price Type' | translate }}</label>
                  <select class="custom-select form-control custom-select-2" formControlName="priceType" (change)="changePriceType()">
                    <option [ngValue]="option.id"
                            selected="option.id === id" *ngFor="let option of PriceTypes">
                      {{option.name | translate}}
                    </option>
                  </select>

                  <div *ngIf="f.priceType.invalid && (f.priceType.dirty || f.priceType.touched)" class="alert alert-danger">
                    <div *ngIf="f.priceType.errors.required">
                      {{'Price type is required' | translate }}.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" [hidden]="f.priceType.value ==2">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="Price">{{'Price' | translate }}</label>
                  <div class="input-group">
                    <input type="number" class="form-control" placeholder="0" formControlName="price">
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">{{ f.priceType.value !== 1 ? UnitName +' / ': ''}}{{brandCurrency | translate }}</span>
                    </div>
                  </div>
                  <div *ngIf="f.price.invalid && (f.price.dirty || f.price.touched)" class="alert alert-danger">
                    <div *ngIf="f.price.errors.required">
                      {{'Price is required' | translate }}.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12">

                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="Country">{{'Modifiers' | translate }}</label>
                      <ng-select [items]="GroupModifiersList" [bindLabel]="(selctedLang == 'en' ? 'name' : 'alternateName')" bindValue="id" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedgroupModifierIds">


                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngFor="let item of items">
                            <span class="ng-value-label">           {{selctedLang=='en'?item.name:item.alternateName}}</span>
                            <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                          </div>

                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="Country">{{'Availability in Locations' | translate }}</label>
                      <ng-select #select [items]="LocationsList" bindLabel="name" bindValue="locationID" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedLocationIds">

                        <ng-template ng-header-tmp>
                          <div (click)="closeSelect(select)">
                            <button class="dropdown-item"
                                    (click)="onSelectAll('locations')">
                              {{'Select All' | translate }}
                            </button>
                          </div>
                        </ng-template>


                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngFor="let item of items">
                            <span class="ng-value-label">{{item.name}}</span>
                            <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                          </div>

                        </ng-template>
                      </ng-select>
                      <div class="alert alert-danger" *ngIf="!selectedLocationIds || selectedLocationIds.length === 0">
                        {{'Location is required' | translate }}.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tiltle-bar form-group">
                <div class="row">
                  <div class="col-8">
                    <label for="inventory">{{'Nutritional Info' | translate }}</label>
                    <p>{{'Add calories and allergens to your food products' | translate }}</p>
                  </div>
                  <div class="col-4 text-right">
                    <div class="form-check form-switch">
                      <input type="checkbox" class="form-check-input" id="nutriInfo" formControlName="nutriInfo">
                      <label class="form-check-label" for="nutriInfo"></label>
                    </div>
                  </div>
                </div>
              </div>




              @if(f.nutriInfo.value){
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="calories">{{'Calories' | translate }}</label>
                  <div class="input-group">
                    <input type="number" class="form-control" placeholder="" formControlName="calories">
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">{{'KCAL' | translate }}</span>
                    </div>
                  </div>
                  <div *ngIf="f.calories.invalid && (f.calories.dirty || f.calories.touched)" class="alert alert-danger">
                    <!--<div *ngIf="f.calories.errors.required">
                      {{'Calories is required' | translate }}.
                    </div>-->
                    <div *ngIf="f.calories.invalid">{{'Calories must be greater than 0 and less than 10000' | translate }}. </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="Country">{{'Allergens' | translate }}</label>
                  <ng-select [items]="AllergenList" [bindLabel]="(selctedLang == 'en' ? 'name' : 'alternateName')" bindValue="id" [multiple]="true" placeholder=""
                             [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedAllergenIds">

                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngFor="let item of items">
                        <span class="ng-value-label">{{selctedLang=='en'?item.name:item.alternateName}}</span>
                        <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <span class="fw-bold">{{selctedLang=='en'?item.name:item.alternateName}}:</span>&nbsp; {{selctedLang=='en'?item.description:item.alternateDescription}}
                    </ng-template>
                  </ng-select>
                  <!--<div class="alert alert-danger" *ngIf="!selectedAllergenIds || selectedAllergenIds.length === 0">
                    {{'Allergens is required' | translate }}.
                  </div>-->
                </div>
              </div>
              }
              <div class="row">
                <div class="form-group col-lg-12">


                  <div class="tiltle-bar form-group">
                    <div class="row">
                      <div class="col-5">
                        <div class="title">
                          <label>{{'Advanced Settings' | translate }}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="License">{{'Sell By' | translate }}</label>
                      <select class="custom-select form-control custom-select-2" formControlName="unitID" (change)="getUnitName()">
                        <option value="">
                          {{'Select Unit' | translate }}
                        </option>
                        <option [ngValue]="option.unitID"
                                selected="option.unitID === unitID" *ngFor="let option of Units">
                          {{option.unit}}
                        </option>
                      </select>

                      <div *ngIf="f.unitID.invalid && (f.unitID.dirty || f.unitID.touched)" class="alert alert-danger">
                        <div *ngIf="f.unitID.errors.required">
                          {{'Unit is required' | translate }}.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="terminalName">{{'Barcode' | translate }}</label>
                      <input type="text" class="form-control" id=barcode placeholder="" formControlName="barcode">
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="terminalName">{{'Display Order in' | translate }} <span class="font-weight-bold text-dark">{{selectedSubcatName | translate}}</span></label>
                      <input type="number" class="form-control" id=displayOrder placeholder="" formControlName="displayOrder">
                      <div *ngIf="f.displayOrder.invalid && (f.displayOrder.dirty || f.displayOrder.touched)" class="alert alert-danger">
                        <div *ngIf="f.displayOrder.errors.required">
                          {{'Display order is Required' | translate }}
                        </div>
                        <div *ngIf="f.displayOrder.invalid">{{'Display order must be greater than 0 and less than 32000' | translate }}. </div>
                      </div>
                    </div>
                  </div>



                  <div class="tiltle-bar form-group">
                    <div class="row">
                      <div class="col-8">
                        <label for="inventory">{{'Track inventory' | translate }}</label>
                      </div>
                      <div class="col-4 text-right">
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="trackInventory" formControlName="trackInventory" (change)="generateSKU()">
                          <label class="form-check-label" for="trackInventory"></label>
                        </div>
                      </div>
                    </div>
                  </div>





                  <div *ngIf="f.trackInventory.value">
                    <div class="row">
                      <div class="form-group col-lg-12">
                        <label for="License">{{'Inventory' | translate }}</label>
                        <select class="custom-select form-control custom-select-2" formControlName="type" (change)="ChangeInventoryType($event)">
                          <ng-container *ngFor="let option of  InventoryTypes">
                            <!--<option [ngValue]="option.inventoryType" *ngIf="f.priceType.value === 3 && option.inventoryType === 'Item'">
                  {{option.name | translate}}
                </option>-->
                            al
                            <option [ngValue]="option.inventoryType" *ngIf="(f.priceType.value === 3 && option.inventoryType === 'Item') || f.priceType.value !== 3">
                              {{option.name | translate}}
                            </option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                    <div class="row" *ngIf="isRetailItem">
                      <div class="form-group col-lg-12 col-sm-12">
                        <div class="row">
                          <div class=" form-group col-lg-12 col-sm-12">
                            <label for="Supplier">{{'Suppliers' | translate }}</label>
                            <ng-select [items]="SuppliersList" bindLabel="name" bindValue="supplierID" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSupplierIds">
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items">
                                  <span class="ng-value-label">{{item.name}}</span>
                                  <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                                </div>
                              </ng-template>
                            </ng-select>
                          </div>
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="License">{{'Inventory Group' | translate }}</label>
                            <select class="custom-select form-control custom-select-2" formControlName="groupID">
                              <option [ngValue]="option.groupID" [disabled]=option.maped
                                      selected="option.groupID == groupID" *ngFor="let option of Groups">
                                {{option.name}}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="Price">{{'Cost' | translate }}</label>
                            <div class="input-group">
                              <input type="number" class="form-control" placeholder="0" formControlName="cost">

                              <!--<div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">{{brandCurrency | translate }}</span>
                  </div>-->
                              di


                            </div>


                          </div>
                          <div class="form-group col-lg-12 col-sm-12">
                            <label for="">{{'SKU' | translate }}</label>

                            <div class="input-group mb-3 section-sku">
                              <input type="text" class="form-control" placeholder="SKU-000" aria-label="" aria-describedby="basic-addon2" formControlName="sku" [readonly]="SelectedID > 0" (input)="SelectedID === 0 && manualSKUChangeByUser =true">
                              <div class="input-group-append" role="button">
                                <span class="input-group-text button-generate" id="basic-addon2" [ngClass]="{'SKU-Disable': !enableSKU}" (click)="enableSKU && generateSKU()">{{'Generate' | translate }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="!isRetailItem">
                      <div class="form-group col-lg-12">
                        <div class="row">
                          <div class=" form-group col-lg-12 col-sm-12">
                            <div class="recipe-box form-group">
                              <ng-select [items]="RecipesList" class="form-control recipe-box-autocomplete" placeholder="{{'Add Recipes' | translate }}" bindLabel="name" bindValue="recipeID" (change)="addRow($event)">
                              </ng-select>

                              <div class="row p-1" *ngFor="let obj of arrRecipes">
                                <div class="col-6">
                                  <h6 for="name" class="black pt-3">{{obj.name}}</h6>
                                </div>
                                <div class="col-4">
                                  <div class="input-group">
                                    <input type="number" [(ngModel)]="obj.quantity" [ngModelOptions]="{standalone: true}" (change)="UpdateQty(obj)" class="form-control rounded-0" placeholder="" aria-label="" aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                      <span class="input-group-text rounded-0" id="basic-addon2"><label for="unit" class="unit">{{'Unit' | translate }}</label></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-2 text-center">
                                  <a><img class=" pt-3" src="/assets/images/delete-icon.png" (click)="deleteRow(obj)" /></a>
                                </div>
                              </div>
                              <div class="row p-1" *ngIf="arrRecipes.length==0">
                                <div class="col-12 text-center">
                                  <h6 for="name" class="pt-3">{{'No Recipe Added!' | translate }}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
                <div class="form-group col-lg-4 col-sm-4">
                </div>



              </div>



            </div>

          </div>

          <div class="col-lg-3"></div>
        </div>
      </div>
      <!--<div class="col-lg-3"></div>-->
    </div>
  </div>
</form>
