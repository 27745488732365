<main class="views container">
  <div class="page">
    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Product Mix' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header">
            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">
              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                  <button ngbDropdownItem (click)="export('csv' ,data$)">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx',data$)">Excel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body position-relative">
      <div class="preview-filter-data" dir="ltr">{{selectedReport?.dateRange}}</div>
      <div class="preview-filter-data" dir="ltr">{{selectedReport?.locations}}</div>
      <div class="preview-filter-data" dir="ltr" *ngIf="inventoryPurchase && inventoryPurchase !== ''">{{inventoryPurchase}}</div>
      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">

        <div class="tile-body p-0 table-responsive hrzScroll salesOrderDetail">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th width="5%">
                  <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" />
                </th>
                <ng-container *ngIf="(data$ | async) as data">
                  <th *ngIf="showLocationColumn$ | async" width="15%">
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" ngbDropdownToggle>
                        <span>{{'Location' | translate }}</span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                           class="dropdown-header-filter-body">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllLocations" (ngModelChange)="selectAllLocationFilters($event,locations)">
                          <label class="form-check-label font-blue" (click)="selectAllLocationFilters($event,locations)">
                            {{'All Locations' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let location of locations">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="checkbox" class="form-check-input"
                                   [(ngModel)]="location.isChecked" (ngModelChange)="filterLocations()">
                            <label class="form-check-label" (click)="filterLocations()">
                              {{location.name}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </th>
                </ng-container>
                @if(showModifierColumn$ | async){
                <th sortable="ModifierName" (sort)="onSort($event)">{{'Modifier Name (EN)' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="AlternateModifierName" (sort)="onSort($event)">{{'Modifier Name (AR)' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                }
                <!--<th sortable="ItemID" (sort)="onSort($event)">{{'Item ID' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>-->
                <th sortable="Name" (sort)="onSort($event)">{{'Item Name (EN)' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="AlternateName" (sort)="onSort($event)">{{'Item Name (AR)' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Price" (sort)="onSort($event)">{{'Menu Price' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="SalePrice" (sort)="onSort($event)">{{'Sales Price (avg.)' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Quantity" (sort)="onSort($event)">{{'Quantity Sold' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="TotalSales" (sort)="onSort($event)">{{'Total Sales' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="ItemCost" (sort)="onSort($event)">{{'Item Cost' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="TotalItemCost" (sort)="onSort($event)">{{'Total Item Cost' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Margin" (sort)="onSort($event)">{{'Product Margin %' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Profit" (sort)="onSort($event)">{{'Total Profit' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data$ | async ">
                <td>
                  <input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" />
                </td>
                <td *ngIf="showLocationColumn$ | async">
                  {{ item.LocationName }}
                </td>
                @if(showModifierColumn$ | async){
                <td>{{item.ModifierName }}</td>
                <td>{{item.AlternateModifierName  }}</td>
                }
                <!--<td>{{item.ItemID}}</td>-->
                <td> {{item.Name }} </td>
                <td> {{item.AlternateName }} </td>
                <td> {{item.Price | number:'1.2-2'}} </td>
                <td>  {{item.SalePrice | number:'1.2-2' }} </td>
                <td> {{item.Quantity | number:'1.2-2' }} </td>
                <td>  {{item.TotalSales | number:'1.2-2' }} </td>
                <td> {{item.ItemCost | number:'1.2-2' }} </td>
                <td> {{item.TotalItemCost | number:'1.2-2' }} </td>
                <td> {{item.Margin | number:'1.2-2' }} </td>
                <td>  {{item.Profit | number:'1.2-2' }} </td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </section>
      <div class="row mt-10">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <p class="pagination-count">
            {{'Showing' | translate }}
            <strong>{{(service.pageSize * service.page)-199}}</strong> {{'to' | translate }}
            <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong>&nbsp;{{'of' | translate }}
            <strong>{{(total$ | async)!}}</strong>{{'results' | translate }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
            <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</main>

