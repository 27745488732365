<main class="views container order-list">
  <div class="page-header tabheader ">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
          <li ngbNavItem="locationlist" id="locationlist" *hasPermission="['Locations']" (click)="active='locationlist';">
            <a ngbNavLink> {{'Locations List' | translate }} </a>
            <ng-template ngbNavContent>
              <app-location [LogEventType]="'screen'" [LogEvent]="'Location List'"></app-location>
            </ng-template>
          </li>
          <li ngbNavItem="locationGroup" id="locationGroup" *hasPermission="['Locations','Locations Group']" (click)="active='locationGroup';">
            <a ngbNavLink>{{'Locations Group' | translate }}  </a>
            <ng-template ngbNavContent>
              <app-location-group [LogEventType]="'screen'" [LogEvent]="'Location Inventory'"></app-location-group>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
